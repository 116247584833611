@use '~@moved/ui/src/balazs/common/all' as *;

.stripe_link {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  color: $blue-50;
  font-size: $font-size-1;
  &:hover {
    opacity: 1;
  }
}
