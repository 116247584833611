@use "sass:math";
@use 'variables' as *;

// ----------------------------------------
//  Retina Specific Styling
// ----------------------------------------
@mixin retina {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

// ----------------------------------------
//  Breakpoint Mixins
// ----------------------------------------

// Breakpoints
@mixin breakpoint($points) {
  @each $point in $points {
    @if $point == xs {
      @media screen and (max-width: $screen-xs-max) { @content; }
    }
    @else if $point == sm {
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
    }
    @else if $point == md {
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
    }
    @else if $point == lg {
      @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { @content; }
    }
    @else if $point == xlg {
      @media screen and (min-width: $screen-xlg-min) { @content; }
    }
    @else if $point == h-md {
      @media screen and (max-height: $screen-height-md) { @content; }
    }
    @else if $point == n-xs {
      @media screen and (min-width:  $screen-sm-min) { @content; }
    }

    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg, xlg, h-md, n-xs";
    }
  }
}

// ----------------------------------------
//  Margin Helpers
// ----------------------------------------

@mixin l-margin-top($margin-top) {
  margin-top: $margin-top;

  &:first-child {
    margin-top: 0;
  }
}

@mixin l-margin-left($margin-left) {
  margin-left: $margin-left;

  &:first-child {
    margin-left: 0;
  }
}

// ----------------------------------------
//  Misc Helpers
// ----------------------------------------

/**
 * Solid Arrow
 */
// Create CSS arrow
%base_arrow {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  border-style: solid;
}

@mixin arrow($size: 10px, $color: #000, $dir: "down") {
  @extend %base_arrow;

  @if $dir == "up" {
    border-width:  0 $size $size $size;
    border-color: transparent transparent $color transparent;
  }

  @if $dir == "down" {
    border-width: $size $size 0 $size;
    border-color: $color transparent transparent transparent;
  }

  @if $dir == "left" {
    border-width: $size $size $size 0;
    border-color: transparent $color transparent transparent;
  }

  @if $dir == "right" {
    border-width: $size 0 $size $size;
    border-color: transparent transparent transparent $color;
  }
}


// ----------------------------------------
//  Shorthand Mixins
// ----------------------------------------


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height,$width) * 100%;
  }
  & > .aspect-ratio {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin gradient-background() {
  background-color: $primary;
  background-image: linear-gradient(-45deg, $primary-gradient 0%, $primary-gradient 50%, $primary 100%);
  background-size: 101% 700%;
}

@mixin action-hover() {
  cursor: pointer;
  padding: 8px;
  border-radius: 999em;
  border: 1px solid transparent;
  @include transition(all 0.3s);
  &:hover, &.active {
    background: $bg-color;
    border-color: $border-color;
    transform: scale(1.2) !important;
  }
}

@mixin scrollable() {
  overflow-x: hidden;
  overflow-y: auto;
  @include breakpoint(xs) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin white-box {
  padding: 20px 28px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 6px 24px $bg-light;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
