@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 50px;
}

.link {
  width: 500px;
  max-width: 100%;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.pill {
  font-size: $font-size-0;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  padding: 3px 8px;
  font-weight: $med;
  white-space: nowrap;
  color: $black-60;
  background-color: $black-20;
  border: 1px solid $black-20;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
