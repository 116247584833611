@use '~@moved/ui/src/sondheim/common' as *;

.content {
  max-height: 500px;
  min-width: 650px;
  overflow-y: auto;
  margin-right: -$sp-24;
  padding-right: $sp-24;
  padding-bottom: $sp-8;
}
