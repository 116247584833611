@use '../../common/all' as *;

.icon {
  display: block;
  width: 36px;
  height: 24px;
  & > svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}
