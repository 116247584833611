@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    General Moved Footer layout
---------------------------------------- */

.footer {
  background-color: #000;
  padding: 120px 0;
  @include breakpoint([xs]) {
    padding: 65px 0;
  }
  :global(.darkMode) & {
    filter: hue-rotate(180deg) invert(108%);
  }
}

.container {
  @extend %container;
  display: flex;
  justify-content: space-between;
  @include breakpoint([xs]) {
    flex-direction: column;
  }
}

.branding {
  justify-content: flex-start;
}

  .mvd_logo {
    filter: brightness(0) invert(1);
    height: 26px;
  }

  .divider {
    height: 1px;
    background-color: $black-40;
    width: 75px;
    display: block;
    margin: 25px 0;
    opacity: 0.5;
  }

  .copyright {
    color: $black-40;
    span {
      display: block;
    }
  }

.nav {
  justify-content: flex-end;
  display: flex;
  @include breakpoint([xs]) {
    margin-top: 40px;
    flex-direction: column;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
}

  .nav_col {
    flex: 1 1 auto;
    margin-right: 80px;

    &:last-child {
      margin-right: 0;
    }

    @include breakpoint([xs]) {
      margin-right: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-bottom: 25px;
      color: $black-40;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint([xs]) {
        border-top: 1px solid rgba(255,255,255,0.2);
        font-size: $font-size-4;
        padding: 16px 0;
        margin-bottom: 0px;
      }
    }

    .nav_arrow {
      display: none;
      opacity: 0.6;
      transition: transform 0.3s linear, opacity 0.3s linear;
      @include breakpoint([xs]) {
        display: block;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: $font-size-2;

      &:last-child {
        margin-bottom: 0;
      }
      @include breakpoint([xs]) {
        font-size: $font-size-3;
        line-height: 30px;
        margin: 0;
        padding: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: height 0.3s linear, opacity 0.3s linear;
      }
    }

    a,
    .nav_link {
      color: $white;
      cursor: pointer;

      &:hover, &:active {
        color: $black-20;
        text-decoration: underline;
      }
    }

    &.nav_open {
      @include breakpoint([xs]) {
        .nav_arrow {
          transform: rotate(90deg);
          opacity: 1;
        }
        p {
          height: 49px;
          opacity: 1;
        }
      }
    }

  }
