@use '../../../common/all' as *;
@use 'input.all' as *;

.error_msg {
  color: $red-40;
  margin-top: 5px;
  display: block;
  font-size: $font-size-1;
}

.input_row {
  margin-bottom: 20px;
  text-align: left;
}

.input_label {
  order: -1;
  // margin-bottom: 10px;
  color: $black-40;
  font-size: $font-size-1;
  vertical-align: top;
  .label_icon {
    font-size: $font-size-1;
    line-height: 16px;
    margin-right: 5px;
    vertical-align: top;
  }
}

.input_wrapper {
  width: 100%;
}

.verify_pending {
  position: absolute;
  color: $black-70;
  top: 0;
  right: 0;
  height: 50px;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: $med;
  pointer-events: none;
  user-select: none;
  & > span {
    margin-right: 10px;
  }
}

/* TEXT INPUTS */
@include text-inputs {
  @extend %text-underline;
  &[readonly], &[contenteditable="false"] {
    border-bottom-color: $black-20 !important;
  }
}

/* CHECKBOX */
.checkbox { @extend %checkbox-underline; }
.input_row.type_checkbox { @extend %checkbox-row-underline; }

/* TOGGLE */
.toggle_checkbox { @extend %toggle-underline; }
.input_row.type_toggle { @extend %toggle-row-underline; }

/* SLIDETOGGLE */
.slide_container { @extend %slide-toggle-underline; }

/* SELECT */
.select_container { @extend %select-underline; }

/* UPLOADER */
.file_upload { @extend %uploader-underline; }

/* CURRENCY */
input[type="text"].currency { @extend %currency-underline; }

/* PASSWORD */
input[type="password"].password { @extend %password-underline; }

/* INTEGER */
input[type="text"].integer { @extend %integer-underline; }

/* DIFFERENCE */
input[type="text"].difference { @extend %integer-underline; }

/* PERCENTAGE */
input[type="text"].percentage { @extend %percentage-underline; }

/* PILLS */
.pill_field { @extend %pills-underline; }

/* TYPE AHEAD */
input[type="text"].type_ahead { @extend %type-ahead-underline; }

/* ITEMLIST */
.item_list { @extend %item-list-underline; }

/* TEXTAREA */
textarea { @extend %textarea-underline; }

/* DATEPICKER */
.date_picker { @extend %date-picker-underline; }

/* EDITOR */
.editor_label{ @extend %editor-label; }
.editor_container { @extend %editor-underline; }
.editor_preview { @extend %editor-preview; }
