@use '../../../common' as *;

.container {
  position: relative;
  padding: 22px 0 4px;
}

.input {
  border: none;
  border-bottom: 1px solid $borderTertiary;
  border-radius: 0;
  outline: none;
  @include labelM;
  color: $contentPrimary;
  background-color: transparent;
  padding: 0 0 6px 0;
  width: 100%;

  &.leadingIcon {
    padding-left: 36px;
    & ~ .label {
      left: 36px;
    }
    & ~ .icon {
      left: 0;
    }
  }

  &.trailingIcon {
    padding-right: 36px;
    & ~ .label {
      padding-right: 36px;
    }
    & ~ .icon {
      right: 0;
    }
  }

  &:hover {
    border-color: $borderPrimary;
  }

  &:focus, &.hasFocus, &.hasValue {
    & ~ .label {
      @include labelS;
      top: 2px;
    }
  }

  &:focus, &.hasFocus {
    border-color: $borderAccent;
    & ~ .label {
      color: $contentAccent;
    }
    & ~ .icon {
      --color-1: #{$iconMultiAccent};
      --color-2: #{$iconMultiAccent};
    }
  }

  &.hasError {
    border-color: $borderError;
  }

  &[readonly] {
    border-color: transparent;
    & ~ .label {
      color: $contentSecondary;
    }
    & ~ .icon {
      --color-1: #{$iconSingleEnabled};
      --color-2: #{$iconSingleEnabled};
    }
  }

  &:disabled {
    color: $contentTertiary;
    border-color: $borderTertiary;
    background: transparent;
    & ~ .label {
      color: $contentTertiary;
    }
    & ~ .icon {
      --color-1: #{$iconSingleDisabled};
      --color-2: #{$iconSingleDisabled};
    }
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
  /* Firefox */
  &[type=number] { -moz-appearance: textfield; }

}

.icon {
  position: absolute;
  top: 20px;
  pointer-events: none;
  --color-1: #{$iconSingleEnabled};
  --color-2: #{$iconSingleEnabled};
  z-index: 1;
}

.label {
  @include labelM;
  position: absolute;
  top: 22px;
  left: 0;
  transition: top 0.2s, font-size 0.2s;
  z-index: 1;
}
