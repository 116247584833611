@use '~@moved/ui/src/sondheim/common' as *;

.tenant_row {
  display: flex;
  align-items: center;
  padding: $sp-20 0;
  border-bottom: 1px solid $borderSecondary;
  &:first-child {
    padding-top: 0;
  }
}

.field {
  flex: 0 0 33.33%;
}
