@use '~@moved/ui/src/balazs/common/all' as *;

// Task list area
.wrapper {
  padding-bottom: 60px;
}

.task_title {
  margin: 30px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.task_terms {
  color: $blue-50;
}

.count {
  color: $black-40;
  margin-left: 8px;
}

// Task Wrapper
.task {
  background-color: $white;
  padding: 20px 36px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 16px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, padding 0.3s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }
}
