@use '~@moved/ui/src/balazs/common/all' as *;

.header {
  padding: 10px 0 48px;
  text-align: center;
  @include breakpoint(xs) {
    padding: 0 0 30px;
  }
}

.action_icon {
  margin: 0 auto 10px;
}

.pending_content {
  margin: 250px 0;
}

.title {
  margin-bottom: 15px;
  color: $black-90;
  font-size: 30px;
  font-weight: 300;
  line-height: 56px;
  @include breakpoint(xs) {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
  }
}

.subtitle {
  margin-bottom: 20px;
  color: $black-30;
  font-size: 40px;
  font-weight: 300;
  line-height: 52px;
  @include breakpoint(xs) {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 28px;
  }
}

.description {
  margin-bottom: 52px;
  color: $black-40;
  font-size: 16px;
  line-height: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint(xs) {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
}

.error_message {
  margin-bottom: 52px;
  color: $red-40;
  font-size: 12px;
  line-height: 16px;
}

.center {
  text-align: center;
}

.resident_name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E8EAEC;
  @include breakpoint(xs) {
    flex-flow: column;
    margin-bottom: 16px;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.name_label,
.name_value {
  color: $black-90;
  font-size: 26px;
  font-weight: 300;
  line-height: 40px;
  @include breakpoint(xs) {
    font-size: 16px;
    line-height: 24px;
  }
}

.name_label {
  @include breakpoint(xs) {
    color: $black-40;
  }
}

.field_wrapper {
  display: flex;
  margin-bottom: 16px;
  gap: 20px;
  @include breakpoint(xs) {
    flex-flow: column;
    gap: 0;
  }
}

.field_label {
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  color: $black-90;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  @include breakpoint(xs) {
    display: block;
    justify-content: initial;
    width: 100%;
    color: $black-40;
  }
}

.field_value {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  flex: 1 1 auto;
  color: $black-90;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  @include breakpoint(xs) {
    display: block;
    justify-content: initial;
    width: 100%;
    text-align: left;
  }
}

.actions {
  display: flex;
  margin-top: 50px;
  @include breakpoint(xs) {
    flex-direction: column;
    align-items: center;
  }
  button {
    width: 100%;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    @include breakpoint(xs) {
      margin-right: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
