@mixin iconLeftSmall {
  padding-left: 12px;
  .buttonIcon {
    margin-right: 8px;
  }
}

@mixin iconRightSmall {
  padding-right: 12px;
  .buttonIcon {
    margin-left: 8px;
  }
}

@mixin iconOnlySmall {
  padding: 0;
  width: 32px;
}

@mixin iconLeftDefault {
  padding-left: 16px;
  padding-right: 24px;
  .buttonIcon {
    margin-right: 12px;
  }
}

@mixin iconRightDefault {
  padding-right: 16px;
  padding-left: 24px;
  .buttonIcon {
    margin-left: 12px;
  }
}

@mixin iconOnlyDefault {
  padding: 0;
  width: 44px;
}

@mixin iconLeftLarge {
  padding-left: 36px;
  .buttonIcon {
    margin-right: 12px;
  }
}

@mixin iconRightLarge {
  padding-right: 36px;
  .buttonIcon {
    margin-left: 12px;
  }
}

@mixin iconOnlyLarge {
  padding: 0;
  width: 56px;
}
