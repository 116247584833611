@use '../../../common/all' as *;

%slide-toggle-default,
%slide-toggle-stacked,
%slide-toggle-underline {
  &.full {
    width: 100%;
  }
}

%slide-toggle-underline {
  & ~ label {
    margin-bottom: 6px;
  }
}
