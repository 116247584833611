@use '~@moved/ui/src/sondheim/common' as *;

.buildingSelector {
  user-select: none;
  transition: background-color ease-in-out 0.25s;
  padding: $sp-8 $sp-20;
  margin-left: -$sp-20;
  margin-top: -$sp-8;
  border-radius: $radius-full;
  max-width: 500px;

  &:hover { background-color: $ctaSecondaryEnabled; }

  svg { transition: transform ease-in-out 0.25s; }

  :global(.filterSearchActive) & {
    background-color: $ctaSecondaryHover;
    svg { transform: rotate(-180deg); }
  }

}

.popover { margin-left: -20px; }
