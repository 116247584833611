@use '~@moved/ui/src/balazs/common/all' as *;
@use './mixins';

// Layout Wrapper

// Override default padding
.box {
  padding-top: 14px;
  padding-bottom: 14px;
}

.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex-basis: 21%;
    padding: 0 10px;
    display: flex;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

/* --- Columns BEGIN ---- */
.client_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  .splash {
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin: 0 14px 0 0;
    border-radius: 30px;
    background-color: $black-20;
    overflow: hidden;
    background-size: 90% auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}
/* --- Columns END ---- */

.arrow_icon {
  --color-1: #{$black-40};
  --color-2: #{$black-40};
}
