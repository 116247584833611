@use '../../common/all' as *;

.text_format {
  font-family: monospace;
  background-color: $bg-light;
  border: 1px solid $black-20;
  border-radius: 3px;
  padding: 0 6px;
  cursor: pointer;
  transition: border-color 0.3s;
  user-select: none;
  display: flex;
  align-items: center;
  & > svg {
    margin-left: 8px;
  }
  &:hover {
    border-color: $black-30;
  }
}
