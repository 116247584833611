@use '~@moved/ui/src/sondheim/common' as *;
@use './variables' as *;

.template {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: $backgroundPrimary;
}

.sidebar {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
}

.content {
  flex: 1 1 auto;
  padding-left: $navWidthCollapsed;
  width: 100%;
}
