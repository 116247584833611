@use '~@moved/ui/src/sondheim/common' as *;

.box_wrapper {
  width: 100%;
  background-color: $backgroundSecondary;
  border: 1px solid $borderTertiary;
  border-radius: $radius-8;
  padding: $sp-24;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
