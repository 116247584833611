@use '../../common/all' as *;

.group {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1200px;
}

.wrapper {
  height: 100%;
}
