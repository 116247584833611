@use '../../common/all' as *;

$size: 50px;

.file {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: $size;
  width: $size;
  color: $black-100;
  & > svg {
    height: $size;
    width: $size;
    fill: currentColor;
  }
  & > span {
    position: absolute;
    margin-bottom: 3px;
    font-size: 10px;
    line-height: 7px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 150%;
    font-style: normal;
    pointer-events: none;
    user-select: none;
  }
}
