@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  flex: 1 1 auto;
  margin: 30px 80px;
  padding-bottom: 60px;
  position: relative;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

// Header
.title {
  padding-bottom: 16px;
  border-bottom: 1px solid $black-30;
  margin-bottom: 20px;

  h2 {
    font-size: 26px;
    font-weight: $med;
  }
}

.filters {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid $black-30;
  margin-bottom: 20px;
}

.building_trigger {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  transition: background-color ease-in-out 0.25s;
  padding: 4px 20px;
  margin-left: -20px;
  border-radius: 26px;

  span {
    font-size: 26px;
    font-weight: $med;
    margin-right: 8px;
  }
  svg {
    transition: transform ease-in-out 0.25s;
  }

  &:hover {
    background-color: #e0e2e3;
  }
  :global(.filterSearchActive) & {
    background-color: #c2c5c9;
    svg {
      transform: rotate(-180deg);
    }
  }
}

.filter {
  margin-left: -20px;
}

// Search area
.search {
  margin: 16px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.search_terms {
  color: $black-40;
}

.search_results {
  color: $blue-50;
  margin-left: 8px;
}

.search_bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > label {
    cursor: pointer;
  }
}

.search_input {
  background-color: transparent;
  border: 0 none transparent;
  border-bottom: 1px solid $black-20;
  outline: 0;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:focus {
    border-bottom: 1px solid $black-30;
    background-color: rgba(255,255,255,0.3);
  }
}

// Sort header for move rows
.sort_header {
  font-size: $font-size-1;
  padding: 0 32px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;

  & > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 21%;
    padding: 0 10px;
    color: $black-40;

    svg {
      margin-left: 6px;
    }

    &.active {
      color: $black-90;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .direction {
    flex-basis: 10%;
  }

  .unit {
    flex-basis: 11%;
  }

  .name {
    flex-basis: 22%;
    min-width: 150px;
    @include breakpoint([xs,sm]) {
      min-width: 0;
    }
  }

  .arrow {
    flex-basis: 4%;
  }
}

.results {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 30px;
  &:last-child {
    padding-bottom: 0;
  }
}

.no_results_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100%;
  align-items: center;
  padding: 20px 0;
}

.no_results {
  width: auto;
  min-width: 250px;
}
