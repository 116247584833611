@use '../../common' as *;

.blockHolder {
  border-radius: 8px;
  flex: 1 0 300px;
  overflow-y: scroll;
  position: relative;
  &.expanded {
    flex-basis: auto;
  }
}

.codeBlock {
  font-size: 14px;
  line-height: 1.5;
  display: block;
  min-height: 100%;
  .expandable & {
    padding-bottom: $sp-64;
    margin-bottom: -$sp-64;
  }
  .expanded & {
    padding-top: $sp-64;
  }
}

.basicBlock {
  @extend .codeBlock;
  white-space: pre-wrap;
  background-color: $backgroundInverseSecondary;
  color: $contentInversePrimary;
  padding: $sp-12;
  overflow-x: auto;
}

.expander {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: $sp-16 0;
  background: $backgroundOverlayDark;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapser {
  @extend .expander;
  position: absolute;
  top: 0;
  bottom: auto;
}
