@use 'responsive' as *;

@mixin scrollable() {
  overflow-x: hidden;
  overflow-y: auto;
  @include responsive(mobile) {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
