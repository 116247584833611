@use '~@moved/ui/src/sondheim/common' as *;

.contentEditor {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #212326;
  outline: none;
  position: relative;
  z-index: 1;
  transition: border-color 0.2s, color 0.2s, background-color 0.2s;
  line-height: 1.5em;
  border: 1px solid transparent;
  background-color: #ffffff;
  border-radius: 8px;
  resize: none;
  padding: $sp-20;
  margin-bottom: $sp-20;
}
