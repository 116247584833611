@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.slot_appointment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  width: 100%;
  padding: 2px 8px;
  line-height: 130%;

  .slot_unit {
    @include labelS;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      margin-right: 2px;
    }
  }

  .cancel_appointment {
    cursor: pointer;
    display: none;
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }

    &:hover {
      svg {
        --color-1: #{$red-50};
        --color-2: #{$red-50};
      }
    }
  }

  &:hover {
    background-color: rgba(0,0,0,0.08);
    .slot_unit {
      display: none;
    }
    .cancel_appointment { display: block; }
  }
}

.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
