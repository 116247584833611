@use '~@moved/ui/src/sondheim/common' as *;

@keyframes fadeIn {
  0%    { background-color: rgba($black-100,0) }
  100%  { background-color: rgba($black-100,0.3) }
}

$transitionTime: 0.3s;

.drawerWrapper {
  z-index: 1300; // this value should be less than the notistack (snackbar) z-index, which is 1400
  background-color: rgba($black-100, 0);
  position: fixed;
  inset: 0 0 0 0;
  transition: background $transitionTime ease-out;
  @include responsive(mobile) {
    -webkit-overflow-scrolling: touch;
  }
  :global(.enter-active) &,
  :global(.enter-done) & {
    background-color: rgba($black-100, 0.3);
  }
  :global(.exit-active) &  {
    transition-timing-function: ease-in;
  }
}

.drawer {
  background-color: $backgroundPrimary;
  box-shadow: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 600px;
  min-height: 200px;
  transform: translateX(100%);
  transition:
    transform $transitionTime ease-out,
    box-shadow $transitionTime ease-out;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  @include responsive(mobile) {
    width: 100%;
  }
  :global(.enter-active) &,
  :global(.enter-done) & {
    transform: translateX(0);
    box-shadow: $shadow-4;
  }
  :global(.exit-active) &  {
    transition-timing-function: ease-in;
  }

  &.fullWidth {
    width: 100%;
    transition-duration: $transitionTime*1.5;
  }
}

$collapseTransitionTime: 0.1s;

.header {
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  z-index: 20;
  display: flex;
  padding: $sp-24 $sp-40 0;
  gap: $sp-24;
  justify-content: space-between;
  border-bottom: 1px solid transparent;
  background-color: $backgroundPrimary;
  transition: border $collapseTransitionTime, padding $collapseTransitionTime $collapseTransitionTime;
  &.collapsed {
    border-bottom: 1px solid $borderSecondary;
    padding-bottom: $sp-16;
    transition: border $collapseTransitionTime $collapseTransitionTime, padding $collapseTransitionTime;
  }
  @include responsive(mobile) {
    padding-right: $sp-24;
    padding-left: $sp-24;
  }
}
.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  position: relative;
  min-height: 32px; // need for the cross-fade effect to work smoothly
}

.rightColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  min-width: 32px;
}

.title {
  transition: opacity $collapseTransitionTime;
  .collapsed & {
    opacity: 0;
  }
  &.mini {
    position: absolute;
    top: 0;
    padding: $sp-4 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0;
    .collapsed & {
      opacity: 1;
    }
  }
}

.close {
  flex: 0 0 auto;
  height: 32px;
  width: 32px;
  padding: 4px;
  cursor: pointer;
  background-image: radial-gradient(circle at center , $black-20 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  border-radius: 100px;
  &:hover {
    background-size: 200% 200%;
    color: $black-90;
  }
  &:focus, &:active {
    background-color: $black-20;
    background-image: none;
  }
}

.content {
  flex: 1 1 auto;
  padding: $sp-24 $sp-40;
  @include responsive(mobile) {
    padding-right: $sp-24;
    padding-left: $sp-24;
  }
}

.actions {
  flex: 0 0 auto;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: $sp-16 $sp-40;
  border-top: 1px solid $borderSecondary;
  display: flex;
  justify-content: flex-end;
  gap: $sp-12;
  background: $backgroundPrimary;
  @include responsive(mobile) {
    padding-right: $sp-24;
    padding-left: $sp-24;
  }
}
