@use '../../../../../sondheim/common' as *;

.wrapper {
  display: flex;
  align-items: center;
  gap: $sp-12;
}

.heading {
  color: $contentPrimary;
}
