@use '~@moved/ui/src/sondheim/common' as *;

// Layout Wrapper
.content {
  padding: $sp-32 $sp-80 $sp-64;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  @include responsive(mobile) {
    padding-left: $sp-8;
    padding-right: $sp-8;
  }
}

.vendorRow {
  cursor: pointer;
  transition: padding 0.2s, margin 0.2s, box-shadow 0.2s;
  &:hover {
    padding: $sp-24 $sp-28;
    margin: -$sp-4;
    box-shadow: $shadow-3;
  }
}

// Size different columns for alignment
.name {
  flex: 10 1 25%;
}
.commission, .account, .stripe {
  flex: 1 0 $sp-96;
}
.createdAt {
  flex: 2 1 20%;
}
.actions {
  flex: 0 0 130px;
  display: flex;
  justify-content: flex-end;
}
.arrow {
  width: $sp-24;
  flex: 0 0 $sp-24;
}
