@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  flex: 1 1 auto;
  margin: 30px 80px;
  padding-bottom: 60px;
  position: relative;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

// Header
.title {
  padding-bottom: 16px;
  border-bottom: 1px solid $black-30;
  margin-bottom: 20px;

  h2 {
    font-size: 26px;
    font-weight: $med;
  }
}

.filters {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid $black-30;
  margin-bottom: 20px;
}

// Search area
.search {
  margin: 16px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_terms {
  color: $black-40;
}

.search_results {
  color: $blue-50;
  margin-left: 8px;
}

.search_bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > label {
    cursor: pointer;
  }
}

.search_input {
  background-color: transparent;
  border: 0 none transparent;
  border-bottom: 1px solid $black-20;
  outline: 0;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:focus {
    border-bottom: 1px solid $black-30;
    background-color: rgba(255,255,255,0.3);
  }
}

// Sort header for move rows
.sort_header {
  font-size: $font-size-1;
  padding: 0 32px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  & > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 21%;
    padding: 0 3px;
    color: $black-40;
    cursor: pointer;

    svg {
      margin-left: 6px;
    }

    &.active {
      color: $black-90;
    }

    &.disabled {
      cursor: default;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .arrow {
    flex-basis: 4%;
  }
}

.icon {
  flex-basis: 4%;
  padding-right: 10px;
}

.results {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 30px;
  &:last-child {
    padding-bottom: 0;
  }
}

// Layout Wrapper
.box {
  display: block;
  background-color: $white;
  padding: 18px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 16px;
  transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out;
  color: $black-90;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &:active, &:focus {
    transform: scale(1.025);
    color: $black-90;
  }
}

.no_results_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100%;
  align-items: center;
  padding: 20px 0;
}

.no_results {
  width: auto;
  min-width: 250px;
}
