@use '../../../common' as *;

$datepicker__background-color: $white;
$datepicker__border-color: $white;
$datepicker__highlighted-color: $green-40;
$datepicker__muted-color: $black-40;
$datepicker__selected-color: $backgroundAccent;
$datepicker__text-color: $primary;
$datepicker__header-color: $primary;
$datepicker__navigation-disabled-color: $black-20;

$datepicker__border-radius: $radius-12;
$datepicker__selected-border-radius: 999em;
$datepicker__day-margin: 4px 5px;
$datepicker__font-size: 14px;
$datepicker__font-family: $base-font-family;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0px 20px 20px;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;
