@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  width: 600px;
  transition: height ease-in-out 0.25s, width ease-in-out 0.25s;
  // min-width: 488px;
}

.title {
  font-size: $font-size-4;
  line-height: 20px;
  margin-bottom: 40px;
  & > span {
    color: $black-50;
  }
}

.building_item {
  padding: 4px 17px 4px 4px !important;
  position: relative;
}

.disable_pill_holder {
  position: absolute;
  right: 17px;
  top: 13px;
}

.disable_pill {
  font-size: $font-size-1;
  border-radius: 40px;
  padding: 6px 12px;
  background-color: $black-20;
  border: 1px solid $black-20;
  .pill_text {
    font-weight: $regular;
    color: $black-90;
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.popover {
    width: 250px;
    background-color: $black-90;
    border: none;
    border-radius: 8px;
    padding: 15px;
    z-index: 1000;
  }

  [data-popper-placement*='top'] .popover_arrow::after {
    @include arrow(9px,$black-90,'down');
    top: 0;
    left: 0;
  }

  [data-popper-placement*='bottom'] .popover_arrow::after {
    @include arrow(9px,$black-90,'up');
    top: 0;
    left: 0;
  }

  .popover_text {
    color: $white;
    font-size: 12px;
    line-height: 16px;
  }

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.splash {
  min-width: 64px;
  width: 64px;
  flex-basis: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  margin: 0 8px 0 0;
  border-radius: 4px;
  background-color: $black-20;
  overflow: hidden;
  background-size: auto 100%;
  outline: 1px solid rgba(21, 22, 24, 0.101961);
  outline-offset: -1px;
}


.grow {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div {
    width: 50%;
  }
}

.chapter_actions {
  display: flex;
  justify-content: flex-end;
}

.item {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.preview {
  @extend %btn-ghost;
  @extend %btn--small;
  height: 32px;
  font-size: $font-size-2;
  width: 135px;
}

.selected_count {
  font-size: $font-size-2;
  font-weight: $regular;
  margin-bottom: 12px;
  span {
    color: $black-50;
  }
}

.pills {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.pill {
  font-size: $font-size-2;
  border-radius: 40px;
  padding: 6px 12px;
  font-weight: $regular;
  white-space: nowrap;
  margin-right: 6px;
  margin-bottom: 6px;

  color: $black-90;
  background-color: transparent;
  border: 1px solid $black-20;

}

.actions, .complete {
  display: flex;
  margin-top: 28px;
  align-items: center;
  justify-content: flex-end;
}

.actions {
  justify-content: space-between;

  .actions_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .actions_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .back {
    @extend %btn-gray;
    margin-right: 10px;
    padding-left: 28px;
    span {
      margin-left: 10px;
    }
  }

  .next {
    padding-right: 28px;
    span {
      margin-right: 10px;
    }
  }

  .save, .next {
    @extend %btn-primary;
    margin-left: 10px;
  }
}

.message {
  text-align: center;
  font-size: $font-size-3;
  color: $black-50;
  padding: 0 40px;

  .cloned_title {
    color: $black-90;
  }
}
