@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.search_column {
  flex: 1 1 30%;
  position: relative;
  max-width: 1020px;
  min-width: 350px;
  padding-left: 40px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: $bg-light-opaque;
  z-index: 1;
}

.results_summary {
  text-align: center;
  font-size: $font-size-1;
  line-height: 16px;
  color: $black-50;
  padding: 5px;
  position: relative;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  .search_terms {
    color: $black-40;
  }
  .search_results {
    color: $blue-50;
  }
  .refresh {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: $black-50;
    transform: scaleX(-1);
    --color-1: currentColor;
    transition: transform 0.2s, color 0.2s;
    &.loading {
      color: $black-30;
      animation: spin 1.0s infinite 0.2s linear;
    }
    &:hover {
      color: $black-90;
    }
  }
}

.results {
  flex: 1 1 auto;
  position: relative;
  padding: 20px 20px 40px;
  margin: 0 -20px;
  @include scrollable;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &::-webkit-scrollbar { display: none; }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pagination {
  padding: 10px 0 40px;
  border-top: 1px solid $border-color;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.active_move {
  flex: 1 1 70%;
  min-width: 850px;
  height: 100%;
  background-color: $bg-light-opaque;
  @include scrollable;
}
