@use '../../../common/all' as *;

%password-default,
%password-stacked,
%password-underline {
  & + .forgot_password {
    position: absolute;
    top: 11px;
    right: 10px;
    padding: 5px;
    font-size: 12px;
    line-height: 15px;
    text-decoration: none;
    transition: color 0.3s;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: $link;
    }
  }
}

%password-stacked {
  & + .forgot_password {
    top: 35px;
  }
}
