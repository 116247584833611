@use '../../../common/all' as *;

%uploader-default, %uploader-stacked, %uploader-underline {
  margin-top: 10px;
  width: 100%;

  .uploaded_file {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px;
    min-width: 500px;
    border-radius: 16px;
    border: solid 2px #C5C9CF;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 0 15px rgba(0,0,0,0.0);
    position: relative;
    @include breakpoint([xs]) {
      min-width: 0;
    }
  }

  .uploaded_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: visible;
    overflow-x: hidden;
    margin-left: 6px;
    flex: 1 0;
    &:first-child {
      margin-left: 0;
    }
  }

  .image_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    max-width: 120px;
    width: 100%;
    padding: 16px;
    border-radius: 200px;
    background-color: $black-20;
    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .image_name {
    margin-top: 20px;
    font-size: $font-size-2;
    height: 20px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .remove_wrapper {
    z-index: 100;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .remove {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    text-align: center;
    cursor: pointer;
    color: $white;
    background-color: $red-30;
    background-image: radial-gradient(circle at center , $red-60 50%, transparent 50%);
    background-repeat: no-repeat;
    background-size: 0 0;
    background-position: 50% 50%;
    transition: background-size .3s, color .3s;
    border-radius: 100px;
    user-select: none;
    &:hover {
      background-size: 200% 200%;
      color: $red-70;
    }
    &:focus, &:active {
      background-color: $black-20;
      background-image: none;
    }

    svg {
      --color-1: #{$white};
      --color-2: #{$white};
    }
  }

  .popover {
    white-space: nowrap;
  }

  &.has_error {
    border-color: $red-40;
    color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }

    .uploader {
      border-color: $red-40;
      background-color: $red-10;
    }
  }
}
