@use '../../common/all' as *;

/* ----------------------------------------
    Monthly Calendar View Daypicker
---------------------------------------- */

.arrow {
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
}

.container {
}

.header {
  display: flex;
  padding: 0 5px 5px;
}

.header_month {
  flex: 1 1 auto;
  font-size: $font-size-3;
  color: $black-90;
}

.header_nav {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.month_nav {
  cursor: pointer;
  color: $black-90;
  user-select: none;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow {
    opacity: 0.4;
  }
  &:hover {
    .arrow {
      opacity: 1;
    }
  }
}

.calendar {
  width: 100%;
  border-spacing: 0;
}

.active_week {
  background-color: $blue-10;
  border-radius: 10px;
}

.dow, .day {
  font-size: $font-size-1;
  text-align: center;
  padding: 0;
  font-weight: $regular;
  user-select: none;
  > span {
    border-radius: 30px;
    display: inline-block;
    height: 28px;
    width: 28px;
    line-height: 28px;
  }
  @include breakpoint(xs) {
    font-size: $font-size-2;
    > span {
      height: 36px;
      width: 36px;
      line-height: 36px;
    }
  }
}

.dow {
  color: $black-40;
  cursor: default;
}

.day {
  padding: 0;
  cursor: pointer;
  &:first-child {
    border-radius: 30px 0 0 30px;
  }
  &:last-child {
    border-radius: 0 30px 30px 0;
  }
  > span {
    color: $black-90;
  }
}

.day_selected, .day_selected:hover, .hover_day .day.day_selected:hover {
  > span {
    background-color: $black-40;
    color: $white;
  }
}

.day_today, .day_today:hover {
  > span {
    background-color: $blue-50;
    color: $white;
  }
}

.day_prev, .day_next {
  > span {
    color: $black-30;
  }
}

.hover_week .week:hover {
  background-color: $black-20;
}
.hover_week .week.active_week:hover {
  background-color: $blue-20;
}

@include breakpoint([sm, md, lg, xlg]) {
  .hover_day .day {
    &:hover {
      > span {
        background-color: $black-30;
        color: $white;
      }
    }
  }
}
