.address_items {
  display: flex;
  margin-top: 5px;
}

.address_item {
  flex: 0 0 auto;
  margin-right: 25px;
  h6 {
    margin: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
