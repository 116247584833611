@use '~@moved/ui/src/sondheim/common' as *;

.sizer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  min-height: 300px;
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  padding: 20px 28px;
  overflow: hidden;
  align-content: stretch;
  max-width: 710px;
  flex: 1 1 70%;
  & > div {
    width: 100%;
    flex-basis: 100%;
  }
}

.side_column {
  max-width: 380px;
  min-width: 150px;
  display: flex;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  border-radius: 12px;
  margin-left: 20px;
  flex: 1 1 30%;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  align-self: stretch;
}
