@use '../common' as *;

p {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.displayXL { @include displayXL; }
.displayL { @include displayL; }
.displayM { @include displayM; }
.displayS { @include displayS; }

.headingL { @include headingL; }
.headingM { @include headingM; }
.headingS { @include headingS; }

.labelL { @include labelL; }
.labelM { @include labelM; }
.labelS { @include labelS; }
.labelXS { @include labelXS; }

.textTruncate { @include textTruncate; }
