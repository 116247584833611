@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 50px;
}

.creation {
  width: 500px;
  max-width: 100%;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
