@use '~@moved/ui/src/balazs/common/all' as *;

.box {
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
  padding-top: 16px;
  &:hover, &:active, &:focus {
    background-color: $blue-10;
  }
  &.active {
    background-color: $blue-20;
  }
}

.name {
  font-size: $font-size-4;
  font-weight: $med;
  line-height: 24px;
  @include text-truncate;
}

.move_date, .last_active_date {
  line-height: 16px;
  color: $black-50;
  transition: color 0.2s;
  .active & {
    color: rgba($blue-80,0.7);
  }
}

.status {
  text-transform: capitalize;
  font-size: $font-size-1;
  line-height: 16px;
  padding: 4px 0;
  flex: 0 0 auto;
  &:before {
    content:'';
    height: 7px;
    width: 7px;
    display: inline-block;
    border-radius: 4px;
    background-color: $black-20;
    margin-right: 5px;
    margin-bottom: 1px;
    background-color: currentColor;
  }
  &.core {
    color: $green-40;
  }
  &.active {
    color: $primary;
  }
  &.inactive {
    color: $black-70;
  }
  &.archived {
    color: $black-40;
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  color: $black-30;
  transition: color 0.2s;
  --color-2: currentColor;
  .active & { color: rgba($blue-80,0.3); }
}
