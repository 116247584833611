@use '../common/all' as *;
/**
 * Width Percentage
 */

$widths-map: (
  '10': 10%,
  '15': 15%,
  '20': 20%,
  '25': 25%,
  '30': 30%,
  '33': 33.333%,
  '40': 40%,
  '45': 45%,
  '50': 50%,
  '55': 55%,
  '60': 60%,
  '65': 65%,
  '66': 66.666%,
  '70': 70%,
  '75': 75%,
  '80': 80%,
  '85': 85%,
  '90': 90%,
  '100': 100%
);

@each $name, $width in $widths-map {
  .w-#{$name} { width: $width; }
}

@media screen and (max-width: $screen-sm-max) {
  @each $name, $width in $widths-map {
    .w-#{$name}-xs { width: $width; }
  }
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @each $name, $width in $widths-map {
    .w-#{$name}-sm { width: $width; }
  }
}

@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @each $name, $width in $widths-map {
    .w-#{$name}-md { width: $width; }
  }
}

@media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @each $name, $width in $widths-map {
    .w-#{$name}-lg { width: $width; }
  }
}

@media screen and (min-width: $screen-xlg-min) {
  @each $name, $width in $widths-map {
    .w-#{$name}-xlg { width: $width; }
  }
}

/**
 * Height
 */
.h-100 { min-height: 100%; }
.vh-100 { min-height: 100vh; }
