@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  font-size: $font-size-4;
  line-height: 20px;
  margin-bottom: 30px;
}

.asset_name {
  font-size: $font-size-3;
  font-weight: $regular;
}

.content {
  &.welcome-image {
    min-width: 350px;
  }
  &.dashboard-image {
    min-width: 500px;
  }
  &.logo {
    min-width: 720px;
  }
}

.upload_wrapper {
  min-width: 700px;
}
.dropzone {
  padding: 90px 60px;
}

.extra_restrictions {
  display: flex;
  justify-content: center;
  .restriction {
    color: $black-40;
    padding: 0 20px;
    border-right: 1px solid $black-20;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:last-child {
      border-right: none;
    }
    svg {
      --color-1: #{$black-40};
      --color-2: #{$black-40};
    }
  }
}

.crop_wrapper {
  display: flex;
  justify-content: center;
  .crop_preview {
    img {
      max-width: 700px;
      max-height: 500px;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .spacer {
    flex: 1 1 100%;
  }
}

.preview_wrapper {
  display: flex;
  justify-content: center;
}
