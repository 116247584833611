@use '../../../common/all' as *;

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  position: relative;
  padding: 0;
  margin: 8px 0 0 0;
  width: 100%;
  @include breakpoint(xs) {
    height: 30px;
  }
}

  .option {
    height: 44px;
    width: auto;
    flex-basis: 100%;
    border: 1px solid $black-30;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-weight: $semibold;
    padding: 0 24px;
    position: relative;
    z-index: 3;
    list-style-type: none;
    margin: 0;
    transition: color 0.2s linear, border-color 0.2s linear, background-color 0.2s linear;
    color: $black-70;
    text-align: center;
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
    &.active {
      color: $black-100;
      border-color: $black-100;
    }
    &:hover, &:active {
      color: $blue-50;
      border-color: $blue-50;
      background-color: rgba(255,255,255,0.15);
    }
    &.has_error {
      border-color: $red-40;
      color: $red-60;
    }
    @include breakpoint(xs) {
      height: 30px;
      width: 30px;
      padding: 0;
    }
  }

  .pill {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    width: 0;
    background-color: $black-20;
    border-radius: 8px;
    z-index: 2;
    transition: all 0.4s ease-in-out;
  }

  .pill_green {
    background-color: $black-20;
    opacity: 0;
    border-radius: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: opacity 0.4s ease-in-out;
  }
