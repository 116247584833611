@use '../../common/all' as *;
//Styles for address modal in the FieldAddress form element
.content {
  width: 460px;
  max-width: 100%;
  h3 {
    // text-align: center;
    line-height: 16px;
    margin-bottom: 40px;
  }
}

.property_type {
  margin-bottom: 50px !important;
}

.deed {
  margin-top: 25px;
}

.next {
  margin-top: 30px;
}
