@use '../../common/all' as *;
@use '../DynamicForm/styles/input.text' as *;

.card_input {
  @extend %text-underline;
}
.card_focus {
  border-bottom-color: $black-40;
}
.card_invalid {
  border-bottom-color: $red-40;
}
:export {
  placeholder: $black-40;
  color: $black-90;
  fonts: $base-sans-serif;
  invalid: $red-40;
}
