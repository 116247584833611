@use '../../../common/all' as *;
@use 'input.base' as *;

@mixin editor {
  @include base-input;
  line-height: 1.5em;
  border: 1px solid transparent;
  background-color: $white;
  border-radius: 8px;
  padding: 20px;
  resize: none;
  & ~ .input_label {
    margin-bottom: 20px;
  }
  &.has_error {
    color: $red-40;
    border: 1px solid $red-40 !important;
  }
}

%editor-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 10px;
  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
  }
}

%editor-preview {
  width: 800px;
  max-width: 100%;
}

%editor-default, %editor-stacked {
  @include editor;
  background-color: $bg-light;
  &.has_error {
    border-color: $red-40;
    color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }
  }
}

%editor-underline {
  @include editor;
  & ~ .input_label {
    color: $black-40;
  }

}
