@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  font-weight: $regular;
  margin-bottom: 50px;
  h3 {
    font-size: 20px;
    font-weight: $regular;
  }
}

.no_results {
  p {
    font-style: italic;
  }
  margin-bottom: 20px;
}

.assignment {
  width: 500px;
  max-width: 100%;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.form {
  max-height: 380px;
  overflow-x: visible;
  overflow-y: auto;
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
  & > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

// Item
.form .item {
  padding: 4px 17px 4px 4px;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.grow {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div {
    width: 50%;
  }
}

.splash {
  min-width: 64px;
  width: 64px;
  flex-basis: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  margin: 0 28px 0 0;
  border-radius: 4px;
  background-color: $black-20;
  overflow: hidden;
  background-size: auto 100%;
  outline: 1px solid rgba(21, 22, 24, 0.101961);
  outline-offset: -1px;
}
