$breakpoint-1: 768px;
$breakpoint-2: 962px;
$breakpoint-3: 1200px;                // unused currently
$breakpoint-4: 1400px;                // unused currently

$screen-xs-max: ($breakpoint-1 - 1);
$screen-sm-min: $breakpoint-1;
$screen-sm-max: ($breakpoint-2 - 1);
$screen-md-min: $breakpoint-2;
$screen-md-max: ($breakpoint-3 - 1);  // unused currently
$screen-lg-min: $breakpoint-3;        // unused currently
$screen-lg-max: ($breakpoint-4 - 1);  // unused currently
$screen-xl-min: $breakpoint-4;        // unused currently

// Breakpoints (xs, s, m, l, xl)
@mixin breakpoints($points...) {
  @each $point in $points {
    @if $point == xs {
      @media screen and (max-width: $screen-xs-max) { @content; }
    }
    @else if $point == sm {
      @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
    }
    @else if $point == md {
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
    }
    @else if $point == lg {
      @media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { @content; }
    }
    @else if $point == xl {
      @media screen and (min-width: $screen-xl-min) { @content; }
    }
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
  }
}

$responsive-layouts: mobile, tablet, desktop;

@mixin responsive($displays...) {
  @each $display in $displays {
    @if $display == mobile {
      @include breakpoints(xs) { @content; }
    }
    @else if $display == tablet {
      @include breakpoints(sm) { @content; }
    }
    @else if $display == desktop {
      @include breakpoints(md,lg,xl) { @content; }
    }
    @else {
      @warn "Responsive mixin supports: mobile, tablet, desktop";
    }
  }
}

// use this mixin to automatically create a 'naked' style and responsive layout
// suffixed overrides for each responsive layout
// i.e. .hidden, .hidden--mobile, .hidden--tablet, .hidden--desktop
@mixin withResponsive() {
  @each $layout in $responsive-layouts {
    @include responsive($layout) {
      &--#{$layout} { @content; }
    }
  }
}
