@use '~@moved/ui/src/sondheim/common' as *;

.content {
  width: 600px;
  min-height: 540px;
  display: flex;
  flex-direction: column;
}

.filters {
  padding: 0 0 $sp-16 0;
  border-bottom: 1px solid $borderTertiary;
  margin-bottom: $sp-16;
  flex: 0 0 auto;
}

.results_summary {
  display: flex;
  justify-content: space-between;
}

.row {
  display: flex;
  gap: 24px;
}

.logo {
  width: 154px;
  height: 24px;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  margin: -4px 0 -4px 4px; // 16px 24px (minus the parent padding of 20px)
}

.label {
  flex: 1 1 auto;
  margin: -10px 0; // 10px 24px (minus the parent padding of 20px)
}

.preview {
  flex: 0 0 auto;
  margin: -8px 0; // 12px 24px (minus the parent padding of 20px)
}

.spacer {
  flex: 1 1 100%;
}
