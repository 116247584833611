@use '~@moved/ui/src/balazs/common/all' as *;

.search_bar {
  display: flex;
  flex-direction: column;
  padding: 40px 0 10px;
  & > label {
    cursor: pointer;
  }
}

.search_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.search_action_icon {
  margin-left: 10px;
  cursor: pointer;
}

.add_user {
  color: $black-50;
  transition: color 0.2s, transform 0.2s;
  --color-1: #{$white};
  --color-2: currentColor;
  &:hover {
    color: $black-70;
    transform: scale(1.1);
  }
}

.advanced_toggle {
  color: $black-50;
  transition: color 0.2s, transform 0.2s;
  --color-1: currentColor;
  &:hover {
    color: $black-70;
    transform: scale(1.1);
  }
  &.active {
    color: $primary;
  }
  &.visible {
    color: $black-90;
    transform: rotate(180deg);
  }
}

.advanced_search {
  padding: 10px 0 0;
}

.filter_row {
  padding-bottom: 10px;
  display: flex;
  .save_search {
    margin: 4px 0;
    cursor: pointer;
  }
  .save_search_icon {
    color: $black-50;
    transition: color 0.2s, transform 0.2s;
    --color-1: currentColor;
    &:hover {
      color: $black-70;
      transform: scale(1.1);
    }
  }
}

.hint {
  font-size: $font-size-0 !important;
  font-style: italic;
  color: $black-40;
}

.search_options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 2px;
  gap: 4px 12px;
}
.sorts, .per_page {
  font-size: $font-size-1;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: flex-start;
  & > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 5px;
    padding: 0 5px;
    color: $black-40;
    transition: color 0.3s;

    svg {
      margin-left: 6px;
      --color-1: currentColor;
    }

    &.sortable {
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
    &.active {
      color: $black-90;
    }
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .name {
    color: $black-90;
    font-weight: bold;
  }
}
