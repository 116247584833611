@use '~@moved/ui/src/balazs/common/all' as *;


// Layout Wrapper
.properties_list {
  margin-top: 20px;
}

.box {
  display: block;
  background-color: $white;
  padding: 4px 20px 4px 4px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out, color 0.2s ease-in-out;
  color: $black-40;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &:active, &:focus {
    transform: scale(1.025);
    color: $black-90;
  }

  &.client_box {
    padding: 14px 14px 14px 0px;
  }

  &.pending {
    opacity: 0.4;
    pointer-events: none;
  }

  .splash {
    min-width: 64px;
    width: 64px;
    flex-basis: 64px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 48px;
    margin: 0 8px 0 0;
    border-radius: 4px;
    background-color: $black-20;
    overflow: hidden;
    background-size: auto 100%;
    outline: 1px solid rgba(21, 22, 24, 0.101961);
    outline-offset: -1px;
  }

  .grow {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > div {
      width: 50%;
    }
  }

  &.open {
    &:hover, &:active, &:focus {
      transform: scale(1);
      color: $black-40;
    }
  }

  .actions {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: inherit;
  }

}

.more_icon {
  cursor: pointer;
  margin-right: 10px;
  --color-1: #{$black-40};
  --color-2: #{$black-40};
  &:hover {
    --color-1: #{$blue-40};
    --color-2: #{$blue-40};
  }
}

.arrow_icon {
  color: inherit;
  transition: transform 0.3s ease-in-out;
  --color-1: currentColor;
  --color-2: currentColor;

  .open & {
    transform: rotate(90deg);
    --color-1: #{$blue-50};
    --color-2: #{$blue-50};
  }
}

.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    padding: 0 10px;
    color: $black-100;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.address_line {
  display: flex;
  flex-direction: column;
  .address_line_two {
    font-size: $font-size-1;
    color: $black-40;
  }
}

.property_popover {
  border: 0px none transparent;
  box-shadow: 0px 40px 69px rgba(0, 0, 0, 0.04), 0px 20px 30px rgba(0, 0, 0, 0.027), 0px -8px 11px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.013);
  border-radius: 13px;
  padding: 12px 0;
  user-select: none;
}

.popover_action {
  padding: 6px 18px;
  width: 186px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 12px;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }

  &:hover {
    color: $blue-40;
    svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
    }
  }
}

.box_bottom {
  color: $black-100;
  cursor: default;
}
