@use '../../common/all' as *;

.event {
  font-size: $font-size-2;
  line-height: 20px;
  padding-bottom: 16px;
  position: relative;
  padding-left: 22px;
  &::before {
    content:'';
    position: absolute;
    top: 6px;
    left: 0;
    background-color: $black-40;
    height: 7px;
    width: 7px;
    border-radius: 3px;
    z-index: 1;
  }
  &::after {
    content:'';
    position: absolute;
    top: 0;
    left: 3px;
    width: 1px;
    bottom: 0;
    background-color: $black-20;
    z-index: 0;
  }
  &:first-child::after {
    top: 10px;
  }
  &:last-child::after {
    bottom: auto;
    height: 10px;
  }
  &:first-child::after {
    top: 10px;
  }
  &:last-child:first-child::after {
    content: none;
  }

  &.green::before {
    background-color: $green-40;
  }
  &.red::before {
    background-color: $red-40;
  }
  &.yellow::before {
    background-color: $yellow-40;
  }
}

.event_content {
  font-size: $font-size-1;
  line-height: 16px;
  color: $black-40;
}
