@use '../../../common' as *;

@mixin base {
  align-items: center;
  justify-content: center;
  border-radius: 999em;
  white-space: nowrap;
}

@mixin small {
  @include base;
  padding: $sp-4 $sp-8;
}

@mixin medium {
  @include base;
  padding: $sp-8 $sp-12;
}
