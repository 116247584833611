@use '~@moved/ui/src/balazs/common/all' as *;

.image_block {
  margin-top: 50px;
}

.header {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}
.column {
  flex: 1 1 auto;
  &.actions {
    flex: 0 0 auto;
  }
}

.title {
  font-size: $font-size-3;
}

.uploader {
  display: flex;
  align-items: center;
  .thumbnail {
    flex: 0 0 auto;
    height: 56px;
    width: 56px;
    background-color: $bg-light;
    padding: 16px;
    border-radius: 999em;
    margin-right: 24px;
  }
}

.editor {
  display: flex;
  align-items: center;
  .thumbnail {
    flex: 0 0 auto;
    height: 100px;
    width: 100px;
    background-color: $bg-light;
    border-radius: 999em;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      flex: 0 0 auto;
      max-width: 60px;
      max-height: 60px;
    }
  }
}
