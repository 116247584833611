@use '../../../sondheim/common' as *;
@use 'sass:math';

$columnCount: 4;

.hidden {
  height: 0;
  visibility: hidden;
}

.filters {
  display: flex;
  gap: $sp-24;
  margin-bottom: $sp-24;
  & > * {
    flex: 1 1 50%;
  }
}

.scroller {
  width: 600px;
  max-width: 100%;
  height: 500px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.symbolList {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.symbolOption {
  flex: 0 0 calc(#{math.div(100%,$columnCount)} - #{math.div(16px*($columnCount - 1), $columnCount)});
}
