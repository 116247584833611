@use '../common/all' as *;
// ----------------------------------------
//  Text Element Declarations
// ----------------------------------------

/* ----------------------------------------
    Headers
---------------------------------------- */
h1, h2, h3, h4, h5, h6, h7 {
  margin: 0;
  color: inherit;
}

.mega {
  font-weight: $med;
  font-size: $font-size-8;
  line-height: 1.1;
}
h1, .h1 {
  font-weight: $med;
  font-size: $font-size-7;
  line-height: 1.5;
}
h2, .h2 {
  font-weight: $semibold;
  font-size: $font-size-5;
  line-height: 1.3;
}
h3, .h3 {
  font-weight: $semibold;
  font-size: $font-size-4;
  line-height: 1.5;
}
h4, .h4 {
  font-weight: $semibold;
  font-size: $font-size-2;
  line-height: 1.5;
}
h5, .h5 {
  font-weight: $semibold;
  font-size: $font-size-1;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 15px 0;
}
h6, .h6 {
  font-weight: $semibold;
  font-size: $font-size-0;
  line-height: 1.3;
  color: $black-40;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
h7, .h7 {
  font-weight: $light;
  font-size: $font-size-1;
  color: $black-40;
}
::selection {
  color: #fff;
  background: $black-90;
}

/* ----------------------------------------
    Text Blocks
---------------------------------------- */
p {
  margin: 0 0 20px;
  color: $text-color;
  &:last-child {
    margin-bottom: 0;
  }
}

/* ----------------------------------------
    Misc Typography Helpers
---------------------------------------- */
strong, .bold, b {
  font-weight: $bold;
}
em, .italic {
  font-style: italic;
}
.black {
  color: $black-90;
}
.gray {
  color: $black-40;
}
.primary {
  color: $primary;
}
.secondary {
  color: $secondary;
}
.red {
  color: $red-40;
}
