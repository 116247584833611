/**
 * Text Transform
 */
.capitalize { text-transform: capitalize; }
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }

/**
 * Font Style
 */
.italic { font-style: italic; }
.oblique { font-style: oblique; }


/**
 * Text Align
 */
.txt-center { text-align: center; }
.txt-left { text-align: left; }
.txt-right { text-align: right; }
