@use '../common' as *;

html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: grayscale;
}

body {
  @include base-text;
  background-color: $backgroundPrimary;
  zoom: 1;
  overflow-x: hidden;
}

.darkMode {
  background-color: $backgroundInversePrimary;
  filter: hue-rotate(180deg) invert(100%) contrast(85%); // invert all colors, rotate non grayscale colors back to original hue, drop the contrast
  img { filter: hue-rotate(180deg) invert(100%) contrast(118%); } // revert filter for images
  [style^='background-image:'] {
    filter: hue-rotate(180deg) invert(100%) contrast(118%); // revert filter for background images
    img { filter: none; } // don't double invert nested images
  }
}
