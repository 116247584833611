@use '../../../../common/all' as *;
@use '../../../DynamicForm/styles/input.base' as *;


/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.popover {
  background-color: $white;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 6px 24px 0 rgba(0,0,0,0.04);
  min-width: 330px;
}

.date_inputs {
  display: flex;
}

.date_bar {
  display: flex;
  position: relative;
  align-items: center;
  flex-basis: 50%;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
}

@mixin active-state {
  &:focus, &:active, &.has_value {
    @content;
  }
  &:not(:empty), &:not(:placeholder-shown) {
    @content;
  }
}

.date_input {
  @include base-input;
  height: 48px;
  text-overflow: ellipsis;
  border: 0px transparent none;
  font-size: $font-size-2;
  border: 1px solid transparent;
  border-bottom: 1px solid $black-40;
  padding: 15px 0 15px 30px;
  background-color: transparent;

  @include active-state {
    border-bottom-color: $black-90;
    & ~ .input_icon {
      svg {
        --color-1: #{$black-90};
        --color-2: #{$black-90};
      }
    }
  }
  &:focus, &:active {
    border-bottom-color: $blue-50;
  }
}

.input_icon {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: text;
  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.calendar {
  padding: 0;
  margin: 0;
  overflow-x: auto;
  overflow-y: visible;
  max-height: 390px;
  border-radius: 8px;
}
