@use '~@moved/ui/src/sondheim/common' as *;
@use './variables';

.profile_link {
  display: block;
  cursor: pointer;
  padding: 24px variables.$navPadding 30px;
  line-height: 16px;
  font-size: 12px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: $black-20;

    .name { color: $primary; }
    .icon { color: $primary; }
    .label { color: $black-50; }
  }

  &.profile_active {
    background-color: $black-30;
    .icon {
      transform: rotate(-180deg);
    }
  }
}

.content {
  position: relative;
}

.label {
  display: block;
  color: $black-40;
  transition: color 0.3s ease-in-out;
}

.name {
  color: $black-90;
  font-size: 14px;
  transition: color 0.3s ease-in-out;
}

.arrow {
  position: absolute;
  top: -4px;
  right: -4px;
  line-height: 0;
}

.icon {
  transition: color 0.3s ease-in-out;
  color: $black-90;
  transition: transform ease-in-out 0.25s;
  --color-1: currentColor;
}

// User actions popover
.user_popover {
  width: 244px;
}
