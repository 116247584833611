@use 'styles/sizes';
@use 'styles/colors';

.small    { @include sizes.small;     }
.medium   { @include sizes.medium;    }

.success  { @include colors.success;  }
.info     { @include colors.info;     }
.error    { @include colors.error;    }
.warning  { @include colors.warning;  }
.default  { @include colors.default;  }
