@use '../../../../../sondheim/common' as *;
@use 'sass:math';

.heading {
  @include headingS;
  margin-bottom: $sp-8;
}

$columnGap: $sp-32;
$bulletWidth: $sp-8;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $sp-8 $columnGap;
  margin: 0;
  padding: 0;
}

.item {
  @include labelM;
  color: $contentPrimary;
  padding-left: $sp-12;
  margin-left: $bulletWidth;

  // List Styles
  ul & {
    &::marker {
      content: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Ccircle cx='4' cy='4' r='3.5' stroke='%23151618'/%3E%3C/svg%3E");
      margin-left: $bulletWidth;
    }
  }
  ol & {
    &::marker {
      content: counter(list-item);
      margin-left: $bulletWidth;
    }
  }

  // Column Styles
  @for $i from 1 through 3 {
    .columns-#{$i} & { flex: 0 0 calc(#{math.div(100%,$i)} - #{$bulletWidth + math.div($columnGap*($i - 1),$i)}); }
  }

}
