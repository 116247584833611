@use '../colors/all' as colors;

%base_arrow {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 0;
  height: 0;
  border-style: solid;
}

@mixin arrow($size: 10px, $color: colors.$black-100, $dir: "down") {
  @extend %base_arrow;

  @if $dir == "up" {
    border-width:  0 $size $size $size;
    border-color: transparent transparent $color transparent;
  }

  @if $dir == "down" {
    border-width: $size $size 0 $size;
    border-color: $color transparent transparent transparent;
  }

  @if $dir == "left" {
    border-width: $size $size $size 0;
    border-color: transparent $color transparent transparent;
  }

  @if $dir == "right" {
    border-width: $size 0 $size $size;
    border-color: transparent transparent transparent $color;
  }
}
