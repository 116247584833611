@use '../../common/all' as *;

.dropzone {
  padding: 45px 50px;
  min-width: 500px;
  border: dashed 2px #C5C9CF;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0,0,0,0.0);
  transition: box-shadow 0.3s, background 0.3s, border 0.3s;
  @include breakpoint([xs,sm]) {
    min-width: 0;
  }
  // needs to be nested for the added specificity to override stockholm defaults
  .icon {
    display: inline-block;
    margin-bottom: 14px;
    vertical-align: top;
  }
  .icon-complete {
    background: $black-20;
    border-radius: 999em;
    padding: 8px;
    vertical-align: top;
  }

  &.disabled {
    border-color: $black-20;
    box-shadow: 0 0 15px rgba(0,0,0,0.0);
    cursor: default;
    &.active, &:focus {
      border-color: $black-20;
      box-shadow: 0 0 15px rgba(0,0,0,0.0);
    }
  }

  &:hover, &.active, &:focus {
    outline: none;
    border-color: $blue-50;
  }

  &.selected {
    border: solid 2px #C5C9CF;
    background-color: #fff;
  }

}

.copy {
  color: $black-90;
  font-size: $font-size-3;
}

.hint {
  color: $black-40;
  font-size: $font-size-2;
  margin-top: 10px;
}

.content_wrapper {
  margin-top: 30px;
}


.files_list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  position: relative;
}

.thumbnail {
  background-color: $black-10;
  height: 120px;
  width: 120px;
  padding: 16px;
  border-radius: 999em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  cursor: pointer;
  svg {
    --color-1: #C5C9CF; //#{$iconSingleEnabled};
    --color-2: #C5C9CF; //#{$iconSingleEnabled};
  }
  &:hover {
    svg {
      --color-1: #91979D; //#{$iconSingleHover};
      --color-2: #91979D; //#{$iconSingleHover};
    }
  }
  .file_preview:hover & {
    visibility: visible;
    opacity: 1;
  }

}

.error_notebox {
  width: 100%;
  svg {
    --color-1: #{$red-10} !important;
    --color-2: #{$red-50} !important;
  }
}

.error_file {
  margin-top: 6px;
  &:first-child {
    margin-top: 0;
  }
  h4 {
    color: $black-60;
  }
}
.errors {
  padding-left: 16px;
  margin: 0;
  font-size: $font-size-2;
  line-height: 20px;
  color: $black-50;
}

.error {
  border-color: $red-40;
  background-color: $red-10;
}
