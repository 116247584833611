@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Simple template layout
---------------------------------------- */

.template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 70px;
  background-color: $black-10;
}
