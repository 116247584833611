@use '~@moved/ui/src/balazs/common/all' as *;

.field {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }

  .label {
    flex: 1 1 34%;
    color: $black-40;
  }
  .value {
    flex: 1 1 66%;
  }
}
