@use '~@moved/ui/src/sondheim/common' as *;

.request {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  width: 100%;
  padding: 2px 8px;
  line-height: 130%;
  color: $contentSecondary;
  cursor: pointer;
  &:hover {
    background-color: rgba(0,0,0,0.08);
    .new { display: block; }
    .pending { display: none; }
  }
}

.new {
  @include labelXS;
  display: none;
}
