@use '~@moved/ui/src/balazs/common/all' as *;

.invite_modal {
  width: 500px;
  max-width: 100%;
}

.title {
  margin-bottom: 50px;
  h3 {
    font-size: 20px;
    font-weight: $med;
  }
}

.buttons {
  margin-top: 70px;
  display: flex;
  justify-content: flex-end;
}

.confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
