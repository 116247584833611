@use '~@moved/ui/src/balazs/common/all' as *;

.section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 26px;
  @include white-box;
  &:last-child {
    margin-bottom: 0;
  }
}

.note_form {
  position: relative;
  flex-direction: row;
  align-items: center;
}

.note_icon {
  margin-left: -4px;
  margin-right: 8px;
  --color-1: #{$white};
  --color-2: #{$black-100};
}

.note_input {
  & > textarea {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.note_check {
  position: absolute;
  display: block;
  z-index: 1;
  bottom: 20px;
  right: 10px;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  --color-1: #{$green-40};
  --color-2: #{$green-40};
  .note_complete & {
    opacity: 1;
    transform: scale(1);
  }
}
