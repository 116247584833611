@use '../../../../common' as *;
@use 'icons';
@use 'colors';

@mixin base {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999em;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  transition: padding 0.2s ease-out, colors.$colorTransition;
  &:disabled, &:global(.disabled) {
    cursor: default;
  }
}

@mixin small {
  @include base;
  @include labelM;
  height: 32px;
  padding: 6px 16px;
  &.withIconLeft {
    @include icons.iconLeftSmall;
  }
  &.withIconRight {
    @include icons.iconRightSmall;
  }
  &.withIconOnly {
    @include icons.iconOnlySmall;
  }
}

@mixin default {
  @include base;
  @include labelL;
  height: 44px;
  padding: 10px 32px;
  &.withIconLeft {
    @include icons.iconLeftDefault;
  }
  &.withIconRight {
    @include icons.iconRightDefault;
  }
  &.withIconOnly {
    @include icons.iconOnlyDefault;
  }
}

@mixin large {
  @include base;
  @include labelL;
  height: 56px;
  padding: 16px 40px;
  &.withIconLeft {
    @include icons.iconLeftLarge;
  }
  &.withIconRight {
    @include icons.iconRightLarge;
  }
  &.withIconOnly {
    @include icons.iconOnlyLarge;
  }
}
