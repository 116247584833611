@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.day_column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 0;
  margin-left: -1px;

  .day_col_border {
    border-left: 1px solid $normalVertical;
    border-right: 1px solid $normalVertical;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  position: relative;


  &:first-child {
    margin-left: 0;
  }

  &.today {
    .day_col_border {
      border-color: $today;
      z-index: 4;
    }
  }
  &:hover {
    .day_col_border {
      border-color: $hover;
      z-index: 5;
    }
  }

}

.day_header {
  text-align: center;
  height: $calendarHeaderHeight;
  padding: 15px;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $black-20;
  background-color: $black-10;
  z-index: 37;
  display: flex;
  .day_column:hover & {
    border-bottom-color: $black-30;
    .header_line {
      display: block;
    }
  }

  .header_line {
    height: 2px;
    background-color: $black-30;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    .today & {
      background-color: $blue-40;
      display: block;
    }
  }
}

.day_of_week {
  @include labelXS;
  color: $black-40;
  margin: 0 0 3px;
  .today & {
    color: $blue-50;
  }
}

.day_of_month {
  @include headingL;
  color: $black-90;
  span {
    border-radius: 40px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
  }
  .no_slots & {
    color: $black-40;
  }
  .today & {
    color: $blue-50;
  }
}

.slot_holder {
  position: relative;
  flex: 1 1 auto;
  .day_column:hover & {
    background-color: rgba(0,0,0,0.02);
  }
}
