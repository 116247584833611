@use '~@moved/ui/src/balazs/common/all' as *;
@use '~@moved/ui/src/balazs/ui/keyframes' as *;

.main {
  padding-bottom: 60px;
}

.task_title {
  margin: 30px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.section_title {
  margin: 30px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task_terms {
  color: $blue-50;
}

.task_count {
  color: $black-40;
  margin-left: 8px;
}


// Actions
.create {
  @extend %btn-gray;
  @extend %btn--small;
  padding-left: 10px;
  padding-right: 16px;
  svg {
    margin-right: 9px;
    --color-1: #{$black-20};
    --color-2: #{$black-100};
  }
}

// Task Content
.hidden {
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  outline: 1px solid $black-20;

  &:hover {
    background-color: rgba(255,255,255,0.3);
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &.link {
    cursor: pointer;
  }
  h4 {
    font-weight: $regular;
    margin-left: 24px;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pill {
  border-radius: 20px;
  padding: 4px 10px;
  background-color: $black-20;
  color: $black-60;
  font-size: $font-size-0;
  line-height: $font-size-0;
  font-weight: $med;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 16px;
  animation: fadeIn 0.2s ease-in-out both;
  pointer-events: none;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: right;
}

.icon {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.drag {
    cursor: grab;
  }
}

.icon_trash {
  cursor: pointer;

  svg {
    --color-1: #{$red-30};
    --color-2: #{$red-30};
  }

  &:hover {
    svg {
      --color-1: #{$red-50};
      --color-2: #{$red-50};
    }
  }
}

.icon_more {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$blue-50};
      --color-2: #{$blue-50};
    }
  }
}

.task_popover {
  border: 0px none transparent;
  box-shadow: 0px 40px 69px rgba(0, 0, 0, 0.04), 0px 20px 30px rgba(0, 0, 0, 0.027), 0px -8px 11px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.013);
  border-radius: 13px;
  padding: 12px 0;
  user-select: none;
}

.popover_action {
  padding: 6px 18px;
  width: 126px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & > svg {
    margin-right: 12px;
    --color-1: #{$black-40};
    --color-2: #{$black-40};
    &.visible {
      --color-1: #{$white};
    }
  }

  &:hover {
    color: $blue-40;
    & > svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
      &.visible {
        --color-1: #{$white};
      }
    }
  }
}


// No task treatment
.no_task {
  background-color: $orange-10;
  border: 1px solid $orange-30;
  border-radius: 8px;
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  // flex-direction: column;

  .warning {
    --color-1: #{$orange-60};
    --color-2: #{$orange-20};
  }

  h3 {
    margin: 12px 0;
    color: $black-100;
    font-weight: $regular;
    font-size: $font-size-3;
  }
}

.reminder {
  @extend %btn-ghost;
  $paddingSize: 16px;
  padding-left: $paddingSize;
  padding-right: $paddingSize;
  span {
    margin-left: 8px;
    font-size: $font-size-2;
  }
}
