@use '~@moved/ui/src/balazs/common/all' as *;

.status_label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.status_icon {
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background-color: $black-40;
  &.core {
    background-color: $green-40;
  }
  &.active {
    background-color: $blue-40;
  }
  &.inactive {
    background-color: $black-40;
  }
  &.archived {
    background-color: $black-10;
  }
}
