@use '../../common/all' as *;

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-2;
  margin-bottom: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $black-20;
  gap: 20px;

  &.hide_divider {
    padding-bottom: 0;
    border-bottom: none;
  }

  &.icon {
    padding-left: 39px;
    .label { margin-left: -39px; }
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0 none transparent;
  }

  @include breakpoint(xs) {
    align-items: flex-start;
  }

}

.label {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.icon_padding {
  width: 39px;
}
