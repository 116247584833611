@use '~@moved/ui/src/sondheim/common' as *;

.month {
  transition: background-color ease-in-out 0.25s;
  padding: $sp-4 $sp-12;
  border-radius: $radius-6;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: $black-20;
  }
}
