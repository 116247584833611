@use '~@moved/ui/src/balazs/common/all' as *;

.background {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #F6F7F8;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.container {
  overflow: hidden;
  width: 680px;
  margin: 56px 0;
  background-color: $white;
  border-radius: 12px;
  color: $text-color;
  @include breakpoint(xs) {
    margin: 12px;
  }
}

.content {
  padding: 36px 60px;
  @include breakpoint(xs) {
    padding: 20px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  padding: 38px 60px;
  @include breakpoint(xs) {
    padding: 24px;
  }
}

.moved_logo {
  height: 18px;
  filter: invert(1);
}

.copyright {
  opacity: 0.5;
  color: $white;
  font-size: 14px;
  line-height: 22px;
  @include breakpoint(xs) {
    font-size: 12px;
    line-height: 16px;
  }
}
