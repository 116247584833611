@use '../../../common' as *;

.wrapper {
  display: flex;
  gap: $sp-2;
  align-items: center;
  justify-content: stretch;
  flex-wrap: nowrap;
  background-color: $backgroundTertiary;
  border-radius: $radius-8;
  padding: $sp-2;
  position: relative;
  z-index: 1;
}

.option {
  flex: 1 1 auto;
  color: $contentSecondary;
  cursor: pointer;
  user-select: none;
  text-align: center;
  position: relative;
  z-index: 3;
  transition: color 0.1s linear;
  &:hover, &.active { color: $contentPrimary; }
  .small & {
    @include labelS;
    padding: $sp-4 $sp-12;
  }
  .large & {
    @include labelM;
    padding: $sp-8 $sp-24;
  }
  .disabled & {
    cursor: default;
    &:hover, &.active { color: $contentSecondary; }
  }
}

.pill {
  position: absolute;
  display: block;
  inset: $sp-2 auto $sp-2 $sp-2;
  width: 0;
  background-color: $backgroundSecondary;
  border-radius: $radius-6;
  z-index: 2;
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out, background-color 0.2s ease-in-out;
  box-shadow: $shadow-2;
}
