@use '../../../common/all' as *;
@use 'input.base' as *;

%item-list-default,
%item-list-stacked,
%item-list-underline {
  .search_bar {
     display: flex;
     position: relative;
     align-items: center;
     width: 100%;
     margin-bottom: 12px;
   }

   @mixin active-state {
     &:focus, &:active, &.has_value {
       @content;
     }
     &:not(:empty), &:not(:placeholder-shown) {
       @content;
     }
   }

  & ~ label {
    margin-bottom: 10px;
  }

  .input_icon {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: text;
    svg {
      --color-1: #{$black-40};
      --color-2: #{$black-40};
    }
  }

   .input_clear {
     position: absolute;
     right: 0;
     z-index: 1;
     cursor: pointer;
     svg {
       --color-1: #{$black-40};
       --color-2: #{$black-40};
       &:hover {
         --color-1: #{$red-30};
         --color-2: #{$red-30};
       }
     }
   }

   .search_input {
     @include base-input;
     height: 48px;
     text-overflow: ellipsis;
     border: 0px transparent none;
     font-size: $font-size-2;
     border: 1px solid transparent;
     border-bottom: 1px solid $black-40;
     padding: 15px 0 15px 30px;
     background-color: transparent;

     @include active-state {
       border-bottom-color: $black-90;
       & ~ .input_icon {
         svg {
           --color-1: #{$black-90};
           --color-2: #{$black-90};
         }
       }
     }
     &:focus, &:active {
       border-bottom-color: $blue-50;
       & ~ .input_icon {
         svg {
           --color-1: #{$blue-50};
           --color-2: #{$blue-50};
         }
       }
     }
   }
}
