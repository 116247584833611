@use '~@moved/ui/src/balazs/common/all' as *;

.create {
  @extend %btn-gray;
  @extend %btn--small;
  padding-left: 10px;
  padding-right: 16px;
  svg {
    margin-right: 9px;
    --color-1: #{$black-20};
    --color-2: #{$black-100};
  }
}

.create_popover {
  border: 0px none transparent;
  box-shadow: 0px 40px 69px rgba(0, 0, 0, 0.04), 0px 20px 30px rgba(0, 0, 0, 0.027), 0px -8px 11px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.013);
  border-radius: 13px !important;
  padding: 12px 0;
  user-select: none;
  min-width: 200px;
}

.popover_action {
  padding: 6px 18px;
  width: 240px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & > svg {
    margin-right: 12px;
  }

  &:hover {
    color: $blue-40;
    & > svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
    }
  }
}
