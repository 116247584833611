@use '../../../common' as *;
@use 'styles/sizes';

.container {
  display: flex;
  flex-direction: column;

  &.small   {   @include sizes.small;   }
  &.large   {   @include sizes.large;   }
  &.square  {   @include sizes.square;  }

  &.selected {
    border: 1px solid $borderAccent;
  }
  &.selected.disabled {
    border: 1px solid $borderAccentLight;
  }
  &.disabled, &.disabled:hover {
    cursor: default;
    background: $backgroundStateDisabled;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.05);
  }
  &:hover {
    background: $backgroundStateHover;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);
  }
}

.basicContent {
  display: flex;
  align-items: center;
}

.input {
  display:none;
  & ~ .checkmark {
  --color-1: #{$iconMultiPrimary};
  --color-2: #{$iconMultiPrimaryLight};
  }

  &:checked ~ .checkmark {
    --color-1: #{$iconOnColor};
    --color-2: #{$iconMultiAccent};
  }

  &:disabled ~ .checkmark {
    opacity: 0.6;
  }

  &:checked:disabled ~ .checkmark {
    opacity: 1;
    --color-2: #{$iconMultiAccentLight};
  }
}

.icon {
  flex: 0 0 auto;
  --color-1: #{$iconMultiPrimary};
  --color-2: #{$iconMultiPrimaryLight};
  .disabled & {
    opacity: 0.6;
  }
}

.content {
  flex: 1 1 auto;
  cursor: pointer;
  user-select: none;
  .disabled & {
    cursor: default;
  }
}
