@use '~@moved/ui/src/balazs/common/all' as *;

.icon {
  flex-basis: 4%;
  padding-right: 10px;
}

.name {
  flex-basis: 22%;
  min-width: 150px;
}

.num_buildings {}

.partner_name {}

.invite_status {}

.arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
}
