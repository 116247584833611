@use '../common' as *;

@each $name, $spacing in $spacings {

  .width-#{$name} { width: $spacing;}
  .height-#{$name} { height: $spacing; }

}

.width-full { width: 100%; }
.width-three-quarters { width: 75%; }
.width-half { width: 50%; }
.width-quarter { width: 25%; }
.width-eighth { width: 12.5%; }
.width-tenth { width: 10%; }
.width-twentieth { width: 5%; }

.height-full { height: 100%; }
.height-half { height: 50%; }
.height-quarter { height: 25%; }
