@use '../mixins/responsive' as *;

@mixin labelL {

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @include responsive(mobile) {
    /* no differences */
  }

}

@mixin labelM {

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @include responsive(mobile) {
    /* no differences */
  }

}

@mixin labelS {

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @include responsive(mobile) {
    /* no differences */
  }

}

@mixin labelXS {

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @include responsive(mobile) {
    /* no differences */
  }

}
