@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 808px;
}

.title {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: $font-size-3;
  font-weight: $regular;
}

.link_existing_btn {
  --color-1: #{$white};
  --color-2: #{$white};
}

.new_list_btn {
  --color-1: #{$black-20};
  --color-2: #{$black-100};
}
