@use '../../common' as *;

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $sp-8;
}

.page {
  padding: $sp-2 $sp-8;
  color: $contentSecondary;
  transition: color 0.3s;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border-radius: 4px;
  &:hover {
    color: $contentPrimary;
  }
  // Current page
  &.active {
    color: $contentInversePrimary;
    background: $backgroundInversePrimary;
    cursor: default;
  }
  // Previous and next page button
  &.turn_page {
    padding: 0;
    margin: 0 8px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      --color-1: currentColor;
      --color-2: currentColor;
    }
  }
  &.disabled {
    cursor: default;
    color: $contentTertiary;
  }
}
.spacer {
  color: $contentTertiary;
  padding: 0;
  cursor: default;
}
