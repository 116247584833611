@use '~@moved/ui/src/balazs/common/all' as *;

@mixin action-btn {
  padding: 6px 16px;
  line-height: 20px;
  height: 32px;
  font-size: $font-size-2;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-right: 8px;
    --color-1: #{$black-90};
    --color-2: #{$black-30};
  }

}

.ghost {
  @extend %btn-ghost;
  @include action-btn;
}

.gray {
  @extend %btn-gray;
  @include action-btn;
  svg {
    --color-1: #{$black-100};
    --color-2: #{$black-40};
  }
}

.cancel {
  @extend %btn-ghost-red;
  @include action-btn;
}
