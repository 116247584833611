@use '../../../common/all' as *;

%date-picker-default,
%date-picker-stacked,
%date-picker-underline {
  &.full {
    width: 100%;
  }
}

%date-picker-underline {
  & ~ label {
    margin-bottom: -8px;
  }
}
