@use '../../common/all' as *;

.option {
  flex: 1;
  min-width: 113px;
  outline: 0;
  position: relative;
  @include breakpoint(xs) {
    min-width: 84px;
  }
}

.option_content {
  border-radius: 8px;
  border: 2px solid transparent;
  margin: 12px 0;
  color: $black-90;
  min-height: 100px;
  text-align: center;
  cursor: pointer;
  transition: background-color .2s ease, border-color 0.2s ease;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.03);
  background-color: $white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:hover {
    border-color: $green-30;
  }

  .disabled & {
    cursor: not-allowed;
    opacity: 0.6;
    color: #999;
    &:hover {
      opacity: 0.6;
      background-color: rgba(255,255,255,0.9);
    }
  }

  .selected & {
    background-color: $green-10;
    border-color: $green-40;

    &:hover {
      background-color: $green-20;
      border-color: $green-40;
    }
  }

}
