@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  width: 500px;
  max-width: 100%;
}

.previous_message {
  color: $red-50;
  border: 1px solid $red-50;
  background: $red-10;
  padding: 10px 16px;
  font-size: $font-size-2;
  border-radius: 6px;
}
