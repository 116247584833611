.atom_spinner {
  width: 40px;
  height: 40px;
  img {
    width: 100%;
  }
}
.movr-logo {
  display: block;
}
  .white {
    filter: brightness(100);
  }
