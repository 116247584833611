@use "sass:map";

$sizes: 0, 2, 4, 8, 12, 16, 18, 20, 24, 28, 32, 40, 48, 64, 80, 96, 160;

$spacings: ();
@each $size in $sizes {
  $spacings: map.set($spacings, #{$size}, $size * 1px);
}

@function spacing($name) {
  @return map.get($spacings, $name);
}

$sp-0: spacing('0');
$sp-2: spacing('2');
$sp-4: spacing('4');
$sp-8: spacing('8');
$sp-12: spacing('12');
$sp-16: spacing('16');
$sp-18: spacing('18');
$sp-20: spacing('20');
$sp-24: spacing('24');
$sp-28: spacing('28');
$sp-32: spacing('32');
$sp-40: spacing('40');
$sp-48: spacing('48');
$sp-64: spacing('64');
$sp-80: spacing('80');
$sp-96: spacing('96');
$sp-160: spacing('160');
