@use '../../common/all' as *;

.image_upload {
  display: block;
}

/* ----------------------------------------
    Image Cropper
---------------------------------------- */

.cropzone {
  border: 2px solid $black-30;
  border-radius: 12px;
  padding: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    max-width: 100%;
    max-height: 400px;
  }
}

.actions {
  z-index: 100;
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
}

.button_wrapper {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  text-align: center;
  cursor: pointer;
  color: $black-100;
  background-color: $white;
  background-image: radial-gradient(circle at center , $blue-30 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  border-radius: 100px;
  user-select: none;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);

  &:hover {
    background-size: 200% 200%;
    color: $blue-40;
    svg {
      --color-1: #{$white};
      --color-2: #{$white};
    }
  }
  &:focus, &:active {
    background-color: $blue-20;
    background-image: none;
  }

  svg {
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }
}

.remove {
  background-image: radial-gradient(circle at center , $red-30 50%, transparent 50%);
  &:hover {
    background-size: 200% 200%;
    color: $red-40;
  }
  &:focus, &:active {
    background-color: $red-20;
  }
}

.popover {
  white-space: nowrap;
}



// Initial file
.uploaded_file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  min-width: 500px;
  border-radius: 16px;
  border: solid 2px #C5C9CF;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 0 15px rgba(0,0,0,0.0);
  position: relative;
  @include breakpoint([xs]) {
    min-width: 0;
  }
}

.image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  padding: 16px;
  border-radius: 200px;
  background-color: $black-20;
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image_name {
  margin-top: 20px;
  font-size: $font-size-3;
}

.remove_wrapper {
  z-index: 100;
  position: absolute;
  top: 10px;
  right: 10px;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  text-align: center;
  cursor: pointer;
  color: $white;
  background-color: $red-30;
  background-image: radial-gradient(circle at center , $red-60 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  border-radius: 100px;
  user-select: none;
  &:hover {
    background-size: 200% 200%;
    color: $red-70;
  }
  &:focus, &:active {
    background-color: $black-20;
    background-image: none;
  }

  svg {
    --color-1: #{$white};
    --color-2: #{$white};
  }
}

.popover {
  white-space: nowrap;
}
