@use '~@moved/ui/src/sondheim/common' as *;

.snowToggle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: $sp-4 $sp-16 $sp-8;
  border-bottom-right-radius: $radius-12;
  border-bottom-left-radius: $radius-12;
  background: #fff;
  box-shadow: $shadow-4;
}

.snowfield {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  pointer-events: none;
  &.hidden {
    display: none;
  }
}
