/* move from / to  */
@keyframes moveToTop {
	from { }
	to { transform: translateY(-100%); }
}

@keyframes moveFromBottom {
	from { transform: translateY(100%); }
}

.enter, .exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	visibility: hidden;
	overflow: hidden;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
}

.enterActive {
  visibility: visible;
  animation: moveFromBottom .6s ease both;
}

.exitActive {
  visibility: visible;
  animation: moveToTop .7s ease-in-out both;
  z-index: 2;
}
