$shadow-1: 0px 2px 4px rgba(0, 0, 0, 0.04);

$shadow-2: 0px 3px 8px rgba(0, 0, 0, 0.05);

$shadow-3: 0px 6px 24px rgba(0, 0, 0, 0.06);

$shadow-4: 0px 40px 69px rgba(0, 0, 0, 0.04),
           0px 20px 30px rgba(0, 0, 0, 0.027),
           0px -8px 11px rgba(0, 0, 0, 0.02),
           0px 2px 4px rgba(0, 0, 0, 0.013);

$shadow-5: 0px 72px 134px rgba(0, 0, 0, 0.05),
           0px 47px 78px rgba(0, 0, 0, 0.038),
           0px 28px 43px rgba(0, 0, 0, 0.030),
           0px 14px 22px rgba(0, 0, 0, 0.025),
           0px 6px 11px rgba(0, 0, 0, 0.02),
           0px 1px 5px rgba(0, 0, 0, 0.012);
