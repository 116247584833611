@use '~@moved/ui/src/balazs/common/all' as *;

.copy {
  border-radius: 26px;
  background-color: $white;
  padding: 6px 16px;
  color: $black-90;
  font-family: $base-sans-serif;
  &:focus {
    outline: 0;
    border-color: $blue-40;
    box-shadow: 0 0 0 1px $blue-40;
  }
}
