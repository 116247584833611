@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 26px;
}

.actions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.note_box {
  margin: 12px 0 24px;
  max-width: 576px;
}
