@use '../../../common/all' as *;

@mixin base-input {
  display: block;
  width: 100%;
  padding: 15px;

  line-height: 15px;
  font-size: $font-size-2;
  color: $black-90;
  outline: none;
  position: relative;
  z-index: 1;
  transition: border-color 0.2s, color 0.2s, background-color 0.2s;
  &::placeholder {
    color: $black-40;
  }
  &[readonly], &[contenteditable="false"] {
    color: $black-40;
    border-color: transparent !important;
    pointer-events: none;
  }

  &:-webkit-autofill {
    &, &:hover, &:focus, &:active {
      transition: color 5000s 5000s, background-color 5000s 5000s, border-color 0.2s;
      background-color: transparent !important;
      -webkit-text-fill-color: inherit !important;
    }
  }
}
