@use '../../common/all' as *;

.tabs {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin-left: 0;
  border-bottom: 1px solid #dfe1e4;
  position: relative;
}

.tab {
  list-style-type: none;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  color: $black-40;
  font-size: $font-size-2;
  font-weight: $regular;

  &:hover {
    color: $blue-50;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tab_active {
  color: $black-90;
}

.icon {
  margin-right: 10px;
  --color-1: currentColor;
  --color-2: currentColor;
}

.spacer {
  min-width: 40px;
}

.bar {
  background-color: $blue-50;
  width: 0;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
