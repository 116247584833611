@use '~@moved/ui/src/balazs/common/all' as *;

.menu_item {

  a {
    color: $black-40;
    align-items: center;
    font-size: $font-size-2;
    font-weight: 400;
    padding: 12px 32px;
    position: relative;
    z-index: 2;
    transition: color 0.2s, padding 0.4s cubic-bezier(.65, 0, .35, 1);
    display: flex;
    flex-wrap: nowrap;
    &:hover, &:active {
      color: $blue-50;
    }
    &.active {
      color: $black-90;
    }
  }

  .icon {
    margin-right: 16px;
    transition: margin 0.4s;
    --color-1: currentColor;
    --color-2: currentColor;
  }

  .label {
    transition: opacity 0.3s, transform 0.3s;
    white-space: nowrap;
    overflow: hidden;
  }
  @for $i from 1 through 8 {
    &:nth-of-type(#{$i}) {
      .label {
        transition-delay: #{200 * $i}ms;
      }
    }
  }

  &.collapsed {
    a {
      padding: 12px 20px;
    }
    .icon {
      margin-right: 0;
    }
    .label {
      transition: all 0.3s 0s;
      opacity: 0;
      visibility: hidden;
      transform: translateX(20px);
    }
  }

}
