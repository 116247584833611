@use "sass:math";
@use '../common' as *;

// Combo spacings (put first so individual spacings can override)
@each $name, $spacing in $spacings {
  // for each combination of symmetrical spacings top/bottom and left/right
  @each $name2, $spacing2 in $spacings {
    .margin-#{$name}-#{$name2} { margin: $spacing $spacing2; }
    .padding-#{$name}-#{$name2} { padding: $spacing $spacing2; }
  }
}

// Basic spacings
@each $name, $spacing in $spacings {

  .margin-#{$name} { margin: $spacing; }
  .marginTop-#{$name} { margin-top: $spacing; }
  .marginRight-#{$name} { margin-right: $spacing; }
  .marginBottom-#{$name} { margin-bottom: $spacing; }
  .marginLeft-#{$name} { margin-left: $spacing; }

  .padding-#{$name} { padding: $spacing; }
  .paddingTop-#{$name} { padding-top: $spacing; }
  .paddingRight-#{$name} { padding-right: $spacing; }
  .paddingBottom-#{$name} { padding-bottom: $spacing; }
  .paddingLeft-#{$name} { padding-left: $spacing; }

  .gap-#{$name} { gap: $spacing; }

}

// Responsive spacings
// these need to be outside and after the base styles so that they can have priority
// to override with responsive styles since media queries do not add specificity :(
@each $name, $spacing in $spacings {

  .margin-#{$name} { @include withResponsive() { margin: $spacing; } }
  .marginTop-#{$name} { @include withResponsive() { margin-top: $spacing; } }
  .marginRight-#{$name} { @include withResponsive() { margin-right: $spacing; } }
  .marginBottom-#{$name} { @include withResponsive() { margin-bottom: $spacing; } }
  .marginLeft-#{$name} { @include withResponsive() { margin-left: $spacing; } }

  .padding-#{$name} { @include withResponsive() { padding: $spacing; } }
  .paddingTop-#{$name} { @include withResponsive() { padding-top: $spacing; } }
  .paddingRight-#{$name} { @include withResponsive() { padding-right: $spacing; } }
  .paddingBottom-#{$name} { @include withResponsive() { padding-bottom: $spacing; } }
  .paddingLeft-#{$name} { @include withResponsive() { padding-left: $spacing; } }

  .gap-#{$name} { @include withResponsive() { gap: $spacing; }}

}
