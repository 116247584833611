@use '~@moved/ui/src/sondheim/common' as *;

// Column: Normal, Today, Hover Border Colors
$normal: $black-20;
$normalVertical: $black-20;
$active: $black-30;
$today: $blue-20;
$hover: $black-30;

// Cell: Hover & Active Colors
$cellHover: $blue-20;
$cellHoverBG: $white;
$cellActiveBG: $blue-10;

// Sizes:
$keyWidth: 60px;
$calendarHeaderHeight: 80px;
