@use '../../common/all' as *;

/* ----------------------------------------
    Search trigger styling
---------------------------------------- */

.wrapper {
  position: relative;
}

.trigger {
  cursor: pointer;
}

.menu {
  position: absolute;
  z-index: 60;
  top: 100%;
  left: 0;
  margin-top: 8px;
}

.popover {
  background-color: $white;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 6px 24px 0 rgba(0,0,0,0.04);
  min-width: 330px;
}

.title_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h4 {
    font-weight: $regular;
    font-size: 16px;
  }
  svg {
    cursor: pointer;
    --color-1: #{$black-90};
    --color-2: #{$black-90};
    &:hover {
      --color-1: #{$red-40};
      --color-2: #{$red-40};
    }
  }
}
