@use '../../../common/all' as *;

%type-ahead-default,
%type-ahead-stacked,
%type-ahead-underline {
  & + .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0 0 6px 6px;
    max-height: 250px;
    z-index: 120;
    display: flex;
    flex-direction: column;
    box-shadow: 0 6px 24px 0 rgba(0,0,0,0.12);
    padding: 6px 0;
    .menu_option {
      padding: 6px 16px;
      font-size: $font-size-2;
      cursor: pointer;
      &:hover {
        background-color: $blue-20;
      }

      .term {
        color: $black-40;
      }
    }
    .labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        color: $black-70;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
