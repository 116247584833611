@use '~@moved/ui/src/balazs/common/all' as *;

.lease_info {
  @include white-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
  margin-bottom: 40px;
  .row {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 16px;
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .block {
    flex: 1 1 33.3%;
    padding: 0 28px;

    label {
      display: block;
      font-size: $font-size-1;
      color: $black-40;
      margin-bottom: 2px;
      padding: 0;
    }

    .value {
      font-size: $font-size-3;
      font-weight: $med;
    }

    .clipboard {
      font-size: $font-size-1;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
