@use '../../common/all' as *;

.container {
  position: relative;
  svg {
    display: block;
  }
}
.fraction {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  font-size: $font-size-1;
  line-height: 100%;
}
.circle_bg {}
.circle_fill {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
