@use "~@moved/ui/src/balazs/common/all" as *;

.pet_modal {
  width: 500px;
  max-width: 100%;
}

.title {
  margin-bottom: 40px;
  h3 {
    font-size: 20px;
    font-weight: $med;
  }
}

.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.intro {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.photo_container {
  max-width: 120px;
  width: 100%;
}

.photo {
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 800px;
}

.highlights {
  margin-left: 20px;
  width: 100%;
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.subtitle {
  color: $black-50;
  font-size: $font-size-3;
  margin-top: 20px;
}

.fields {
  margin-top: 25px;
  .row {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-2;
    margin: 0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $black-20;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0 none transparent;
    }
    @include breakpoint(xs) {
      align-items: flex-start;
    }
  }
  .icon {
    margin-right: 15px;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $black-40;

    @include breakpoint(xs) {
      flex: 0 0 auto;
    }
  }
  .value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    @include breakpoint(xs) {
      margin-left: 20px;
    }
  }
  &.borderless {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
