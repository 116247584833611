@use '../../../../../sondheim/common' as *;

.spacer {
  display: block;

  &.small {
    height: $sp-16;
  }
  &.medium {
    height: $sp-24;
  }
  &.large {
    height: $sp-40;
  }

  &.editor {
    &:hover {
      background: $red-20;
    }
  }

}

:global(.ce-block):has(+ :global(.ce-block) .spacer) {
  padding-bottom: 0 !important;
}
:global(.ce-block):has(.spacer) {
  padding-bottom: 0 !important;
}
