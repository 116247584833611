@use '~@moved/ui/src/balazs/common/all' as *;

.logo_block {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  flex-direction: column;
  &.small {
    height: 48px;
    width: auto;
    justify-content: flex-start;
    flex: 0 0 auto;
    flex-direction: row;
  }
  &.inverted {
    filter: brightness(0) invert(1);
  }
  &.horizontal {
    flex-direction: row;
    height: 32px;

    .divider {
      width: 1px;
      height: 100%;
      max-height: 40px;
      margin: 0 20px;
    }

    .building_logo {
      max-height: 32px;
    }

    .mvd_logo {
      height: 32px;
    }
  }
}

  .building_logo {
    max-width: 144px;
    max-height: 90px;
    transition: height ease-in 0.3s;
    display: block;
    .small & {
      max-height: 34px;
      max-width: 100px;
      height: auto;
    }
    :global(.darkMode) & { filter: brightness(0); }
    @include breakpoint(xs) {
      max-height: 34px;
      max-width: 100px;
    }
  }

  .divider {
    display: block;
    background-color: $black-30;
    opacity: 0.5;
    height: 1px;
    width: 100%;
    max-width: 217px;
    margin: 32px 0;
    flex: 0 0 1px;
    .inverted & {
      color: $white;
      opacity: 0.3;
    }
    .small & {
      height: 32px;
      margin: 0 20px;
    }
    @include breakpoint([xs]) {
      height: 35px;
      width: 1px;
      margin: 0 16px;
    }
  }

  .mvd_logo {
    display: block;
    height: 24px;
    @include breakpoint([xs]) {
      height: 20px;
    }
  }
  .mvd_icon {
    display: block;
    height: 12px;
    @include breakpoint([xs]) {
      height: 20px;
    }
  }
