@use '../../common' as *;

.group {
  border-radius: $radius-20;
  padding: 0 $sp-16;
  gap: 0;
  transition: padding .3s ease-out,
              background-color .3s ease-out,
              gap .3s ease-out;
  &.expanded {
    background-color: $backgroundTertiary;
    gap: $sp-12;
    padding: $sp-16;
  }
}

.ctaContainer {
  display: flex;
  justify-content: center;
}

.showCtaWrapper {
  background-color: rgba(255,255,255, 70%);
  border-radius: 0 0 $radius-8 $radius-8;
  box-shadow: $shadow-1;
  color: $blue-50;
  cursor: pointer;
  padding: $sp-8;
  text-align: center;
  transform: translateY(-$sp-4);
  transition: transform .1s ease-out;
  width: 97%;
  &:hover {
    transform: translateY(0);
  }
}
