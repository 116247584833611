@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 50px;
}

.creation {
  width: 500px;
  max-width: 100%;
  @include breakpoint([xs]) {
    width: 100%;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  & > div {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.demo_tenant {
  margin-bottom: 20px;
  border-bottom: 1px solid $black-20;
  padding-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0px none transparent;
    padding-bottom: 0;
  }

  & > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0
    }
  }
}

.tenant_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > a {
    margin-left: 10px;
    font-size: $font-size-1;
  }
}

.reminder {
  @extend %btn-ghost;
  $paddingSize: 12px;
  padding-left: $paddingSize;
  padding-right: $paddingSize;
  span {
    margin-left: 8px;
    font-size: $font-size-1;
  }
}
