@use '~@moved/ui/src/balazs/common/all' as *;
@use '../../../common/styles/shared';

// Layout Wrapper
.content {
  flex: 1 1 auto;
  padding: 40px;
  position: relative;
  background-color: $black-10;
  min-height: 100%;
  @include breakpoint(xs) {
    padding: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

.section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 26px;
  @include white-box;
  &:last-child {
    margin-bottom: 0;
  }
}

// Header Section
.header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  h3 {
    font-size: $font-size-1;
    font-weight: $regular;
    margin-bottom: 4px;
  }

  .back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $link;
    cursor: pointer;
    svg {
      margin-right: 4px;
      --color-1: #{$link};
      --color-2: #{$link};
    }
    &:hover {
      color: $link-hover;
      --color-1: #{$link-hover};
      --color-2: #{$link-hover};
    }
  }
}

.subtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.clipboard {
  color: $black-90;
}

.id_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  color: $black-40;
  font-size: $font-size-1;

  .id_item {
    margin-top: 4px;
    padding-right: 12px;
    border-right: 1px solid $black-20;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
      border-right: 0 transparent none;
      padding-right: 0;
    }
    .label {
      white-space: nowrap;
      display: block;
      padding-bottom: 0px;
      font-size: $font-size-0;
    }
  }
}

.activities {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.actions {
  display: flex;
  margin-bottom: 12px;
  .action_item {
    margin-right: 10px;
    margin-top: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.oval {
  height: 48px;
  width: 48px;
  border-radius: 100px;
  background: linear-gradient(180deg, #C2C5C9 0%, #6F777F 100%);
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: $white;
    font-size: $font-size-3;
    cursor: default;
    font-weight: $med;
  }

  &.action {
    background-color: $black-10;
    border: 1px solid $black-10;
    background-image: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
    &:hover {
      background-color: $blue-10;
      transform: scale(1.15);
      border-color: $blue-20;
    }
  }
}
