@use '~@moved/ui/src/balazs/common/all' as *;

.form_wrapper {
  flex: 0 1 520px;
  @include breakpoint(xs) {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    padding-bottom: 16px;
  }
}

.view_toggle {
  position: absolute;
  top: 25px;
  right: 50px;
  color: $black-40;
  @include breakpoint(xs) {
    display: none;
  }
}

.form_toggle {
  @include breakpoint(xs) {
    width: 90px;
    height: 36px;
    background-color: $black-20;
    border-radius: 18px;
    color: $black-100;
    font-size: 13px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
  }
}
