@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  flex: 1 1 30%;
  margin-right: 32px;
  @include white-box;
  padding: 24px 32px;
  &:last-child {
    margin-right: 0;
  }
}

.spinner {
  margin: 10px 0;
}
