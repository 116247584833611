@use '~@moved/ui/src/sondheim/common' as *;

.content {
  max-width: 600px;
}

.spacer {
  flex: 1 1 100%;
}

.filter_chip {
  padding: 6px 12px;
  border: 1px solid $borderTertiary;
  border-radius: 999em;
}
