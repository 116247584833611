@use '../../common' as *;

.editor {
  min-width: 200px;
  :global(.tiptap) {
    outline: none;
  }
}

.list {
  padding: 0;
  margin: 0;
}

.item {
  width: 100%;
  padding: $sp-8 0;
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: $sp-8;
  & > label {
    flex: 0 0 auto;
    padding: 2px;
    &:has(input:checked) ~ div {
      text-decoration: line-through;
    }
    & > input {
      display: none;
    }
    // using this span as a style based checkbox
    input ~ span {
      display: block;
      height: $sp-20;
      width: $sp-20;
      border: 2px solid $iconSingleEnabled;
      position: relative;
      border-radius: $radius-4;
      cursor: pointer;
      &:hover {
        border-color: $iconSingleHover;
      }
      // psuedo content to create a style based checkmark
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        bottom: 4px;
        width: 7px;
        height: 11px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(40deg);
        display: none;
      }
    }
    // if input is checked, style the box and show the checkmark
    input:checked ~ span {
      background-color: $iconMultiSuccess;
      border-color: $iconMultiSuccess;
      &:hover {
        background-color: $green-60;
        border-color: $green-60;
      }
      &:after { display: block; }
    }
  }
  & > div {
    @include labelM;
    color: $contentPrimary;
    padding: $sp-2 0;
    flex: 1 1 auto;
    // implementation of placeholder text
    &:has(:global(.ProseMirror-trailingBreak)):before {
      content: 'Add an item...';
      position: absolute;
      color: $contentSecondary;
      cursor: text;
    }
  }
}
