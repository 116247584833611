@use '../../../common/all' as *;

%toggle-default,
%toggle-stacked,
%toggle-underline {
  display: none;
  & + label.toggle {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 24px;
    margin: 5px 0 6px;
    border-radius: 100px;
    background-color: $black-30;
    cursor: pointer;
    outline: none;
    vertical-align: top;
    transition: all 0.3s;
    user-select: none;
    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      width: 20px;
      background-color: $white;
      border-radius: 999em;
      -webkit-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }
    &.mini {
      width: 12px;
      &:before {
        margin-left: 8px;
      }
    }
  }
  &:hover + label.toggle {
    background-color: $black-40;
  }
  &:checked + label.toggle {
    background-color: $blue-50;
    &:before {
      left: calc(100% - 22px);
    }
  }
}


%toggle-row-default,
%toggle-row-underline {
  flex-direction: row;
  .input_label {
    flex: 0 0 auto;
    order: 0;
    line-height: 24px;
    height: 24px;
    color: $black-90;
    cursor: pointer;
    margin: 5px 0 6px;
    padding-left: 10px;
  }
  .label_icon {
    line-height: 24px;
    vertical-align: top;
  }
}

%toggle-row-stacked {
  flex-direction: row;
  .input_label {
    flex: 1 1 auto;
    line-height: 24px;
    height: 24px;
    color: $black-90;
    cursor: pointer;
    margin: 5px 0;
    .label_icon {
      line-height: 24px;
      vertical-align: top;
    }
  }
}
