@use '~@moved/ui/src/balazs/common/all' as *;
@use './variables';

/* ----------------------------------------
    Left side navigation
---------------------------------------- */

.navigation {
  // layout
  width: 270px;
  flex: 0 0 270px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  background: linear-gradient(180deg, #fff 0%, #f5f6f6 100%);
  box-shadow: -2px 0 16px 0 rgba(0,0,0,0.01);
}

.logo_section {
  padding: variables.$navPadding;
  padding-bottom: 0;
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

// NavigationMenu override
.menu > li a {
  padding: 20px variables.$navPadding;
}

.bottom_section {
  flex: 0 0 auto;
}

.profile_content {
  margin: 10px variables.$navPadding 0;
  padding-bottom: 24px;
  border-bottom: 1px solid $black-20;
}
