@use "sass:map";

$radius-values: 0, 4, 6, 8, 12, 16, 20;

$radii: ();
@each $radius in $radius-values {
  $radii: map.set($radii, #{$radius}, $radius * 1px);
}

@function radius($name) {
  @return map.get($radii, $name);
}

$radius-4: radius('4');
$radius-6: radius('6');
$radius-8: radius('8');
$radius-12: radius('12');
$radius-16: radius('16');
$radius-20: radius('20');
$radius-full: 999em;
