@use '~@moved/ui/src/sondheim/common' as *;

.wrapper {
  position: relative;
}

.notificationBadge {
  position: absolute;
  top: -$sp-8;
  right: -$sp-8;
  @include labelXS;
  background-color: $backgroundError;
  color: $contentInversePrimary;
  border-radius: $radius-full;
  line-height: 20px;
  height: 20px;
  width: 20px;
  text-align: center;
  user-select: none;
}

.popover {
  min-width: 330px;
}

.content {
  overflow-x: visible;
  overflow-y: auto;
  max-height: 580px;
  margin: 0 -20px;
}

.request {
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: $black-10;
  }
}

.request_wrapper {
  margin: 0 20px;
  padding: 16px 0;
  border-bottom: 1px solid $black-20;
}

.no_results {
  margin: 16px 20px 0;
  font-style: italic;
}
