@use '../../common/all' as *;

.oval {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.15);
  }
}
.icons {
  position: relative;
}
.icon {
  --color-1: #{$black-80};
  --color-2: #{$black-20};
}
.check {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  left: 0;
  --color-1: #{$green-40};
  .copied & {
    opacity: 1;
    transition: none 0s ease;
  }
}
