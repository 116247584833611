@keyframes rotateRoomBottomOut {
	from { }
	to { opacity: .3; transform: translateY(100%) rotateX(90deg); }
}

@keyframes rotateRoomBottomIn {
	from { opacity: .3; transform: translateY(-100%) rotateX(-90deg); }
}

.enter, .exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	visibility: hidden;
	overflow: hidden;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
}

.enterActive {
  visibility: visible;
	transform-origin: 50% 100%;
	animation: rotateRoomBottomIn .6s both ease;
}

.exitActive {
  visibility: visible;
	transform-origin: 50% 0%;
	animation: rotateRoomBottomOut .6s both ease;
  z-index: 2;
}
