@use '~@moved/ui/src/balazs/common/all' as *;

%field_columns {
  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 280px;
    flex-basis: 28%;
    .splash {
      min-width: 96px;
      width: 96px;
      height: 64px;
      margin: 0 12px 0 4px;
      border-radius: 4px;
      background-color: $black-20;
      overflow: hidden;
      background-size: auto 100%;
    }
  }
  .partner_name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 24%;
    .splash {
      min-width: 40px;
      width: 40px;
      height: 40px;
      margin: 0 14px;
      border-radius: 30px;
      background-color: $black-20;
      overflow: hidden;
      background-size: auto 100%;
    }
  }

  .address {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 28%;
  }

  .status {
    display: flex;
    justify-content: flex-start;
    flex-basis: 21%;
  }

  .moves {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 4%;
  }

  .arrow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 2%;
  }
}
