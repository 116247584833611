@use '../../../common/all' as *;

%checkbox-default,
%checkbox-stacked,
%checkbox-underline {
  display: none;
  & + label.checker {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    margin: 5px 0 6px;
    border-radius: 100px;
    cursor: pointer;
    outline: none;
    vertical-align: top;
    transition: background-color ease-in 0.3s, border-width ease-in 0.3s, transform ease-in 0.1s;
    user-select: none;
    background-color: rgba(255,255,255,0.4);
    border: 2px solid $black-30;
    .checker_check {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity ease-in 0.2s;
    }
  }
  &:hover + label.checker {
    transform: scale(1.15);
    border-color: $black-40;
  }
  &:checked + label.checker {
    background-color: $blue-50;
    border-width: 0;
    .checker_check {
      opacity: 1;
    }
  }
  & ~ .input_label {
    .flavor {
      display: block;
      font-size: $font-size-1;
      color: $black-40;
      padding-left: 44px;
    }
  }
}

%checkbox-row-default,
%checkbox-row-underline {
  flex-direction: row;
  .input_label {
    flex: 1 1 auto;
    order: 0;
    line-height: 24px;
    min-height: 24px;
    color: $black-90;
    cursor: pointer;
    margin: 5px 0 6px;
    padding-left: 10px;
    max-width: calc(100% - 34px)
  }
  .label_icon {
    line-height: 24px;
    vertical-align: top;
  }
}

%checkbox-row-stacked {
  flex-direction: row;
  .input_label {
    flex: 1 1 auto;
    line-height: 24px;
    min-height: 24px;
    color: $black-90;
    cursor: pointer;
    margin: 5px 0;
    .label_icon {
      line-height: 24px;
      vertical-align: top;
    }
  }
}
