@use '../../../common' as *;

.container {
  position: relative;
  min-width: 185px;
}

.dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 99;
  min-width: 100%;
}
