@use '~@moved/ui/src/sondheim/common' as *;

.content {
  flex: 1 1 auto;
  margin: 40px 80px 0;
  position: relative;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 80px;
}

.header {
  flex: 0 0 auto;
  margin-bottom: $sp-8;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $sp-12;
}

.back_btn {
  cursor: pointer;
  svg {
    --color-1: #{$black-70};
    --color-2: #{$black-70};
  }
  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }
}


.dataBar {
  flex: 0 0 auto;
  margin-bottom: $sp-12;
}

.section_title {
  margin-top: $sp-24;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.directionWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.response_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: $sp-12;
}

.pill {
  margin: 2px 0;
}
