@use '~@moved/ui/src/sondheim/common' as *;

.wrapper {
  position: relative;
  min-width: 290px;
}

.confirm {
  position: absolute;
  inset: 0 0 0 0;
  z-index: 200;
  background-color: $backgroundSecondary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
