@use '~@moved/ui/src/balazs/common/all' as *;

.unit {
  flex-basis: 11%;
}

.icon {
  flex-basis: 4%;
  padding-right: 10px;
}

.building_task_title {
  flex-basis: 34%;
}

.name {
  flex-basis: 22%;
  min-width: 150px;
  @include breakpoint([xs,sm]) {
    min-width: 0;
  }
}

.building_name {}

.submitted_at {}

.status {}

.lease_date {}
