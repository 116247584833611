@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 24px;
  line-height: 40px;
  text-align: center;
}

.content {
  margin-bottom: 32px;
  color: $black-40;
  font-size: $font-size-2;
  font-weight: $light;
  line-height: 20px;
  text-align: center;
}

.footer {
  text-align: center;
}

.btn_forgot {
  @extend %btn-primary;
  max-width: 340px;
  display: inline-flex;
}
