@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.wrapper {
  display: flex;
  height: 100%;
  background-color: $black-10;
  @include breakpoint(xs) {
    overflow: auto;
  }
}

.promo {
  position: relative;
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  min-width: 330px;
  max-width: 460px;
  padding: 52px 56px;
  background-size: cover;
  background-position: center center;
  color: white;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.00) 100%);
  }
  @include breakpoint(xs) {
    display: none;
  }
}

.content_scroller {
  flex: 1 1 auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  min-height: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include breakpoint(xs) {
    flex: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 16px 0;
  }
}

.mobile_header {
  display: none;
  @include breakpoint(xs) {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;
  }
}
