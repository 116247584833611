@use '~@moved/ui/src/balazs/common/all' as *;
@use '../../../common/styles/shared';

// Layout Wrapper
.wrapper {
  flex: 1 1 auto;
  padding: 40px;
  position: relative;
  background-color: $black-10;
  min-height: 100%;
  @include breakpoint(xs) {
    padding: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
  &.embed {
    margin: 0;
    max-width: none;
    padding: 30px;
  }
  &.iframe {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.back_link {
  display: inline-flex;
  margin: 0 0 26px;
  align-items: center;
  line-height: 18px;
  cursor: pointer;
  font-size: $font-size-2;
  font-weight: $med;
  &:hover > svg {
    transform: scale(1.15);
    background-color: $blue-10;
    border-color: $blue-20;
    transform: scale(1.15);
    margin-right: 15px;
  }
  & > svg {
    @include white-box;
    padding: 8px;
    box-sizing: content-box;
    border-radius: 999em;
    margin-right: 12px;
    border: 1px solid transparent;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, margin 0.2s ease-in-out;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  --color-1: #{$black-70};
  --color-2: #{$black-70};
  &:hover {
    transform: scale(1.3);
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title_top {
  width: 100%;
  .user_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $black-40;
    font-size: $font-size-2;
  }
  .user_name {
    margin-right: 8px;
  }
}

.title_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .title_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.order_date {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  h1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 12px;
  }
}

.summary {
  $spacing: 17px;
  margin: $spacing 0 26px;
  border-top: 2px solid $black-20;
  border-bottom: 2px solid $black-20;
  padding: $spacing 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
