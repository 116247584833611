@use '../../common' as *;
@use 'sass:math';

$transitionDuration: 200;
:export {
  transitionDuration: $transitionDuration;
}

.trigger {
  display: block;
}

.content {
  background: $backgroundSecondary;
  border-radius: $radius-12;
  padding: $sp-20;
  box-shadow: $shadow-4;
  justify-content: center;
  z-index: 1200;
  &:global(.enter) {
    opacity: 0;
  }
  &:global(.enter-active) {
    opacity: 1;
    transition: opacity #{$transitionDuration}ms ease-out;
  }
  &:global(.exit) {
    opacity: 1;
  }
  &:global(.exit-active) {
    opacity: 0;
    transition: opacity #{$transitionDuration}ms ease-out;
  }
  &[data-popper-interactive='false'] { pointer-events: none; }

  &.withArrow[data-popper-placement='top-end'],
  &.withArrow[data-popper-placement='left-end'] {
    border-bottom-right-radius: $radius-4;
  }
  &.withArrow[data-popper-placement='top-start'],
  &.withArrow[data-popper-placement='right-end'] {
    border-bottom-left-radius: $radius-4;
  }
  &.withArrow[data-popper-placement='bottom-start'],
  &.withArrow[data-popper-placement='right-start'] {
    border-top-left-radius: $radius-4;
  }
  &.withArrow[data-popper-placement='bottom-end'],
  &.withArrow[data-popper-placement='left-start'] {
    border-top-right-radius: $radius-4;
  }

}

.arrow {
  height: $sp-20;
  width: $sp-20;
  position: absolute;
  pointer-events: none;
  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin: auto;
  }

  [data-popper-placement*='top'] > & {
    bottom: -$sp-12;
    width: $sp-20;
    height: $sp-12;
    &::before {
      border-width: $sp-12 math.div($sp-20,2) 0 math.div($sp-20,2);
      border-color: $backgroundSecondary transparent transparent transparent;
    }
  }

  [data-popper-placement*='bottom'] > & {
    top: -$sp-12;
    width: $sp-20;
    height: $sp-12;
    &::before {
      border-width: 0 math.div($sp-20,2) $sp-12 math.div($sp-20,2);
      border-color: transparent transparent $backgroundSecondary transparent;
    }
  }

  [data-popper-placement*='right'] > & {
    left: -$sp-12;
    height: $sp-20;
    width: $sp-12;
    &::before {
      border-color: transparent $backgroundSecondary transparent transparent;
      border-width: math.div($sp-20,2) $sp-12 math.div($sp-20,2) 0;
    }
  }

  [data-popper-placement*='left'] > & {
    right: -$sp-12;
    height: $sp-20;
    width: $sp-12;
    &::before {
      border-color: transparent transparent transparent $backgroundSecondary;
      border-width: math.div($sp-20,2) 0 math.div($sp-20,2) $sp-12;
    }
  }

}

.title {
  @include headingS;
  margin-top: -$sp-20;
  margin-right: -$sp-20;
  margin-left: -$sp-20;
  padding: $sp-16 $sp-20;
  border-bottom: 1px solid $borderSecondary;
}
