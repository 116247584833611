@use '~@moved/ui/src/balazs/common/all' as *;

.direction_icon {
  display: inline-block;
  &.in {
    --color-1: #{$blue-50};
    --color-2: #{$black-40};
  }
  &.out {
    transform: scaleX(-1);
    --color-1: #{$red-50};
    --color-2: #{$black-40};
  }
}
