@use '../../common' as *;

.menu {
  display: flex;
  flex-direction: column;
  border-radius: $radius-12;
  padding: $sp-8 0;
  justify-content: center;
  min-width: 140px; // design spec value
}

.divider {
  height: 0;
  border-bottom: 1px solid $borderSecondary;
  margin: $sp-8 0;
}

.item {
  display: flex;
  gap: $sp-12;
  color: $contentPrimary;
  padding: $sp-8 $sp-40 $sp-8 $sp-20;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  &:hover {
    background: $backgroundPrimary;
  }
  &.disabled {
    opacity: 0.4;
    cursor: default;
    background: none;
  }
  // large variant and mobile use larger padding
  .large & { padding: $sp-12 $sp-40 $sp-12 $sp-20; }
  @include responsive(mobile) { padding: $sp-12 $sp-40 $sp-12 $sp-20; }
}

.itemLabel {
  @include labelM;
  // large variant and mobile use larger typography
  .large & { @include labelL; }
  @include responsive(mobile) { @include labelL; }
}
