@use '~@moved/ui/src/balazs/common/all' as *;

.placeholder {
  @include aspect-ratio(2,1);
  position: relative;
  background: $bg-light;
  border-radius: 16px;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
