@use 'variables' as *;

// ----------------------------------------
//  Typography Variables
// ----------------------------------------

$font-size-8: 64px !default;
$font-size-7: 32px !default;
$font-size-6: 26px !default;
$font-size-5: 24px !default;
$font-size-4: 18px !default;
$font-size-3: 16px !default;
$font-size-2: 14px !default;
$font-size-1: 12px !default;
$font-size-0: 10px !default;

/*  -~-~-~-~-   Font Weight   -~-~-~-~-  */
$light:       300 !default;
$regular:     400 !default;
$med:         500 !default;
$semibold:    600 !default;
$bold:        700 !default;

/*  -~-~-~-~-   Font Family   -~-~-~-~-  */
$base-sans-serif: 'circular', 'Roboto', "HelveticaNeue", "Helvetica Neue", "HelveticaNeueRoman", "HelveticaNeue-Roman", "Helvetica Neue Roman", "TeXGyreHerosRegular", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif; // default font
$icon-font: 'Material Icons';

/* ----------------------------------------
    Shorthand Placeholder Selectors
---------------------------------------- */
%main-body-text {
  font-family: $base-sans-serif;
  font-size: $font-size-2;
  line-height: 1.6;
  font-weight: $regular;
}

%container {
  margin: 0 auto;
  max-width: $container-max-width;
  padding: 0 $gutter;
}

%faux-link {
  color: $link;
	text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: color 0.3s;
  &:hover, &:active, &:focus {
    color: $link-hover;
  	text-decoration: none;
  	outline: 0;
  }
  &.secondary {
    color: $secondary;
    &:hover, &:active, &:focus {
      color: $secondary-hover;
    }
  }
}
