@use '../../../../common/all' as *;
@use 'filterSearch';

/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.results_section {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: auto;
  background-color: $white;
  padding: 16px 20px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.capsules {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 22px;
  min-width: 340px;
}

  .capsule {
    color: $black-100;
    background-color: $black-20;
    font-size: $font-size-2;
    border-radius: 16px;
    padding: 6px 6px 6px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    margin-right: 12px;
    margin-top: 8px;
    cursor: default;
    & > span {
      margin-right: 4px;
    }
    & > svg {
      cursor: pointer;
      --color-1: #{$black-100};
      --color-2: #{$black-100};
      &:hover {
        --color-1: #{$red-40};
        --color-2: #{$red-40};
      }
    }
  }
