@use '~@moved/ui/src/balazs/common/all' as *;

$boxPadding: 36px;

// Layout Wrapper
.box {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 16px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.box_active {
  .arrow {
    transform: rotate(90deg);
  }
  .box_top:hover {
    border-bottom-color: $blue-10;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
  --color-1: #{$black-40};
  --color-2: #{$black-40};
}

.box_top {
  padding: 20px $boxPadding 19px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f7fbff;
    border-bottom-color: #f7fbff;
  }
}
  .top_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .top_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h4 {
    font-size: 16px;
    font-weight: $regular;
    margin-left: 24px;
  }
}

.box_bottom {
  padding: 10px $boxPadding 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.actions {
  align-self: flex-end;
}
