@use '../../../../common/all' as *;

/*  ---- Editor Styles ----  */

.editor {
  background-color: $red-10;
  outline: 10px $red-10;
  border-radius: 10px;
  &::after {
    content: attr(data-watermark);
    text-transform: uppercase;
    font-size: $font-size-8;
    font-weight: $bold;
    opacity: (0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 200px;
    line-height: 200px;
    margin-left: -150px;
    margin-top: -100px;
    transform: rotate(-20deg);
    pointer-events: none;
  }

  a:not([class*='btn-']) {
    border-bottom-width: 0 !important;
    text-decoration: underline !important;
  }
}


/*  ---- Render Styles ----  */

.render {
  margin-bottom: 20px;

  a:not([class*='btn-']) {
    border-bottom-width: 0 !important;
    text-decoration: underline !important;
  }
}
