@use '../../common/all' as *;

/* ----------------------------------------
    Import 3rd Party Hamburger SASS
---------------------------------------- */
$hamburger-layer-color: $black-90 !default;
$hamburger-layer-width: 18px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 3px !default;
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-active-layer-color: $black-90 !default;

@import 'hamburgers/hamburgers';
