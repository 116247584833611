@use '~@moved/ui/src/balazs/common/all' as *;

.main {
  padding-bottom: 150px;
}

.task_title {
  margin: 30px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details_section {
  max-width: 612px;
}

.update_details_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
