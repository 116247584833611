@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 26px;
}

.actions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}
