@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper

.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & > * {
    flex-basis: 21%;
    padding: 0 10px;
    display: flex;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.arrow_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
}

.icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
  padding-right: 0;
}


.arrow {
  transition: transform 0.3s ease-in-out;
  --color-1: #{$black-40};
  --color-2: #{$black-40};
  .active & {
    transform: rotate(180deg);
  }
}

.pill {
  max-width: 120px;
}

.box_bottom {
  margin-top: 26px;
  border-top: 1px solid $black-20;
}

.attributes {
  cursor: default;
}

.notes {
  padding-top: 20px;
  padding-bottom: 6px;
}

.actions {
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}
