@use '../../common' as *;

.trigger {
  display: inline-block;
  vertical-align: top;
}

.tooltip {
  @include labelS;
  padding: $sp-4 $sp-8;
  color: $contentInversePrimary;
  background-color: $backgroundInversePrimary;
  border-radius: $radius-4;
  box-shadow: $shadow-1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  z-index: 1200;
}

.arrow {
  position: absolute;
  height: 12px;
  width: 12px;
  &::after, &::before {
    border-style: solid;
    content: '';
    display: block;
    margin: auto;
    position: absolute;
  }
}

[data-popper-placement*='bottom'] {
  .arrow {
    width: 12px;
    top: -6px;
    height: 6px;
  }
  .arrow::after {
    @include arrow(6px,$backgroundInversePrimary,'up');
    left: 0;
    top: 0;
  }
  .arrow::before {
    content: none;
  }
}

[data-popper-placement*='top'] {
  .arrow {
    width: 12px;
    bottom: -6px;
    height: 6px;
  }
  .arrow::after {
    @include arrow(6px,$backgroundInversePrimary,'down');
    left: 0;
    top: 0;
  }
  .arrow::before {
    content: none;
  }
}


[data-popper-placement*='right'] {
  .arrow {
    height: 12px;
    left: -6px;
    width: 6px;
  }
  .arrow::after {
    @include arrow(6px,$backgroundInversePrimary,'left');
    left: 0;
    top: 0;
  }
  .arrow::before {
    content: none;
  }
}

[data-popper-placement*='left'] {
  .arrow {
    height: 12px;
    width: 6px;
    right: -6px;
  }
  .arrow::after {
    @include arrow(6px,$backgroundInversePrimary,'right');
    left: 0;
    top: 0;
  }
  .arrow::before {
    content: none;
  }
}
