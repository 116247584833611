/*
  Note: the path of these files is must be absolute to support evaluation in many differnent apps
*/

@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-book.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-book.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-book.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-book-italic.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-book-italic.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-book-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-medium.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-medium.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-medium-italic.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-medium-italic.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-medium-italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-bold.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-bold.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-bold-italic.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-bold-italic.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-bold-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-black.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-black.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'circular';
  src: url('~@moved/ui/src/fonts/circularstd-black-italic.eot?') format('eot'),
       url('~@moved/ui/src/fonts/circularstd-black-italic.woff') format('woff'),
       url('~@moved/ui/src/fonts/circularstd-black-italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
