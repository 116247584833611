@use '~@moved/ui/src/balazs/common/all' as *;

.logo_preview {
  width: 700px;
  display: flex;
  & > * {
    flex: 1 1 50%;
  }
}

.welcome_image_preview {
  width: 350px;
  @include aspect-ratio(9,16);
}

.dashboard_image_preview {
  width: 700px;
  @include aspect-ratio(16,9);
  .aspect-ratio {
    background-size: cover;
    background-color: $black-90;
    background-position: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}
