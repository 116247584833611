@use '../../common/all' as *;

// override overflow to allow our tooltips to not get clipped (changed in latest version of the library)
:global(.react-horizontal-scrolling-menu--inner-wrapper) {
  .wrapper & {
    overflow-y: visible;
  }
}

$arrowSize: 24px;
.arrow_holder {
  width: $arrowSize;
  flex: 0 0 $arrowSize;
  align-self: center;
  &.left { margin-right: 20px; }
  &.right { margin-left: 20px; }
  @include breakpoint(xs) {
    display: none;
    cursor: default;
  }
}
.arrow {
  height: $arrowSize;
  line-height: $arrowSize;
  font-size: $arrowSize;
  cursor: pointer;
  color: $black-90;
  font-family: $icon-font;
  font-style: normal;
  background: $black-20;
  border-radius: 999em;
  transition: transform 0.1s ease-in, opacity 0.2s;
  &:hover {
    transform: scale(1.2);
  }
  &.left {
    padding: 5px 7px 5px 5px;
  }
  &.right {
    padding: 5px 5px 5px 7px;
  }

  .disabled & {
    cursor: default;
    opacity: 0;
    &:hover {
      transform: none;
    }
  }

}

.wrapper {
  margin: 0 -44px;
  @include breakpoint(xs) {
    margin: 0 -12px;
  }
}

.container {
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.item {
  flex: 1 0 auto;
}

.separator {
  flex: 0 0 16px;
}
