@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 26px;
}

.section {
  min-width: 350px;
  margin-bottom: 26px;
  &:last-child {
    margin-bottom: 0;
  }
}

.none {
  background-color: $bg-light;
  color: $black-40;
  padding: 16px;
  text-align: center;
  border-radius: 6px;
}

.actions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
