@use '~@moved/ui/src/balazs/common/all' as *;

.proxy_mode_holder {
  position: fixed;
  top: 70px;
  right: 0;
  overflow: hidden;
  z-index: 999999;
  max-width: 55px;
  transition: max-width 0.3s;
  &:hover {
    max-width: 140px;
    .exit {
      transition: transform 0.3s 0.3s;
      transform: translateY(0);
    }
  }
}

.proxy_mode {
  position: relative;
  color: #fff;
  height: 35px;
  width: 140px;
  background: rgba(166,0,0,0.8);
  border-bottom-left-radius: 18px;
  border-top-left-radius: 18px;
  padding: 5px 15px 5px 15px;
  line-height: 25px;
  vertical-align: top;
  font-size: 12px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  svg {
    height: 25px;
    width: 25px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
    fill: currentColor;
  }
  .exit {
    position: absolute;
    bottom: 0;
    right: 15px;
    font-size: 9px;
    line-height: 12px;
    transition: transform 0s 0.3s;
    transform: translateY(100%);
  }
}
