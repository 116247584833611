@use 'colors' as *;
@use 'variables' as *;
@use 'typography' as *;
@use 'mixins' as *;

/* ================================================== *\
    Buttons
\* ================================================== */

%btn-base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 348px;
  height: 48px;
  line-height: 38px;
  padding: 5px 44px;
  border-radius: 28px;

  font-style: normal;
  font-weight: $regular;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  border: none;

  box-sizing: border-box;
  user-select: none;

  transition:
    background-color 0.2s,
    border-color 0.2s,
    max-width 0.2s,
    color 0.2s,
    opacity 0.2s;


  &.ng-leave-active, &.ng-hide {
    transition: all 0s;
    display:none;
  }

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &:focus {
    outline:inherit;
  }

  &.loading, &.loading:hover, &.loading:focus {
    transition: all 0.2s 0.1s, color 0.1s !important;
    text-indent: -999em;
    max-width: 48px !important;
    min-width: 48px !important;
    padding: 0 !important;
    color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 24px;
    border-color: $primary;
    border-right-color: transparent !important;
    background: transparent !important;
    overflow: hidden;
    cursor: default;
    animation: spin 1.0s infinite 0.2s linear !important;
    :global {
      animation: spin 1.0s infinite 0.2s linear !important;
    }

    .icon {
      display: none;
    }
  }

  &.error {
    animation: shake 0.1s 3 linear;
    :global {
      animation: shake 0.1s 3 linear;
    }
  }

  &.mobile-mini {
    @include breakpoint(xs) {
      text-indent: -999em;
      padding: 0;
      width: 55px;
      height: 55px;
      background-size: auto 22px;
      background-position: center center;
    }
  }
  .modal fieldset & {
    width: 100%;
  }
  .icon {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
}

%btn-primary {
  @extend %btn-base;
  color: #fff;
  background-color: $primary;
  &:hover, &:focus {
    color: #fff;
    background-color: $primary-hover;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: #fff;
    background-color: $primary;
  }
}

%btn-secondary {
  @extend %btn-base;
  color: #fff;
  background-color: $secondary;
  &:hover, &:focus {
    color: #fff;
    background-color: $secondary-hover;
  }
  &.loading, &.loading:hover, &.loading:focus {
    border-color: $secondary;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: #fff;
    background-color: $secondary;
  }
}

%btn-orange {
  @extend %btn-base;
  color: $orange-80;
  background-color: $orange-20;
  &:hover, &:focus {
    color: $orange-80;
    background-color: $orange-30;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $orange-60;
    background-color: $orange-10;
  }
}

%btn-green {
  @extend %btn-base;
  color: $green-80;
  background-color: $green-20;
  &:hover, &:focus {
    color: $green-80;
    background-color: $green-30;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $green-60;
    background-color: $green-10;
  }
}

%btn-green-dark {
  @extend %btn-base;
  color: #fff;
  background-color: $green-40;
  &:hover, &:focus {
    color: #fff;
    background-color: $green-50;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: #fff;
    background-color: $green-40;
  }
}

%btn-gray {
  @extend %btn-base;
  color: $secondary;
  border: none 0 transparent;
  background-color: $black-20;
  &:hover, &:focus {
    color: $secondary;
    background-color: $black-30;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $secondary;
    background-color: $black-20;
  }
}

%btn-white {
  @extend %btn-base;
  color: $secondary;
  border: none 0 transparent;
  background-color: rgba(255,255,255,0.9);
  &:hover, &:focus {
    color: $secondary;
    background-color: $white;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $secondary;
    background-color: rgba(255,255,255,0.9);
  }
}

%btn-ghost {
  @extend %btn-base;
  color: $secondary;
  border: solid 1px $black-20;
  background-color: transparent;
  &:hover, &:focus {
    color: $secondary;
    background-color: rgba(0,0,0,0.04);
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $secondary;
    background-color: transparent;
  }
}

%btn-ghost-red {
  @extend %btn-base;
  color: $red-50;
  border: solid 1px $red-40;
  background-color: transparent;
  &:hover, &:focus {
    color: $red-60;
    background-color: $red-10;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $red-30;
    border: solid 1px $red-20;
    background-color: transparent;
  }
}

%btn-cancel {
  @extend %btn-base;
  color: $white;
  background-color: $accent-red-light;
  border-color: $accent-red-light;
  &:hover, &:focus {
    color: $white;
    background-color: $accent-red;
    border-color: $accent-red;
  }
  &.loading, &.loading:hover, &.loading:focus {
    border-color: $accent-red-light;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $white;
    background-color: $accent-red-light;
    border-color: $accent-red-light;
  }
}

%btn-red-light {
  @extend %btn-base;
  color: $red-80;
  background-color: $red-20;
  &:hover, &:focus {
    color: $black-100;
    background-color: $red-30;
  }
  &.loading, &.loading:hover, &.loading:focus {
    border-color: $red-20;
  }
  &[disabled]:hover, &[disabled]:focus {
    color: $black-70;
    background-color: $red-10;
  }
  svg {
    --color-1: #{$red-20};
    --color-2: #{$red-60};
  }
}

%btn--full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%; // still needed for loading animation
}

%btn--tight {
  text-transform: uppercase;
  font-size: $font-size-1;
  font-weight: $semibold;
  letter-spacing: 1px;
  height: 38px;
  line-height: 28px;
  display: flex;
}

%btn--small {
  font-size: $font-size-1;
  height: 36px;
  line-height: 25px;
  &.loading, &.loading:hover, &.loading:focus {
    max-width: 36px !important;
    min-width: 36px !important;
  }
}

%btn--large {
  height: 56px;
  line-height: 24px;
  &.loading, &.loading:hover, &.loading:focus {
    border-radius: 999em;
    max-width: 56px !important;
    min-width: 56px !important;
  }
}

%btn--shadow {
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.02), 0 5px 15px 0 rgba(0,0,0,0.02);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.04), 0 5px 15px 0 rgba(0,0,0,0.04);
  }
}
