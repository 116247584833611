@keyframes slideOut {
  0% { }
  25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}

@keyframes slideIn {
  0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); }
}

.enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.enterActive {
  animation: slideIn 1000ms both ease;
}

.exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.exitActive {
  animation: slideOut 1000ms both ease;
}
