@keyframes slideOut {
  0% { opacity: 1; transform: translateX(0); }
  70% { opacity: 0; transform: translateX(20%); }
  100% { opacity: 0; transform: translateX(20%); }
}

@keyframes slideIn {
  0% { opacity: 0; transform: translateX(-20%); }
  30% { opacity: 0; transform: translateX(-20%); }
  100% { opacity: 1; transform: translateX(0); }
}

.enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.enterActive {
  animation: slideIn 300ms ease-out;
}

.exit {}

.exitActive {
  animation: slideOut 300ms ease-in;
}
