@use '../../../../common' as *;

@mixin base {
  border: 1px solid transparent;
  background: $backgroundStateEnabled;
  box-shadow: $shadow-2;
  transition: background 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

@mixin small {
  @include base;
  padding: 19px;
  border-radius: 8px;
  min-width: 200px;
  .basicContent { gap: 16px; }
  .icon { margin: -2px 0; }
  .label { @include labelM; }
  .description { display:none; } // no descriptions in this size variant
  .checkmark { margin: -2px 0; }
  .extendedContent { display:none; } // no extendedContent in this size variant
}

@mixin large {
  @include base;
  padding: 27px;
  border-radius: 8px;
  gap: 20px;
  min-width: 400px;
  .basicContent { gap: 20px; }
  .icon { margin: -4px 0; }
  .label { @include headingS; }
  .description { @include labelS; }
}

@mixin square {
  @include base;
  padding: 20px;
  border-radius: 8px;
  min-width: 104px;
  position: relative;
  .basicContent {
    flex-direction: column;
    gap: 12px;
  }
  .label { @include labelM; }
  .description { display:none; } // no descriptions in this size variant
  .content { text-align: center; }
  .checkmark {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .extendedContent { display:none; } // no extendedContent in this size variant
}
