@use '../../../common/all' as *;
@use 'input.text' as *;

%select-default,
%select-stacked,
%select-underline {
  width: 100%;
}

%select-underline {
  & ~ .input_label {
    position: absolute;
    top: 12px;
    left: 0;
    padding: 4px 0;
    color: $black-40;
    transition: top 0.3s, font-size 0.3s, left 0.3s;
    background: transparent;
    border-radius: 0;
    pointer-events: none;
    font-size: $font-size-2;
  }
  @include active-state {
    & ~ .input_label {
      top: -12px;
      font-size: $font-size-1;
    }
  }
}


%select-row-default,
%select-row-underline,
%select-row-stacked {
  display: block;
}
