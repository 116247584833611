@use '../mixins/responsive' as *;

@mixin displayXL {

  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;

  @include responsive(mobile) {
    font-size: 52px;
    line-height: 56px;
  }

}

@mixin displayL {

  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;

  @include responsive(mobile) {
    font-size: 36px;
    line-height: 44px;
  }

}

@mixin displayM {

  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;

  @include responsive(mobile) {
    font-size: 32px;
    line-height: 40px;
  }

}

@mixin displayS {

  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;

  @include responsive(mobile) {
    font-size: 28px;
    line-height: 36px;
  }

}
