@use '../../../common' as *;
@use 'DatePickerVariables' as *;
@use 'DatePickerOverrides';

.container {
  width: 100%;
  padding: 22px 0 4px;
  display: block;
  position: relative;
}

.calendar {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: $white;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
}


.wrapper {
  width: 100%;

  &.hasFocus, &.hasValue {
    & ~ .label {
      @include labelS;
      top: 2px;
    }
  }

  &.hasFocus {
    border-color: $borderAccent;
    & ~ .label {
      color: $contentAccent;
    }
  }

  &.disabled {
    & ~ .label {
      color: $contentTertiary;
    }
  }

  &.readOnly {
    & ~ .label {
      color: $contentSecondary;
    }
  }

  &.hasError input {
    border-color: $borderError;
  }

}

.label {
  @include labelM;
  position: absolute;
  top: 22px;
  left: 0;
  transition: top 0.2s, font-size 0.2s;
}
