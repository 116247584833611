@use '../../../common' as *;

@mixin base {
  &.dark {
    color: $white;
    .icon {
      --color-1: #{$white};
      --color-2: #{$white};
    }
  }
}

@mixin success {
  @include base;
  background-color: $green-20;
  color: $green-60;
  .icon {
    --color-1: #{$iconMultiSuccess};
    --color-2: #{$iconMultiSuccess};
  }
  &.dark {
    background-color: $backgroundSuccess;
  }
}

@mixin info {
  @include base;
  background-color: $blue-20;
  color: $blue-60;
  .icon {
    --color-1: #{$iconMultiAccent};
    --color-2: #{$iconMultiAccent};
  }
  &.dark {
    background-color: $backgroundAccent;
  }
}

@mixin error {
  @include base;
  background-color: $red-20;
  color: $red-60;
  .icon {
    --color-1: #{$iconMultiError};
    --color-2: #{$iconMultiError};
  }
  &.dark {
    background-color: $backgroundError;
  }
}

@mixin warning {
  @include base;
  background-color: $orange-20;
  color: $orange-60;
  .icon {
    --color-1: #{$iconMultiInformational};
    --color-2: #{$iconMultiInformational};
  }
  &.dark {
    background-color: $orange-50;
  }
}

@mixin default {
  @include base;
  background-color: $black-20;
  color: $black-60;
  .icon {
    --color-1: #{$iconMultiPrimary};
    --color-2: #{$iconMultiPrimary};
  }
  &.dark {
    background-color: $backgroundInversePrimary;
  }
}
