@use '~@moved/ui/src/balazs/common/all' as *;

.pill {
  font-size: $font-size-0;
  line-height: 17px;
  height: 24px;
  padding: 3px 12px;
  border-radius: 12px;
  border: 1px solid $black-20;
  margin-right: 10px;
  &:last-child { margin-right: 0; }

  font-weight: $med;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;

  color: $black-90;
  background-color: $black-20;

  /* Order statuses */
  &.vendor_pending { // blue
    color: $blue-60;
    background-color: $blue-20;
    border-color: $blue-20;
  }
  &.booked { // green
    color: $green-60;
    background-color: $green-20;
    border-color: $green-20;
  }
  &.cancelled { // grey
    color: $black-90;
    background-color: $black-20;
    border-color: $black-20;
  }
  &.completed { // dark green
    color: $white;
    background-color: $green-50;
    border-color: $green-50;
  }

  /* Payment statuses */
  &.valid_payment_method { // no pill
    display: none;
  }
  &.no_payment_method { // red
    color: $red-60;
    background-color: $red-20;
    border-color: $red-20;
  }
  &.pre_authorization_failed { // red
    color: $red-60;
    background-color: $red-20;
    border-color: $red-20;
  }
  &.authorized { // green
    color: $green-60;
    background-color: $green-20;
    border-color: $green-20;
  }
  &.payment_issue { // red
    color: $red-60;
    background-color: $red-20;
    border-color: $red-20;
  }
  &.paid { // dark green
    color: $white;
    background-color: $green-50;
    border-color: $green-50;
  }
}
