@use '../../../common' as *;

.hiddenInput {
  display: none;
}

.chip {
  @include labelM;
  align-items: center;
  background-color: $backgroundPrimary;
  border: 1px solid $borderTertiary;
  border-radius: 999rem;
  color: $contentPrimary;
  cursor: pointer;
  display: inline-flex;
  gap: $sp-4;
  /** 5px not a token, but was specified in the Figma designs */
  padding: 5px $sp-12;
  transition: color .3s ease-out,
              background-color .3s ease-out,
              border-color .3s ease-out;
  /** prevents awkward innerText highlighting on double-click */
  user-select: none;
}

.chip:hover {
  border-color: $borderPrimary;
}

.hiddenInput:checked ~ .chip {
  background-color: $backgroundTertiary;
  border-color: $borderPrimary;
}

.hiddenInput:disabled ~ .chip {
  border-color: $borderSecondary;
  color: $contentSecondary;
  cursor: not-allowed;
}
