@use '../../common/all' as *;

/* ----------------------------------------
    PopOver styling
---------------------------------------- */
.trigger {
  display: inline-block;
}

.container {
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 10px 70px 2px rgba(139,148, 159, 0.3);
  display: flex;
  flex-direction: column;
  padding: 5px;
  transition: opacity 0.2s;
  z-index: 2147483647;
  border: 1px solid $black-20;
  &[data-popper-placement='top-end'],  &[data-popper-placement='left-end'] {
    border-bottom-right-radius: 2px;
  }
  &[data-popper-placement='top-start'], &[data-popper-placement='right-end'] {
    border-bottom-left-radius: 2px;
  }
  &[data-popper-placement='bottom-start'], &[data-popper-placement='right-start'] {
    border-top-left-radius: 2px;
  }
  &[data-popper-placement='bottom-end'], &[data-popper-placement='left-start'] {
    border-top-right-radius: 2px;
  }
}

.arrow {
  height: 18px;
  position: absolute;
  width: 18px;
  &::after, &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
}

[data-popper-placement*='bottom'] {
  .arrow {
    width: 18px;
    top: -9px;
    height: 9px;
  }
  .arrow::after {
    @include arrow(9px,$white,'up');
    left: 0;
    top: 0;
  }
  .arrow::before {
    @include arrow(9px,$black-20,'up');
    left: 0px;
    top: -1px;
  }
}

[data-popper-placement*='top'] {
  .arrow {
    width: 18px;
    bottom: -9px;
    height: 9px;
  }
  .arrow::after {
    @include arrow(9px,$white,'down');
    left: 0;
    top: 0;
  }
  .arrow::before {
    @include arrow(9px,$black-20,'down');
    left: 0px;
    top: 1px;
  }
}


[data-popper-placement*='right'] {
  .arrow {
    height: 18px;
    left: -9px;
    width: 9px;
  }
  .arrow::after {
    @include arrow(9px,$white,'left');
    left: 0;
    top: 0;
  }
  .arrow::before {
    @include arrow(9px,$black-20,'left');
    left: -1px;
    top: 0;
  }
}

[data-popper-placement*='left'] {
  .arrow {
    height: 18px;
    width: 9px;
    right: -9px;
  }
  .arrow::after {
    @include arrow(9px,$white,'right');
    left: 0;
    top: 0;
  }
  .arrow::before {
    @include arrow(9px,$black-20,'right');
    left: 1px;
    top: 0;
  }
}
