@use '~@moved/ui/src/balazs/common/all' as *;
@use '../../../common/styles/shared';

.orders {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
}

// OrderWrapper
.order {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 26px;
  @include white-box;
  &:last-child {
    margin-bottom: 0;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top {
    border-bottom: 1px solid $black-20;
    padding-bottom: 15px;
  }

  .bottom {
    padding-top: 15px;
  }
}

.title {
  h3 {
    font-size: $font-size-3;
    font-weight: $med;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .order_date {
      margin-right: 12px;
    }
  }
}

.view_btn {
  @extend %btn-gray;
  font-size: $font-size-2;
  padding: 6px 16px;
  white-space: nowrap;
  line-height: 20px;
  height: auto;

  svg {
    margin-right: 8px;
    --color-1: #{$black-90};
    --color-2: #{$black-90};
  }
}
