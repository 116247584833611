@use '../../common/all' as *;

$checkmark-timing: cubic-bezier(0.35, 0, 0.25, 1);
$checkmark-timing-fast: cubic-bezier(0.25, 0, 0.15, 0.5);
.checkmark {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  display: inline-block;
  stroke: #01BF49;
  stroke-width: 1;
  box-shadow: inset 0px 0px 0px #01BF49;
  .circle {
    stroke: transparent;
    stroke-width: 1;
    fill: none;
  }
  .check {
    stroke: transparent;
    transform-origin: 50% 50%;
  }
  &.checked {
    stroke-width: 1;
    stroke: #fff;
    .circle {
      stroke: #01BF49;
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      animation: checkmark-stroke .6s $checkmark-timing 0s forwards;
    }
    .check {
      stroke: #01BF49;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: checkmark-stroke .3s $checkmark-timing 0.6s forwards;
    }
  }
  &.fast {
    .circle {
      animation: checkmark-stroke .3s $checkmark-timing-fast 0s forwards;
    }
    .check {
      animation: checkmark-stroke .15s $checkmark-timing-fast 0.3s forwards;
    }
  }
}


@keyframes checkmark-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkmark-fill {
  100% {
    box-shadow: inset 0px 0px 0px 75px #01BF49;
  }
}
