@use '../../../../common/all' as *;
@use '../../../DynamicForm/styles/input.base' as *;

/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.search_bar {
  display: flex;
  position: relative;
  align-items: center;
}

@mixin active-state {
  &:focus, &:active, &.has_value {
    @content;
  }
  &:not(:empty), &:not(:placeholder-shown) {
    @content;
  }
}

.input_icon {
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: text;
  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.input_clear {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
    &:hover {
      --color-1: #{$red-30};
      --color-2: #{$red-30};
    }
  }
}

.search_input {
  @include base-input;
  height: 48px;
  text-overflow: ellipsis;
  border: 0px transparent none;
  font-size: $font-size-2;
  border: 1px solid transparent;
  border-bottom: 1px solid $black-40;
  padding: 15px 0 15px 30px;
  background-color: transparent;

  @include active-state {
    border-bottom-color: $black-90;
    & ~ .input_icon {
      svg {
        --color-1: #{$black-90};
        --color-2: #{$black-90};
      }
    }
  }
  &:focus, &:active {
    border-bottom-color: $blue-50;
    & ~ .input_icon {
      svg {
        --color-1: #{$blue-50};
        --color-2: #{$blue-50};
      }
    }
  }
}

.results_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  font-size: $font-size-1;
  line-height: 13px;
}

.results {
  padding: 0;
  margin: 12px 0 0;
  overflow-x: auto;
  overflow-y: visible;
  max-height: 200px;
}

.result {
  list-style-type: none;
  margin-bottom: 12px;
  min-height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }

  .link {
    visibility: hidden;
    margin-left: 5px;
    line-height: 24px;
  }

  &:hover .link {
    visibility: visible;
  }

  label {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    cursor: pointer;

    .on, .off {
      position: absolute;
      left: -2px;
    }
    .on {
      opacity: 0;
      z-index: 2;
      --color-1: #{$white};
      --color-2: #{$blue-50};
      .check_input:checked ~ & {
        opacity: 1;
      }
    }
    .off {
      z-index: 1;
      --color-1: #{$black-40};
      --color-2: #{$black-40};
    }

    &:hover {
      .on {
        --color-1: #{$white};
        --color-2: #{$blue-30};
      }
      .off {
        --color-1: #{$blue-50};
        --color-2: #{$blue-50};
      }
    }

    span {
      font-size: $font-size-2;
    }
  }

  .check_input {
    display: none;

    &:checked ~ label {
      .on {
        opacity: 1;
      }
    }
  }
}
