@use 'palette';
@use 'global';

/* Background colors */
$backgroundPrimary: global.$secondary;
$backgroundSecondary: palette.$white;
$backgroundTertiary: palette.$black-20;

$backgroundInversePrimary: global.$primary;
$backgroundInverseSecondary: palette.$black-80;

$backgroundStateEnabled: rgba(palette.$white, 85%);
$backgroundStateHover: palette.$white;
$backgroundStateActive: palette.$white;
$backgroundStateDisabled: rgba(palette.$white, 70%);

$backgroundOverlayDark: rgba(global.$primary, 40%);
$backgroundOverlayLight: rgba(global.$primary, 10%);

$backgroundAccent: global.$accent;
$backgroundError: global.$error;
$backgroundWarning: global.$warning;
$backgroundSuccess: global.$success;

$backgroundAccentLight: palette.$blue-10;
$backgroundErrorLight: palette.$red-10;
$backgroundWarningLight: palette.$yellow-10;
$backgroundSuccessLight: palette.$green-10;

/* Content colors */
$contentPrimary: global.$primary;
$contentSecondary: palette.$black-50;
$contentTertiary: palette.$black-30;

$contentInversePrimary: palette.$white;
$contentInverseSecondary: rgba(palette.$white, 60%);
$contentInverseTertiary: rgba(palette.$white, 30%);

$contentAccent: global.$accent;
$contentOnColor: palette.$white;

$contentError: global.$error;
$contentWarning: palette.$yellow-60;
$contentSuccess: global.$success;

/* Border colors */
$borderPrimary: global.$primary;
$borderSecondary: rgba(global.$primary, 10%);
$borderTertiary: palette.$black-30;

$borderAccent: global.$accent;
$borderError: global.$error;
$borderWarning: global.$warning;
$borderSuccess: global.$success;

$borderAccentLight: palette.$blue-30;
$borderErrorLight: palette.$red-30;
$borderWarningLight: palette.$yellow-30;
$borderSuccessLight: palette.$green-30;

/* CTA colors */
$ctaAccentEnabled: global.$accent;
$ctaAccentHover: palette.$blue-60;
$ctaAccentActive: palette.$blue-70;
$ctaAccentDisabled: palette.$blue-30;

$ctaSecondaryEnabled: palette.$black-20;
$ctaSecondaryHover: palette.$black-30;
$ctaSecondaryActive: palette.$black-40;
$ctaSecondaryDisabled: palette.$black-20;

$ctaErrorEnabled: global.$error;
$ctaErrorHover: palette.$red-60;
$ctaErrorActive: palette.$red-70;
$ctaErrorDisabled: palette.$red-30;

/* Icon colors */
$iconSingleEnabled: palette.$black-40;
$iconSingleHover: palette.$black-50;
$iconSingleActive: global.$accent;
$iconSingleDisabled: palette.$black-30;

$iconOnButton: global.$primary;
$iconOnColor: palette.$white;

$iconMultiPrimary: global.$primary;
$iconMultiAccent: global.$accent;
$iconMultiError: global.$error;
$iconMultiWarning: palette.$yellow-60;
$iconMultiSuccess: global.$success;
$iconMultiInformational: palette.$orange-60;
$iconMultiWhite: palette.$white;

$iconMultiPrimaryLight: palette.$black-30;
$iconMultiAccentLight: palette.$blue-20;
$iconMultiErrorLight: palette.$red-20;
$iconMultiWarningLight: palette.$yellow-30;
$iconMultiSuccessLight: palette.$green-20;
$iconMultiInformationalLight: palette.$orange-20;
