@use '../../common' as *;

.link {
  text-decoration: none;
  cursor: pointer;
  // overrides needed for inside content editor which has slightly too heavy styles for all a tags
  border-bottom: none !important;
  padding-bottom: 0 !important;
}

.button {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
