.dots {
  & > span {
    display: inline-block;
    font-size: 16px;
    line-height: 12px;
    margin-right: 1px;
    animation: wave 1.3s linear infinite;
    &:nth-child(2) {
      animation-delay: -1.1s;
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
}
