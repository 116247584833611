@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.week_row {
  display: flex;
  height: 100%;
  border: 1px solid $black-30;
  border-radius: 6px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.week_holder {
  height: (1440px + $calendarHeaderHeight);
  display: flex;
  width: 100%;
}
