@use 'styles/sizes';
@use 'styles/colors';

.smallPrimary {
  @include sizes.small;
  @include colors.primary;
}

.smallSecondary {
  @include sizes.small;
  @include colors.secondary;
}

.smallTertiary {
  @include sizes.small;
  @include colors.tertiary;
}

.defaultPrimary {
  @include sizes.default;
  @include colors.primary;
}

.defaultSecondary {
  @include sizes.default;
  @include colors.secondary;
}

.defaultTertiary {
  @include sizes.default;
  @include colors.tertiary;
}

.largePrimary {
  @include sizes.large;
  @include colors.primary;
}

.largeSecondary {
  @include sizes.large;
  @include colors.secondary;
}

.largeTertiary {
  @include sizes.large;
  @include colors.tertiary;
}

.customColor {
  @include colors.custom;
}
