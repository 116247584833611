@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.block {
  z-index: 1;
  overflow: hidden;
  cursor: default;
  transition: background-color 0.1s ease-in-out 0s;
  position: absolute;
  top: 119px;
  height: 121px;
  left: 0;
  right: 0;
  z-index: 35;

  border: 1px solid $normal;
  border-left-color: $normalVertical;
  border-right-color: $normalVertical;
  background-color: $black-10;

  .today & {
    border-left-color: $today;
    border-right-color: $today;
  }
  .day_column:hover & {
    border-left-color: $hover;
    border-right-color: $hover;
  }
  &:hover {
    border-color: $cellHover;
    background-color: $red-10;
    z-index: 30;
  }
  &:active, .day_column:hover &:active {
    background-color: $cellActiveBG;
    transition: background-color 0s ease-in-out 0s;
  }
  @include responsive(mobile) {
    min-height: 60px;
    border-left-width: 0;
    border-right-width: 0;
    margin-left: 0;
    margin-right: 0;
    &.slot_capacity {
      padding-bottom: 40px;
    }
  }
}

.block_content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 8px 0;
  z-index: 1;
  background-color: $red-20;
  background-image: url('../images/diagonal.png');
  background-position: center;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: 20px;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.block_line {
  height: 4px;
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: $red-50;
}

.block_appointments {
  margin-bottom: 4px;
}

.block_appointment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-basis: 100%;
  width: 100%;
  padding: 2px 8px;

  line-height: 130%;
  .slot_tenant {
    @include textTruncate;
  }
  &:hover {
    background-color: rgba(0,0,0,0.08);
    .cancel_block { display: block; }
  }

  .cancel_block {
    cursor: pointer;
    display: none;
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }

    &:hover {
      svg {
        --color-1: #{$red-50};
        --color-2: #{$red-50};
      }
    }
  }
}

.block_small {
  .block_content { padding: 0; }
  .block_line { display: none; }
  .block_appointment { line-height: 120%; }
}
