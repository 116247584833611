@use "~@moved/ui/src/balazs/common/all" as *;

.content_top,
.content_bottom {
  width: 100%;
}

.content_bottom {
  margin-top: 60px;
  padding-bottom: 24px;
}

.subtitle {
  font-size: $font-size-2;
  font-weight: $regular;
}

.toggle_bar,
.mandatory_bar {
  border: 1px solid $black-30;
  border-radius: 8px;
  padding: 12px 20px;
  flex-direction: row-reverse !important;
  margin-bottom: 12px !important;
  justify-content: space-between;
}

.toggle_label {
  display: flex;
  margin-left: -10px;
  font-size: $font-size-1;
  align-items: center;
  justify-content: flex-start;

  & > svg {
    margin-right: 10px;
  }
}

.form, .mandatory {
  max-width: 808px;
}

.mandatory {
  margin-top: 40px;
}

.mandatory_fields {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
}

.mandatory_bar {
  width: calc(50% - 8px);
  align-self: flex-start;

  .label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0 6px;

    & > svg {
      margin-right: 10px;
    }

    span {
      font-size: $font-size-1;
    }
  }
}

.buttons {
  display: flex;
}
.back_btn {
  margin-right: 12px;
  cursor: pointer;
  svg {
    --color-1: #{$black-70};
    --color-2: #{$black-70};
  }
  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }
}

.save {
  min-width: 340px;
  margin-right: 20px;
}
