@use '../../common/all' as *;

.wrapper {}

.bucket {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.bucket_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$itemMargin: 16px;

.items {
  margin-top: -$itemMargin;
  min-height: 67px;
  border-radius: 8px;
  &.dragover {
    background-color: $black-20;
  }
}

// Item Wrapper
.item {
  margin-top: $itemMargin;
}
.box {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);

  font-size: $font-size-2;
  overflow: hidden;
  transition: background-color 0.08s ease-in-out,
              box-shadow 0.08s ease-in-out,
              padding 0.08s ease-in-out,
              margin 0.08s ease-in-out;

  &.dragging {
    opacity: (0.6);
  }

  &:hover {
    margin: -3px -6px;
    padding: 3px 6px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.08);
  }
}

.icon_area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 28px;
}

.icon {
  cursor: grab;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }
}

.addtional {
  display: flex;
}

.content {
  padding: 16px 24px;
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  .content_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .content_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h4 {
    font-weight: $regular;
    margin-left: 24px;
  }
}
