@use '~@moved/ui/src/sondheim/common' as *;

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 808px;
}

.title {
  margin-top: $sp-0;
  margin-bottom: $sp-32;
  font-size: 16px;
}

.link_existing_btn {
  --color-1: #{$white};
  --color-2: #{$white};
}

.new_question_btn {
  --color-1: #{$black-20};
  --color-2: #{$black-100};
}
