@use '../../../../common/all' as *;

/*  ---- Editor Styles ----  */


/*  ---- Render Styles ----  */

.render {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;

  &.background {
    background-color: $black-10;
    border-radius: 10px;
    padding: 20px;
    align-items: center;

    .caption {
      color: $black-100;
    }
  }

  img {
    display: block;
    max-width: 100%;
    border-radius: 6px;
  }

  .stretched {
    width: 100%
  }

  .border {
    border: 3px solid $blue-40;
    border-radius: 6px;
    overflow: hidden;
  }

  .caption {
    margin-top: 20px;
    font-style: italic;
  }

}
