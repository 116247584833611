@use 'common/all' as *;

/* ----------------------------------------
   Body Styles
---------------------------------------- */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  @extend %main-body-text;
  background-color: #fff;
  zoom: 1;
  overflow-x: hidden;
  &.no-scroll {
    overflow: hidden !important;
    @include breakpoint(xs) {
      position: fixed;
      width: 100%;
    }
  }
}
