@keyframes rotateRoomRightOut {
  from { }
  to { opacity: .3; transform: translateX(100%) rotateY(-90deg); }
}

@keyframes rotateRoomRightIn {
  from { opacity: .3; transform: translateX(-100%) rotateY(90deg); }
}

.enter, .exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.enterActive {
  visibility: visible;
  transform-origin: 100% 50%;
  animation: rotateRoomRightIn .6s both ease;
}

.exitActive {
  visibility: visible;
  transform-origin: 0% 50%;
  animation: rotateRoomRightOut .6s both ease;
  z-index: 2;
}
