@use '../../../../../sondheim/common' as *;

/*  ---- General Styles ----  */
.url_wrapper {
  margin-top: 10px;
  background-color: $black-60;
  border-radius: 3px;
  padding: 6px 10px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%;
  .label {
    font-weight: bold;
    color: $white;
    user-select: none;
    cursor: default;
    margin-right: 10px;
  }
  .url_input {
    background: transparent;
    border: none;
    color: $white;
    width: 100%;
    outline: none;
    &::placeholder {
      color: $white;
      opacity: 0.5;
    }
  }
  &::before {
    @include arrow(8px,$black-60,'up');
    top: -8px;
    left: 20px;
    position: absolute;
  }
}




/*  ---- Editor Styles ----  */
.editor {
  position: relative;
  display: flex;
  padding: 0;
  min-height: 44px;
  .button {
    cursor: pointer;

  }
}

/*  ---- Render Styles ----  */
.render {
  display: flex;
  .custom {
    &:hover {
      box-shadow: inset 0 0 0 999em rgba(0,0,0,0.1);
    }
  }
}


/* ---- Settings Styles ---- */
.settingsRow {
  padding: 0 0 $sp-12 0;
  align-items: center;
}
.colorToggle {
  flex: 1 1 50px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &.primary {
    background-color: $ctaAccentEnabled;
    color: $iconOnColor;
    &:hover { background-color: $ctaAccentHover; }
  }
  &.secondary {
    background-color: $ctaSecondaryEnabled;
    &:hover { background-color: $ctaSecondaryHover; }
  }
  &.tertiary {
    background-color: $white;
    border: 1px solid $borderTertiary;
    &:hover { border-color: $black-40; }
  }
  &.custom {
    &:hover { box-shadow: inset 0 0 0 999em rgba(0,0,0,0.1); }
  }
  & > svg {
    --color-1: currentColor;
  }
}

.colorInput {
  flex: 1 1 100px;
}
