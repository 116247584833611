@use '~@moved/ui/src/balazs/common/all' as *;

.content {
  flex: 1 1 auto;
  margin: 40px 80px;
  position: relative;
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

.header {
  margin-bottom: 32px;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  h1 {
    font-size: 26px;
  }
}

.back_btn {
  margin-right: 12px;
  cursor: pointer;
  svg {
    --color-1: #{$black-70};
    --color-2: #{$black-70};
  }
  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }
}

.subtitle {
  p {
    margin: 0;
  }
}
