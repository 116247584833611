@use '../../common' as *;

/* Table styles */
.table {
  display: table;
  border-collapse: separate;
  border-spacing: 0 $sp-12;
  margin: -$sp-12 0;
  width: 100%;
}

/* TableHeader styles */
.headerRow {
  display: table-row;
  align-items: center;
}
.headerColumn {
  display: table-cell;
  padding: $sp-8 $sp-24 0;
  white-space: nowrap;
  &.actions {
    width: 0;
  }
}

/* TableRow styles */
.row {
  display: table-row;
  align-items: center;
  box-shadow: $shadow-1;
  // border-radius here ONLY affects the rounding of the box shadow, hence delegating the
  // background down to the cells and duplicating the border radius as needed.
  border-radius: $radius-8;
  max-width: 100%;
  &.clickable {
    cursor:pointer;
  }
  &.disabled {
    cursor: default;
    .column {
      background: $backgroundStateDisabled;
      color: $contentTertiary;
    }
  }
  &:hover:not(.disabled) {
    box-shadow: $shadow-2;
  }
  &:hover:not(.disabled) .column {
    background: $backgroundStateHover;
    &.actions {
      svg {
        --color-1: #{$iconSingleHover};
        --color-2: #{$iconSingleHover};
      }
    }
  }
  &.disabled .column.actions svg {
    --color-1: #{$iconSingleDisabled};
    --color-2: #{$iconSingleDisabled};
  }
}

.column {
  display: table-cell;
  padding: $sp-16 $sp-24;
  background: $backgroundStateEnabled;
  height: 56px;
  line-height: 24px;
  vertical-align: middle;
  &:first-child {
    border-top-left-radius: $radius-8;
    border-bottom-left-radius: $radius-8;
  }
  &:last-child {
    border-top-right-radius: $radius-8;
    border-bottom-right-radius: $radius-8;
  }
  &.actions {
    width: 0;
    svg {
      --color-1: #{$iconSingleEnabled};
      --color-2: #{$iconSingleEnabled};
    }
  }
}
