@use '../../../../common/all' as *;
@use '../../../DynamicForm/styles/input.base' as *;

.calendarWrapper {

  :global {
    .rdrCalendarWrapper{
      color: $black-100;
      font-size: 14px;
    }

    .rdrMonths {
      margin-top: -44px;
    }

    .rdrDateDisplayWrapper{
      background-color: rgb(239, 242, 247);
    }

    .rdrDateDisplay{
      margin: 0.833em;
    }

    .rdrDateDisplayItem{
      border-radius: 4px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
      border: 1px solid transparent;
      input{
        cursor: pointer;
        height: 2.5em;
        line-height: 2.5em;
        border: 0px;
        background: transparent;
        width: 100%;
        color: #849095;
      }
    }

    .rdrDateDisplayItemActive{
      border-color: currentColor;
    }

    .rdrDateDisplayItemActive{
      input{
        color: #7d888d
      }
    }

    .rdrMonthAndYearWrapper {
      align-items: center;
      height: 60px;
      padding-top: 10px;
      z-index: 20;
    }

    .rdrMonthAndYearPickers{
      display: none;
    }

    .rdrMonthPicker, .rdrYearPicker{
      margin: 0 5px
    }

    .rdrNextPrevButton {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 0.833em;
      padding: 0;
      border: 0;
      border-radius: 5px;
      background: transparent;
      &:hover{
        background: transparent;
      }
      i {
        display: block;
        width: 0;
        height: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        margin: auto;
        transform: translate(-3px, 0px);
      }
    }

    .rdrPprevButton {
      i {
        height: 24px;
        width: 24px;
        border: 0px none transparent;
        background-image: url('data:image/svg+xml,\
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">\
          <g fill="none" fill-rule="evenodd">\
            <path d="M0 0h24v24H0z"></path>\
            <path d="M13.293 5.293a1 1 0 0 1 1.497 1.32l-.083.094L9.415 12l5.292 5.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083-6-6a1 1 0 0 1-.083-1.32l.083-.094z" fill="rgb(145,151,157)"></path>\
          </g>\
        </svg>');
      }
    }

    .rdrNextButton {
      i {
        height: 24px;
        width: 24px;
        border: 0px none transparent;
        background-image: url('data:image/svg+xml,\
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">\
          <g fill="none" fill-rule="evenodd">\
            <path d="M0 0h24v24H0z"></path>\
            <path d="M10.707 5.293a1 1 0 0 0-1.497 1.32l.083.094L14.585 12l-5.292 5.293a1 1 0 0 0-.083 1.32l.083.094a1 1 0 0 0 1.32.083l.094-.083 6-6a1 1 0 0 0 .083-1.32l-.083-.094z" fill="rgb(145,151,157)"></path>\
          </g>\
        </svg>');
      }
    }


    .rdrWeekDays {
      padding: 0 0.833em;
    }

    .rdrMonth{
      padding: 0 0.833em 1.666em 0.833em;
      .rdrWeekDays {
        padding: 0;
      }
    }

    .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
      display: none;
    }

    .rdrWeekDay {
      font-weight: 400;
      line-height: 2.667em;
      color: rgb(132, 144, 149);
    }

    .rdrDay {
      background: transparent;
      user-select: none;
      border: 0;
      padding: 0;
      line-height: 44px;
      height: 44px;
      text-align: center;
      color: $black-100;
      &:focus {
        outline: 0;
      }
    }

    .rdrDayNumber {
      outline: 0;
      font-weight: 300;
      font-size: 14px;
      position: absolute;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }

    .rdrDayToday .rdrDayNumber span{
      font-weight: 500;
      &:after{
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 18px;
        height: 2px;
        border-radius: 2px;
        background: #3d91ff;
      }
    }

    .rdrDayToday:not(.rdrDayPassive) {
      .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected{
        & ~ .rdrDayNumber span:after{
          background: $white;
        }
      }
    }

    .rdrDay:not(.rdrDayPassive){
      .rdrStartEdge, .rdrSelected{
          & ~ .rdrDayNumber{
            span{
              color: $white;
            }
          }
      }
    }

    .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
      background: currentColor;
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
    }

    .rdrSelected{
      left: 2px;
      right: 2px;
    }

    .rdrInRange{
      background-color: $blue-20;
    }

    .rdrStartEdge{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      left: 2px;
    }

    .rdrEndEdge{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      right: 2px;
    }

    .rdrSelected{
      border-radius: 8px;
    }

    .rdrDayStartOfMonth {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .rdrDayStartOfWeek{
      .rdrInRange, .rdrEndEdge{
        left: 2px;
      }
    }

    .rdrDayEndOfMonth {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .rdrStartEdge {
      background-color: $blue-50;
      & ~ .rdrDayNumber{
        span {
          color: $white;
        }
      }
    }

    .rdrDayEndOfWeek{
      .rdrInRange {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        right: 2px;
      }
    }

    .rdrEndEdge {
      background-color: $black-20;
      & ~ .rdrDayNumber{
        span {
          color: $black-100;
        }
      }
    }

    .rdrDayStartOfMonth {
      .rdrDayInPreview, .rdrDayEndPreview{
        border-left-width: 2px;
        left: 0px;
      }
    }

    .rdrStartEdge.rdrEndEdge {
      background-color: $blue-50;
    }

     .rdrDayStartOfWeek {
      .rdrDayInPreview, .rdrDayEndPreview{
        border-left-width: 2px;
        left: 0px;
      }
    }

    .rdrDayEndOfMonth {
      .rdrDayInPreview, .rdrDayStartPreview {
        border-right-width: 2px;
         right: 0px;
       }
    }

    .rdrDayEndOfWeek {
      .rdrDayInPreview, .rdrDayStartPreview{
       border-right-width: 2px;
       right: 0px;
     }
    }


    .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
      background-color: $blue-50;
      background-color: rgba($blue-50, 0.4);
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      pointer-events: none;
      border: 0px solid $white;
      outline: 2px solid $white;
      z-index: 1;

      & ~ .rdrDayNumber span {
        color: #fff;
      }
    }


    .rdrDayStartPreview{
      border-top-width: 2px;
      border-left-width: 2px;
      border-bottom-width: 2px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      left: 0px;
    }

    .rdrDayInPreview{
      border-top-width: 2px;
      border-bottom-width: 2px;
    }

    .rdrDayEndPreview{
      border-top-width: 2px;
      border-right-width: 2px;
      border-bottom-width: 2px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      right: 2px;
      right: 0px;
    }

    .rdrDefinedRangesWrapper{
      font-size: 12px;
      width: 226px;
      border-right: solid 1px #eff2f7;
      background: #fff;
      .rdrStaticRangeSelected{
        color: currentColor;
        font-weight: 600;
      }
    }

    .rdrStaticRange{
      border: 0;
      cursor: pointer;
      display: block;
      outline: 0;
      border-bottom: 1px solid #eff2f7;
      padding: 0;
      background: #fff;
      &:hover, &:focus{
        .rdrStaticRangeLabel{
          background: #eff2f7;
        }
      }
    }

    .rdrStaticRangeLabel{
      display: block;
      outline: 0;
      line-height: 18px;
      padding: 10px 20px;
      text-align: left;
    }

    .rdrInputRanges{
      padding: 10px 0;
    }

    .rdrInputRange{
      align-items: center;
      padding: 5px 20px;
    }

    .rdrInputRangeInput{
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px rgb(222, 231, 235);
      margin-right: 10px;
      color: rgb(108, 118, 122);
      &:focus, &:hover{
        border-color: rgb(180, 191, 196);
        outline: 0;
        color: #333;
      }
    }

    .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
      content: '';
      border: 1px solid currentColor;
      border-radius: 1px;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0px;
      right: 0px;
      background: transparent;
    }

    .rdrDayPassive{
      pointer-events: none;
      .rdrDayNumber span{
        color: #d5dce0;
      }
      .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
        display: none;
      }
    }

    .rdrDayDisabled {
      background-color: rgb(248, 248, 248);
      .rdrDayNumber span{
        color: #aeb9bf;
      }
      .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
        filter: grayscale(100%) opacity(60%);
      }
    }

    .rdrMonthName{
      text-align: center;
      font-weight: 400;
      color: $black-100;
      font-size: 17px;
      padding: 10px;
    }
  }
}
