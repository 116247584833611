@use '../../../common' as *;

.container {
  position: relative;
}

.input[readonly] { // always readonly and needs to override
  border-bottom: 1px solid $borderTertiary;
  padding-right: 36px;

  &.leadingIcon {
    padding-left: 36px;
    & ~ .label {
      left: 36px;
    }
    & ~ .icon {
      left: 0;
    }
  }

  &.trailingIcon {
    padding-right: 72px;
    & ~ .label {
      padding-right: 72px;
    }
    & ~ .icon {
      right: 36px;
    }
  }

  &:focus {
    border-color: $borderAccent;
    & ~ .label {
      color: $contentAccent;
    }
    & ~ .icon {
      --color-1: #{$iconMultiAccent};
      --color-2: #{$iconMultiAccent};
    }
  }

  &.hasError {
    border-color: $borderError;
  }

  &:disabled {
    border-color: $borderTertiary;
  }

  &.readOnly {
    border-color: transparent;
    & ~ .label {
      color: $contentSecondary;
    }
    & ~ .icon {
      --color-1: #{$iconSingleEnabled};
      --color-2: #{$iconSingleEnabled};
    }
  }

}

.arrow {
  position: absolute;
  top: 20px;
  right: 0;
  cursor: pointer;
  &.disabled {
    cursor: default;
    --color-1: #{$iconSingleDisabled};
    --color-2: #{$iconSingleDisabled};
  }
  &.readOnly {
    display: none;
  }
  &.open {
    --color-1: #{$iconMultiAccent};
    --color-2: #{$iconMultiAccent};
  }
}

.dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 99;
  min-width: 100%;
}
