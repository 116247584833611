@use '../../common/all' as *;

.pill {
  border-radius: 999em;
  padding: 4px 10px;
  font-size: $font-size-0;
  line-height: $font-size-0;
  font-weight: $med;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid transparent;

  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;

  &.red {
    background-color: $red-20;
    color: $red-80;
  }
  &.brown {
    background-color: $brown-20;
    color: $brown-80;
  }
  &.green {
    background-color: $green-20;
    color: $green-80;
  }
  &.orange {
    background-color: $orange-20;
    color: $orange-80;
  }
  &.blue {
    background-color: $blue-20;
    color: $blue-80;
  }
  &.white {
    background-color: $white;
    color: $black-100;
    border: 1px solid $black-30;
  }
  &.gray {
    background-color: $black-20;
    color: $black-80;
  }
}
