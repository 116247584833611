@use '../common' as *;

.dividerPrimary {
  border-top: 1px solid $borderPrimary;
  width: 100%;
}
.dividerSecondary {
  border-top: 1px solid $borderSecondary;
  width: 100%;
}
.dividerTertiary {
  border-top: 1px solid $borderTertiary;
  width: 100%;
}
