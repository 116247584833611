@use '../../common/all' as *;

.sort_table {
  width: 100%;
  border-spacing: 0;
  // pad first & last column
  th:first-child, td:first-child {
    padding-left: 20px;
  }
  th:last-child, td:last-child {
    padding-right: 20px;
  }
  // Column headings
  th {
    border-bottom: solid 1px $border-color;
  }
  .column_title {
    transition: all 0.3s;
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    color: $black-40;
    font-size: $font-size-0;
    font-weight: 500;
    text-align: left;
    cursor: default;
    &.sortable {
      color: $black-90;
      opacity: 0.7;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      .sort_name {
        border-bottom: 1px dashed $black-30;
        box-sizing: content-box;
        padding-bottom: 3px;
        margin-bottom: -4px;
      }
    }
    .sort_name {
      flex: 0 0 auto;
      text-transform: uppercase;
      letter-spacing: 1px;
      height: 16px;
      line-height: 18px;
    }
    .sort_arrow {
      transition: all 0.3s;
      flex: 0 0 auto;
      display: block;
      color: $black-90;
      height: 16px;
      width: 16px;
      margin-left: 4px;
    }
    &.active {
      opacity: 1;
    }
  }

  // Body Rows
  tbody tr:hover {
    background: $bg-light;
    &.row_click {
      cursor: pointer;
    }
  }
  // All Cells
  td {
    position: relative;
    height: 56px;
    @include text-truncate;
    overflow: visible;
    font-size: 14px;
    border-bottom: solid 1px $border-color;
  }
}
