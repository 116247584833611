@use '~@moved/ui/src/sondheim/common' as *;

.content_top, .content_bottom {
  width: 100%;
}

.content_bottom {
  margin-top: $sp-64;
  padding-bottom: $sp-24;
}

.buttons {
  display: flex;
  gap: $sp-16;
}
