@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.slot {
  z-index: 1;
  overflow: hidden;
  cursor: default;
  transition: background-color 0.1s ease-in-out 0s;
  position: absolute;
  top: 119px;
  height: 121px;
  left: 0;
  right: 1px;
  z-index: 20;

  border: 1px solid $normal;
  border-left-color: $normalVertical;
  border-right-color: $normalVertical;
  background-color: $black-10;
  margin: 0 -1px 0 0;
  &:last-child {
    margin-bottom: 15px;
  }

  &:hover {
    border-color: $cellHover;
    background-color: $cellHoverBG;
    z-index: 30;
  }
  &:active {
    background-color: $cellActiveBG;
    transition: background-color 0s ease-in-out 0s;
  }
}


.slot_content {
  position: absolute;
  overflow: hidden;
  padding: 8px 0;
  z-index: 1;
  background-color: $black-20;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  transition: background-color ease-in-out 0.25s;

  .slot_unavailable & {
    background-image: url('../images/diagonal.png');
    background-position: center;
    background-repeat: repeat;
    background-size: 20px;
    background-color: $brown-20;
  }
}

.slot_actions {
  visibility: hidden;
  .slot_content:hover & {
    visibility: visible;
  }
}

.slot_line {
  height: 4px;
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.slot_actions {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.slot_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0 8px;
  span {
    @include labelS;
    color: $black-60;
  }
}

.slot_top, .slot_appointments {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.slot_appointments, .slot_requests {
  margin-bottom: 4px;
}


.slot_adds {
  padding: 0 8px;
  width: 100%;
}

.slot_add {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
  user-select: none;
  .slot_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 20px;
    width: 100%;
    background-color: rgba(0,0,0,0.08);
    &:hover {
      background-color: rgba(0,0,0,0.16);
    }
  }
}


.slot_with_apps {
  z-index: 29;
  .slot_line { background-color: $black-70; }
  .slot_actions { visibility: visible; }
  .slot_content { background-color: $black-20; }
  &.slot_full {
    .slot_line { background-color: $blue-50; }
    .slot_content { background-color: $blue-20; }
  }
}

.slot_small {
  .slot_content { padding: 0; }
  .slot_line { display: none; }
  .slot_adds { padding: 0; }
  .slot_appointment, .slot_request { line-height: 120%; }
}
