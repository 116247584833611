@use '~@moved/ui/src/balazs/common/all' as *;

.section {
  $spacing: 26px;
  margin-bottom: $spacing;
  border-bottom: 2px solid $black-20;
  padding-bottom: $spacing;
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0 none transparent;
    padding-bottom: $spacing*2;
  }
}

.title_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.columns {
  display: flex;
  justify-content: space-between;

  .column {
    flex: 1 1 49%;
    display: flex;
    flex-direction: column;
  }
}

.rows {
  display: flex;
  flex-direction: column;
}
