@use '~@moved/ui/src/balazs/common/all' as *;

.progress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-0;
}

.minibar {
  width: 124px;
  display: flex;
  & > span {
    height: 8px;
    background-color: $black-20;
    margin: 0 1px;
    flex: 1 1 auto;
    &.completed {
      background-color: $blue-50;
    }
    &:first-child {
      margin-left: 0;
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      margin-right: 0;
      border-radius: 0 4px 4px 0;
    }
  }
}

.number {
  margin-left: 8px;
  color: $black-40;
  font-size: $font-size-0;
  letter-spacing: 1px;
  font-weight: $med;
}

.progress_complete {
  & > span {
    margin: 0;
  }

  .number {
    color: $blue-50;
  }
}

.shadow {
  .number {
    font-weight: $regular;
  }

  .minibar > span {
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.1);
    &.completed {
      background-color: $blue-50;
    }
  }

  .progress_complete .minibar > span.completed {
    background-color: $green-40;
  }
}
