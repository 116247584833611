@use '../common/all' as *;

/* ----------------------------------------
    Buttons
---------------------------------------- */
.btn-primary {
  @extend %btn-primary;
}

.btn-secondary {
  @extend %btn-secondary;
}

.btn-orange {
  @extend %btn-orange;
}

.btn-green {
  @extend %btn-green;
}

.btn-green-dark {
  @extend %btn-green-dark;
}

.btn-gray {
  @extend %btn-gray;
}

.btn-white {
  @extend %btn-white;
}

.btn-ghost {
  @extend %btn-ghost;
}

.btn-ghost-red {
  @extend %btn-ghost-red;
}

.btn-red-light {
  @extend %btn-red-light;
}

.btn-cancel {
  @extend %btn-cancel;
}

.btn--full {
  @extend %btn--full;
}

.btn--tight {
  @extend %btn--tight;
}

.btn--small {
  @extend %btn--small;
}

.btn--large {
  @extend %btn--large;
}

.btn--shadow {
  @extend %btn--shadow;
}

/* ----------------------------------------
    Spinner Element
---------------------------------------- */
.spinner {
  display: block;
  margin: 0 auto;
  animation: spin 1.33s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}


/* ----------------------------------------
    Links
---------------------------------------- */
a, .faux-link {
  @extend %faux-link;
}

.disable-link {
  pointer-events: none;
  opacity: 0.5;
}

/* ----------------------------------------
    Horizontal rules
---------------------------------------- */
hr {
  border: 1px solid $black-20;
  margin: 30px 0;
}

/* ----------------------------------------
    Containers
---------------------------------------- */
//Centered Max Width Container for Content
.container {
  @extend %container;
}

//Box Shadowed Rounded Edged coi-card
.card-container {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 0 6px 34px 0 rgba(0,0,0,0.09);
  padding: 30px 40px;
  @include breakpoint(xs) {
    border-radius: 0;
  }
}

/* ----------------------------------------
    CheckList Items (not form elements)
---------------------------------------- */

.content-checklist {
  .checklist-item {
    display: block;
    padding-left: 45px;
    margin-top: 20px;
    position: relative;
    color: $text-color;
    font-size: $font-size-3;
    &::before {
      display: block;
      background-color: transparent;
      border: 2px solid $text-color;
      height: 20px;
      width: 20px;
      content: "";
      position: absolute;
      left: 0;
      border-radius: 100px;
    }
  }

  .checklist-item-complete {
    text-decoration: line-through;
    &::before {
      display: block;
      border: 0 none transparent;
      background-color: $blue-50;
      color: $white;
      content: "check";
      font-family: $icon-font;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
  }
}
