@use '~@moved/ui/src/balazs/common/all' as *;

.form {
  max-width: 808px;
}

.content_top, .content_bottom {
  width: 100%;
}

.content_bottom {
  margin-top: 60px;
  padding-bottom: 24px;
}

.buttons {
  display: flex;
}

.save {
  min-width: 340px;
  margin-right: 20px;
}
