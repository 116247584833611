@use '../common' as *;

/* Border sizes */
$widths: 0, 1, 2;
@each $width in $widths {
  .borderWidth-#{$width} { border-width: $width * 1px; border-style: solid; }
  .borderTopWidth-#{$width} { border-top-width: $width * 1px; border-style: solid; }
  .borderRightWidth-#{$width} { border-right-width: $width * 1px; border-style: solid; }
  .borderBottomWidth-#{$width} { border-bottom-width: $width * 1px; border-style: solid; }
  .borderLeftWidth-#{$width} { border-left-width: $width * 1px; border-style: solid; }
}

/* Border radii */
.radius-full { border-radius: 999em; }
@each $name, $radius in $radii {

  .radius-#{$name} { border-radius: $radius;}

  .radiusTop-#{$name} { border-top-left-radius: $radius; border-top-right-radius: $radius; }
  .radiusRight-#{$name} {border-top-right-radius: $radius; border-bottom-right-radius: $radius; }
  .radiusBottom-#{$name} { border-bottom-left-radius: $radius; border-bottom-right-radius: $radius; }
  .radiusLeft-#{$name} { border-top-left-radius: $radius; border-bottom-left-radius: $radius; }

  .radiusTopLeft-#{$name} { border-top-left-radius: $radius; }
  .radiusTopRight-#{$name} { border-top-right-radius: $radius; }
  .radiusBottomLeft-#{$name} { border-bottom-left-radius: $radius; }
  .radiusBottomRight-#{$name} { border-bottom-right-radius: $radius; }

}
