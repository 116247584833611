@use 'colors' as *;

// ----------------------------------------
//  Mapped Color Variables
// ----------------------------------------

$white:            $neutral-0;

/* -~-~-~- Button/Link Colors -~-~-~- */
$primary:          $blue-50; // main button
$primary-hover:    darken($primary, 10%);

$secondary:        $black-90; // secondary button
$secondary-hover:  lighten($secondary, 10%);

$link:             $blue-50; // link colors
$link-hover:       darken($link, 10%);

/* -~-~-~- Content Colors -~-~-~- */
$bg-color:         rgba(255, 255, 255, 0.9);
$bg-light:         rgba(0, 0, 0, 0.03);
$bg-light-opaque:  $black-10;
$bg-medium:        rgba(0, 0, 0, 0.09);
$text-color:       $black-40;
$border-color:     $black-20;

/* -~-~-~- Accent Colors -~-~-~- */
$accent-red:       #de0000;
$accent-red-light: #F90000;
$accent-orange:    #e1503c;
$accent-blue:      #177EE6;

/* ----------------------------------------
    Social Media Colors
---------------------------------------- */
$facebook:         #3c5a96;
$facebook-hover:   #334c80;
$twitter:          #59adeb;
$twitter-hover:    #509bd3;
$google:           #fff;
$google-hover:     rgba(0, 0, 0, 0.03);
$instagram:        #125688;
$instagram-hover:  darken($instagram, 5%);
$stripe:           #6772e5;
$affirm:           #00c8e5;

// ----------------------------------------
//  General Height Variables
// ----------------------------------------
$headerHeight: 100px;
$mobileHeaderHeight: 70px;

// ----------------------------------------
//  General Width Variables
// ----------------------------------------

$container-max-width: 1180px;
$gutter:              20px;


// ----------------------------------------
//  Screen Size Variables
// ----------------------------------------

// Small screen / tablet
$screen-sm-min:       768px !default;

// Medium screen / desktop
$screen-md-min:       962px !default;

// Large screen / wide desktop
$screen-lg-min:       1200px !default;

// Xlarge screens
$screen-xlg-min:      1400px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:       ($screen-sm-min - 1) !default;
$screen-sm-max:       ($screen-md-min - 1) !default;
$screen-md-max:       ($screen-lg-min - 1) !default;
$screen-lg-max:       ($screen-xlg-min - 1) !default;

// Height Breakpoint
$screen-height-md:    1050px !default;
