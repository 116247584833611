@keyframes moveFromRight {
  from { transform: translateX(100%); }
}

@keyframes scaleDown {
  to { opacity: 0; transform: scale(.8); }
}

.enter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.enterActive {
  animation: moveFromRight 600ms ease both;
  z-index: 2;
}

.exit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.exitActive {
  animation: scaleDown 600ms ease both;
  z-index: 1;
}
