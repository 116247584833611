@use '../../../common/all' as *;
@use 'input.integer' as *;

%percentage-default,
%percentage-stacked,
%percentage-underline {
  & ~ .percent_sign {
    color: $black-30;
    display: block;
    position: absolute;
    font-size: $font-size-2;
    height: 14px;
    line-height: 14px;
    width: 14px;
    z-index: 2;
    right: 50%;
    top: 43px;
    margin-right: -30px;
    cursor: text;
    user-select: none;
  }
}
/*
*  I don't know why these need !optional
*  But it throws errors without it, and it works just fine with it, so...
*/
%percentage-default {
  @extend %integer-default !optional;
}
%percentage-stacked {
  @extend %integer-stacked !optional;
  & ~ .percent_sign {
    top: 40px;
  }
}
%percentage-underline {
  @extend %integer-underline !optional;
  & ~ .percent_sign {
    top: 17px;
  }
}
