@use '../../common/all' as *;

/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
}

.pills {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pill {
  font-size: $font-size-2;
  border: 1px solid $black-30;
  border-radius: 26px;
  transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
  margin-right: 8px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }

  .pill_inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 14px;
    border: 2px solid transparent;
    border-radius: 26px;
    min-height: 100%;
    margin: -1px;
    transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
    & > span {
      line-height: 16px;
      white-space: nowrap;
    }
  }

  .type_label {
    padding-right: 7px;
    border-right: 1px solid $black-90;
    margin-right: 7px;
  }

  .type_value {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    border-color: $black-50;
  }

  :global(.filterSearchActive) & {
    border-color: $black-60;
  }

  &.pill_active {
    background-color: $black-20;
    border-color: $black-60;
    .pill_inner {
      border-color: $black-60;
      color: $black-90;
    }
  }
}

.clear_icon {
  margin-left: 10px;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --color-1: #{$black-90};
    --color-2: #{$black-90};
    &:hover {
      --color-1: #{$red-40};
      --color-2: #{$red-40};
    }
  }
}

.divider {
  height: 28px;
  width: 1px;
  background-color: $black-30;
  margin: 0 16px 0 8px;
  &:last-child {
    margin-right: 0;
  }
}

.clear_all {
  margin-left: 10px;
  .clear_link {
    @extend %faux-link;
    white-space: nowrap;
  }
}
