@use '../common/all' as *;
/**
 * @helper Flex Box
 */

@mixin flex($breakpoint: false) {
  $breakpointPrefix: '';

  @if $breakpoint {
    $breakpointPrefix: "-#{$breakpoint}"
  }
  .flex#{$breakpointPrefix} { display: flex; flex-wrap: wrap }
  .inline-flex#{$breakpointPrefix} { display: inline-flex; }

  .flex-auto#{$breakpointPrefix} {
    flex: 1 1 auto;
    min-width: 0; /* 1 */
    min-height: 0; /* 1 */
  }

  .flex-none#{$breakpointPrefix} { flex: none; }

  .flex-column#{$breakpointPrefix}  { flex-direction: column; }
  .flex-row#{$breakpointPrefix}     { flex-direction: row; }
  .flex-wrap#{$breakpointPrefix}    { flex-wrap: wrap; }
  .flex-nowrap#{$breakpointPrefix}    { flex-wrap: nowrap; }
  .flex-grow#{$breakpointPrefix}    { flex-grow: 1 }
  .flex-grow#{$breakpointPrefix}-n  { flex-grow: 0 }
  .flex-shrink#{$breakpointPrefix}    { flex-shrink: 1 }
  .flex-shrink#{$breakpointPrefix}-n  { flex-shrink: 0 }

  .items-start#{$breakpointPrefix}    { align-items: flex-start; }
  .items-end#{$breakpointPrefix}      { align-items: flex-end; }
  .items-center#{$breakpointPrefix}   { align-items: center; }
  .items-baseline#{$breakpointPrefix} { align-items: baseline; }
  .items-stretch#{$breakpointPrefix}  { align-items: stretch; }

  .self-start#{$breakpointPrefix}    { align-self: flex-start; }
  .self-end#{$breakpointPrefix}      { align-self: flex-end; }
  .self-center#{$breakpointPrefix}   { align-self: center; }
  .self-baseline#{$breakpointPrefix} { align-self: baseline; }
  .self-stretch#{$breakpointPrefix}  { align-self: stretch; }

  .justify-start#{$breakpointPrefix}   { justify-content: flex-start; }
  .justify-end#{$breakpointPrefix}     { justify-content: flex-end; }
  .justify-center#{$breakpointPrefix}  { justify-content: center; }
  .justify-between#{$breakpointPrefix} { justify-content: space-between; }
  .justify-around#{$breakpointPrefix}  { justify-content: space-around; }

  .content-start#{$breakpointPrefix}   { align-content: flex-start; }
  .content-end#{$breakpointPrefix}     { align-content: flex-end; }
  .content-center#{$breakpointPrefix}  { align-content: center; }
  .content-between#{$breakpointPrefix} { align-content: space-between; }
  .content-around#{$breakpointPrefix}  { align-content: space-around; }
  .content-stretch#{$breakpointPrefix} { align-content: stretch; }

  .order-0#{$breakpointPrefix} { order: 0; }
  .order-1#{$breakpointPrefix} { order: 1; }
  .order-2#{$breakpointPrefix} { order: 2; }
  .order-3#{$breakpointPrefix} { order: 3; }
  .order-4#{$breakpointPrefix} { order: 4; }
  .order-5#{$breakpointPrefix} { order: 5; }
  .order-6#{$breakpointPrefix} { order: 6; }
  .order-7#{$breakpointPrefix} { order: 7; }
  .order-8#{$breakpointPrefix} { order: 8; }
  .order-last#{$breakpointPrefix} { order: 99999; }
}

@include flex();

/**
 * Flex Item
 */
.flex-item {
  padding: 0.5rem;
  align-items: stretch;
}

@media screen and (max-width: $screen-sm-max) {
  @include flex("xs");
}
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include flex("sm");
}
@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include flex("md");
}
@media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include flex("lg");
}
@media screen and (min-width: $screen-xlg-min) {
  @include flex("xlg");
}
