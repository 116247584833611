@use '../../common/all' as *;

/*  ---- Shared Styles ----  */

%content-base {

  a:not([class*='btn-']) {
    color: $black-100;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid $black-40;
    transition: border 0.2s, color 0.2s;
    &:hover, &:focus {
      color: $black-100;
      border-color: $black-100;
    }
  }

  :global {

    .ce-block {
      padding-bottom: 16px;
      &:last-child {
        padding-bottom: 0;
      }
    }

    // Quote
    .ce-quote {
      color: $black-50;
      font-size: $font-size-3;
      font-style: italic;
      font-weight: $light;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 32px;
      padding: 0;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
      &:focus {
        outline: none;
      }
    }

    // Delimiter
    .ce-delimiter {
      &:before {
        content: none;
      }
      border: 1px solid $black-20;
      margin: 30px 0;
      height: 0;
      padding: 0;
    }

    .cdx-attaches__download-button {
      border-bottom-width: 0;
    }

    // Inline Code
    .inline-code {
      color: $black-100;
      font-family: "Roboto Mono", monospace;
      letter-spacing: 0;
      padding: 2px 5px;
      border: 1px solid $black-30;
      background: $bg-light;
      border-radius: 4px;
    }
  }
}

/*  ---- Editor Styles ----  */

.editor {
  @extend %content-base;

  [contenteditable="true"] {
    outline: none;
  }

  [contenteditable="true"][data-placeholder]:empty::before {
    content: attr(data-placeholder);
    color: $black-40;
    opacity: 1.0;
  }

  :global {
    .ce-toolbox {
      background-color: $bg-color;
    }
    .ce-toolbar--opened {
      z-index: 999;
    }

    .ce-popover {
      --max-height: none; // settings popover has it's own partial scrolling if too large... remove that limitation
      --padding: 16px 20px;
      --width: 260px;
      --color-text-icon-active: inherit;
    }
    .ce-popover-item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .cdx-search-field {
      display:none; // disable the settings popover search filter
    }

    .ce-block--selected .ce-block__content {
      background: transparent;
    }
  }

}


/*  ---- Render Styles ----  */

.renderer {
  @extend %content-base;
}
