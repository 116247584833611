@use '../../common' as *;
@use 'sass:math';

// Variables
// ==================================================
$icon-line-border-radius  : 99em;
$icon-color               : $iconMultiPrimary;
$icon-hover-color         : $iconMultiAccent;
$icon-active-color        : $iconMultiAccent;

.icon {
  &.size-16 {
    --padding     : 2px;
    --line-height : 1px;
    --line-spacing: 2px;
  }
  &.size-18 {
    --padding     : 2px;
    --line-height : 2px;
    --line-spacing: 2px;
  }
  &.size-22 {
    --padding     : 2px;
    --line-height : 2px;
    --line-spacing: 3px;
  }
  &.size-28 {
    --padding     : 2px;
    --line-height : 2px;
    --line-spacing: 4px;
  }
  &.size-32 {
    --padding     : 3px;
    --line-height : 2px;
    --line-spacing: 5px;
  }
  &.size-36 {
    --padding     : 4px;
    --line-height : 2px;
    --line-spacing: 5px;
  }
  &.size-48 {
    --padding     : 4px;
    --line-height : 3px;
    --line-spacing: 7px;
  }
  &.size-54 {
    --padding     : 4px;
    --line-height : 4px;
    --line-spacing: 10px;
  }
}

// Base styles
// ==================================================
.icon {
  padding: var(--padding);
  display: block;
  cursor: pointer;
  position: relative;
  transition: color 0.1s linear;

  color: $icon-color;
  background-color: transparent;
  overflow: visible;

  &:hover {
    color: $icon-hover-color;
  }

  &.active {
    color: $icon-active-color;
  }
}

.line {
  display: block;
  background-color: currentColor;
  width: calc(100% - var(--padding) * 2);
  height: var(--line-height);
  border-radius: $icon-line-border-radius;
  position: absolute;

  // middle line
  &:not(:first-child):not(:last-child) {
    top: calc(50% - var(--line-height) * 0.5);
  }
  // top line
  &:first-child {
    top: calc(50% - var(--line-spacing) - var(--line-height) * 1.5);
  }
  // bottom line
  &:last-child {
    bottom: calc(50% - var(--line-spacing) - var(--line-height) * 1.5);
  }

}

/*
  * Spin
  */
.icon {
  .line {
    &:not(:first-child):not(:last-child) {
      transition: opacity 0.1s 0.25s ease-in;
    }

    &:first-child {
      transition: top 0.1s 0.25s ease-in,
                  transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:last-child {
      transition: bottom 0.1s 0.25s ease-in,
                  transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.active {
    .line {
      &:not(:first-child):not(:last-child) {
        opacity: 0;
        transition: opacity 0.1s 0.12s ease-out;
      }

      &:first-child {
        opacity: 1;
        top: calc(50% - var(--line-height) * 0.5);
        transform: rotate(225deg);
        transition: top 0.15s ease-in,
                    transform 0.22s 0.18s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:last-child {
        opacity: 1;
        bottom: calc(50% - var(--line-height) * 0.5);
        transform: rotate(135deg);
        transition: bottom 0.15s ease-in,
                    transform 0.22s 0.18s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
