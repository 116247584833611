@use '../../common/all' as *;

.card_list {
  display: flex;
  background-color: $bg-light-opaque;
  flex-wrap: wrap;
  padding: 15px;
  @include breakpoint(xs) {
    flex-direction: column;
    padding: 15px 0;
    width: 100%;
  }
}
.card {
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 24px 20px;
  margin: 10px;
  @include breakpoint(xs) {
    width: 100%;
    margin: 0 0 10px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.card_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 5px 0;
  font-size: $font-size-3;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
}
.card_field {
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
