@use '../../../../common/all' as *;

/*  ---- Editor Styles ----  */


/*  ---- Render Styles ----  */

.render {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4px;

  .button {
    @extend %btn-gray;
    @extend %btn--full;
    font-size: $font-size-2;
    border:  0px transparent none !important;
    transition: background-color 0.2s, border-color 0.2s, max-width 0.2s, color 0.2s, opacity 0.2s;
    .icon {
      margin-left: 10px;
    }
  }

  .type_icon {
    margin-right: 10px;
  }

}
