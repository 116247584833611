@use '../../../common/all' as *;
@use 'input.all' as *;

.error_msg {
  color: $red-40;
  margin-top: 5px;
  display: block;
  font-size: $font-size-1;
}

.input_label {
  order: -1;
  margin-bottom: 10px;
  color: $black-90;
  vertical-align: top;
  font-size: $font-size-1;
  user-select: none;
  .label_icon {
    font-size: $font-size-2;
    line-height: 16px;
    margin-right: 5px;
    vertical-align: top;
  }
}

.input_wrapper {
  width: 100%;
}

/* TEXT INPUTS */
@include text-inputs { @extend %text-stacked; }

/* CHECKBOX */
.checkbox { @extend %checkbox-stacked; }
.input_row.type_checkbox { @extend %checkbox-row-stacked; }

/* TOGGLE */
.toggle_checkbox { @extend %toggle-stacked; }
.input_row.type_toggle { @extend %toggle-row-stacked; }

/* SLIDETOGGLE */
.slide_container { @extend %slide-toggle-stacked; }

/* SELECT */
.select_container { @extend %select-stacked; }

/* UPLOADER */
.file_upload { @extend %uploader-stacked; }

/* CURRENCY */
input[type="text"].currency { @extend %currency-stacked; }

/* PASSWORD */
input[type="password"].password { @extend %password-stacked; }

/* INTEGER */
input[type="text"].integer { @extend %integer-stacked; }

/* DIFFERENCE */
input[type="text"].difference { @extend %integer-stacked; }

/* PERCENTAGE */
input[type="text"].percentage { @extend %percentage-stacked; }

/* PILLS */
.pill_field { @extend %pills-stacked; }

/* TYPE AHEAD */
input[type="text"].type_ahead { @extend %type-ahead-stacked; }

/* ITEMLIST */
.item_list { @extend %item-list-stacked; }

/* TEXTAREA */
textarea { @extend %textarea-stacked; }

/* DATEPICKER */
.date_picker { @extend %date-picker-stacked; }

/* EDITOR */
.editor_label{ @extend %editor-label; }
.editor_container { @extend %editor-stacked; }
.editor_preview { @extend %editor-preview; }
