@use '../../../common/all' as *;
@use 'input.text' as *;

%currency-default,
%currency-stacked,
%currency-underline {
  & + .currency_dollar {
    color: $black-40;
    display: block;
    position: absolute;
    font-size: $font-size-2;
    height: 14px;
    line-height: 14px;
    width: 14px;
    z-index: 2;
    left: 15px;
    top: 17px;
    cursor: text;
    user-select: none;
    transition: color 0.3s;
  }
  &.has_error + .currency_dollar {
    color: $red-40;
  }
}

%currency-default {
  padding-left: 30px;
  & ~ .input_label {
    left: 23px;
  }
  @include active-state {
    & ~ .input_label {
      left: 8px;
    }
  }
  & + .currency_dollar {
    color: $black-40;
    display: block;
    position: absolute;
    font-size: $font-size-2;
    height: 14px;
    line-height: 14px;
    width: 14px;
    z-index: 2;
    left: 15px;
    top: 17px;
    cursor: text;
    transition: color 0.3s;
  }
  &.has_error + .currency_dollar {
    color: $red-40;
  }
}

%currency-stacked {
  padding-left: 30px;
  & ~ .input_label {
    left: 0px;
  }
  & + .currency_dollar {
    top: 40px;
    left: 15px;
  }
}

%currency-underline {
  padding-left: 15px;
  & ~ .input_label {
    left: 15px;
  }
  @include active-state {
    & ~ .input_label {
      left: 0px;
    }
  }
  & + .currency_dollar {
    left: 0px;
  }
}
