@use '../../../common/all' as *;
@use 'input.base' as *;

@mixin textarea {
  @include base-input;
  line-height: 1.5em;
  overflow-y: auto;
  border: 1px solid transparent;
  border-radius: 4px;
  resize: none;
  &.has_error {
    color: $red-40;
    border-bottom-color: $red-40 !important;
  }
}

%textarea-default, %textarea-stacked {
  @include textarea;
  background-color: $bg-light;
  &.has_error {
    border-color: $red-40;
    color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }
  }
}

%textarea-underline {
  @include textarea;
  border: 1px solid transparent;
  border-bottom: 1px solid $black-30;
  border-radius: 0;
  padding: 15px 0;
  background-color: transparent;
  &.bordered {
    border: 1px solid $black-30;
    border-radius: 8px;
    padding: 16px 20px;
    & ~ .input_label {
      margin-bottom: 10px;
    }
  }
  &:focus, &:active {
    border-bottom-color: $black-40;
  }
  & ~ .input_label {
    color: $black-40;
  }
  &.has_error {
    color: $red-40;
    border-bottom-color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }
  }

}
