@use '../common/all' as *;
/* ----------------------------------------
    Spacing
---------------------------------------- */

@mixin spacing($breakpoint: false) {
  $breakpointPrefix: '';

  @if $breakpoint {
    $breakpointPrefix: "-#{$breakpoint}"
  }

  .mt-0#{$breakpointPrefix} { margin-top: 0; }
  .mt-5#{$breakpointPrefix} { margin-top: 5px; }
  .mt-10#{$breakpointPrefix} { margin-top: 10px; }
  .mt-15#{$breakpointPrefix} { margin-top: 15px; }
  .mt-20#{$breakpointPrefix} { margin-top: 20px; }
  .mt-25#{$breakpointPrefix} { margin-top: 25px; }
  .mt-30#{$breakpointPrefix} { margin-top: 30px; }
  .mt-35#{$breakpointPrefix} { margin-top: 35px; }
  .mt-40#{$breakpointPrefix} { margin-top: 40px; }
  .mt-50#{$breakpointPrefix} { margin-top: 50px; }

  .mb-0#{$breakpointPrefix} { margin-bottom: 0; }
  .mb-5#{$breakpointPrefix} { margin-bottom: 5px; }
  .mb-10#{$breakpointPrefix} { margin-bottom: 10px; }
  .mb-15#{$breakpointPrefix} { margin-bottom: 15px; }
  .mb-20#{$breakpointPrefix} { margin-bottom: 20px; }
  .mb-25#{$breakpointPrefix} { margin-bottom: 25px; }
  .mb-30#{$breakpointPrefix} { margin-bottom: 30px; }
  .mb-35#{$breakpointPrefix} { margin-bottom: 35px; }
  .mb-40#{$breakpointPrefix} { margin-bottom: 40px; }
  .mb-50#{$breakpointPrefix} { margin-bottom: 50px; }

  .ml-auto#{$breakpointPrefix} { margin-left: auto; }
  .ml-0#{$breakpointPrefix} { margin-left: 0; }
  .ml-5#{$breakpointPrefix} { margin-left: 5px; }
  .ml-10#{$breakpointPrefix} { margin-left: 10px; }
  .ml-15#{$breakpointPrefix} { margin-left: 15px; }
  .ml-20#{$breakpointPrefix} { margin-left: 20px; }
  .ml-25#{$breakpointPrefix} { margin-left: 25px; }
  .ml-30#{$breakpointPrefix} { margin-left: 30px; }
  .ml-35#{$breakpointPrefix} { margin-left: 35px; }
  .ml-40#{$breakpointPrefix} { margin-left: 40px; }
  .ml-50#{$breakpointPrefix} { margin-left: 50px; }

  .mr-auto#{$breakpointPrefix} { margin-right: auto; }
  .mr-0#{$breakpointPrefix} { margin-right: 0; }
  .mr-5#{$breakpointPrefix} { margin-right: 5px; }
  .mr-10#{$breakpointPrefix} { margin-right: 10px; }
  .mr-15#{$breakpointPrefix} { margin-right: 15px; }
  .mr-20#{$breakpointPrefix} { margin-right: 20px; }
  .mr-25#{$breakpointPrefix} { margin-right: 25px; }
  .mr-30#{$breakpointPrefix} { margin-right: 30px; }
  .mr-35#{$breakpointPrefix} { margin-right: 35px; }
  .mr-40#{$breakpointPrefix} { margin-right: 40px; }
  .mr-50#{$breakpointPrefix} { margin-right: 50px; }

  .m-0#{$breakpointPrefix} { margin: 0; }
  .m-5#{$breakpointPrefix} { margin: 5px; }
  .m-10#{$breakpointPrefix} { margin: 10px; }
  .m-15#{$breakpointPrefix} { margin: 15px; }
  .m-20#{$breakpointPrefix} { margin: 20px; }
  .m-25#{$breakpointPrefix} { margin: 25px; }
  .m-30#{$breakpointPrefix} { margin: 30px; }
  .m-35#{$breakpointPrefix} { margin: 35px; }
  .m-40#{$breakpointPrefix} { margin: 40px; }
  .m-50#{$breakpointPrefix} { margin: 50px; }

}

@include spacing();

@media screen and (max-width: $screen-sm-max) {
  @include spacing("xs");
}
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include spacing("sm");
}
@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include spacing("md");
}
@media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include spacing("lg");
}
@media screen and (min-width: $screen-xlg-min) {
  @include spacing("xlg");
}
