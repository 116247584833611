@use '~@moved/ui/src/balazs/common/all' as *;

.unregistered {
  background-color: $orange-10;
  border: 1px solid $orange-30;
  border-radius: 8px;
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  // flex-direction: column;

  .warning {
    --color-1: #{$orange-60};
    --color-2: #{$orange-20};
  }

  h3 {
    margin: 12px 0;
    color: $black-100;
    font-weight: $regular;
    font-size: $font-size-3;
  }
}

.reminder {
  @extend %btn-ghost;
  $paddingSize: 16px;
  padding-left: $paddingSize;
  padding-right: $paddingSize;
  span {
    margin-left: 8px;
    font-size: $font-size-2;
  }
}
