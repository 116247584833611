@use '../../../common/all' as *;
@use 'input.base' as *;

@mixin text-inputs {
  input[type="text"]:not(:global(.sondheim)), // keeps this from interfering with sondeim Text when it appears as a child of StyledForm
  input[type="tel"],
  input[type="number"],
  input[type="url"],
  input[type="email"],
  input[type="password"],
  .faux_input {
    @content;
  }
}

@mixin active-state {
  &:focus, &:active, &.has_value {
    @content;
  }
  &:not(:empty), &:not(:placeholder-shown), &:not([placeholder=" "]) {
    @content;
  }
}

@mixin text {
  @include base-input;
  height: 48px;
  text-overflow: ellipsis;
  // &[textarea] {
  //   height: auto;
  //   min-height: 70px;
  //   max-height: 100px;
  //   overflow-y: auto;
  //   resize: vertical;
  // }
  &[type="number"] {
    --moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display:none;
    }
  }
}

%text-default {
  @include text;
  background-color: $bg-light;
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 1;
  & ~ .input_label {
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: 2;
    padding: 4px 8px;
    color: $black-40;
    border-radius: 4px;
    background: transparent;
    pointer-events: none;
    transition: top 0.3s, left 0.3s, color 0.3s, box-shadow 0.3s, background 0.3s;
  }
  @include active-state {
    & ~ .input_label {
      top: -12px;
      color: $black-90;
      background: $white;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    }
  }
  &.has_error {
    border-color: $red-40;
    color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }
  }
}

%text-stacked {
  @include text;
  background-color: $bg-light;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  & ~ .input_label {
    display: flex;
    align-items: center;
    position: relative;
    color: $black-90;
    span {
      line-height: 15px;
      height: 15px;
      display: inline-block;
      cursor: pointer;
    }
    @include breakpoint(xs) {
      font-size: $font-size-1;
    }
  }
  &.has_error {
    color: $red-40;
    border: 1px solid $red-40 !important;
  }
}

%text-underline {
  @include text;
  border: 1px solid transparent;
  border-bottom: 1px solid $black-30;
  padding: 15px 0;
  background-color: transparent;
  &:focus, &:active {
    border-bottom-color: $black-40;
  }
  & ~ .input_label {
    position: absolute;
    top: 12px;
    left: 0;
    padding: 4px 0;
    color: $black-40;
    transition: top 0.3s, font-size 0.3s, left 0.3s;
    background: transparent;
    border-radius: 0;
    pointer-events: none;
    font-size: $font-size-2;
  }
  @include active-state {
    & ~ .input_label {
      top: -12px;
      font-size: $font-size-1;
    }
  }
  &.has_error {
    color: $red-40;
    border-bottom-color: $red-40;
    & ~ .input_label {
      color: $red-40;
    }
  }
}
