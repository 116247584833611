@use '../../common/all' as *;

.widthLimits {
  max-width: 700px;
  min-width: 300px;
  @include responsive(mobile) {
    max-width: 100%;
    min-width: none;
  }
}
