@use '../../common/all' as *;
@use '../DynamicForm/styles/input.base' as *;

/* ----------------------------------------
    Mixins
---------------------------------------- */

@mixin active-state {
  &:focus, &:active, &.has_value {
    @content;
  }
  &:not(:empty), &:not(:placeholder-shown) {
    @content;
  }
}

/* ----------------------------------------
    Keyword search styling
---------------------------------------- */

.pill {
  font-size: $font-size-2;
  border: 1px solid $black-30;
  border-radius: 26px;
  transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:focus-within {
    border-color: $black-50;
  }

  .pill_inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 26px;
    min-height: 100%;
    margin: -1px;
    transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
    span {
      line-height: 16px;
    }
  }

  &.pill_active {
    background-color: $black-20;
    border-color: $black-60;
    .pill_inner {
      border-color: $black-60;
    }
  }
}

.search_bar {
  display: flex;
  position: relative;
  align-items: center;
}

.input_icon {
  position: absolute;
  left: 6px;
  z-index: 1;
  cursor: text;
  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }
}

.clear_icon {
  margin-left: 4px;
  height: 30px;
  width: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    cursor: pointer;
    --color-1: #{$black-90};
    --color-2: #{$black-90};
    &:hover {
      --color-1: #{$red-40};
      --color-2: #{$red-40};
    }
  }
}

.search_input {
  @include base-input;
  height: 30px;
  text-overflow: ellipsis;
  border: 0px transparent none;
  font-size: $font-size-2;
  border: 1px solid transparent;
  padding: 0 0 0 30px;
  background-color: transparent;
  transition: width ease-in-out 0.25s;
  width: 80px;

  @include active-state {
    width: 100px;
    & ~ .input_icon {
      svg {
        --color-1: #{$black-90};
        --color-2: #{$black-90};
      }
    }
  }
  &:focus, &:active {
    width: 160px;
  }
}
