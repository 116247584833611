@use '../mixins/responsive' as *;

@mixin headingL {

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  @include responsive(mobile) {
    /* no differences */
  }

}

@mixin headingM {

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  @include responsive(mobile) {
    /* no differences */
  }

}

@mixin headingS {

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  @include responsive(mobile) {
    /* no differences */
  }

}
