@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    General Header layout
---------------------------------------- */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: $headerHeight;
  padding: 10px 60px;
  display: flex;
  align-items: center;
  color: $white;
  transition: background-color ease-in 0.3s, height ease-in 0.3s;
  @include breakpoint([xs]) {
    padding: 10px 23px;
    height: $mobileHeaderHeight;
  }
}

.tight {
  background-color: $black-90;
  height: 70px;
  .building_logo {
    height: 40px;
  }
}

.light {
  color: $black-90;
  &.tight {
    background-color: $white;
  }
}

/* -- Logo Section --*/
.logo {
  display: inline-block;
}

/* -- Navigation Section --*/

.navigation {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}
.nav_item {
  font-size: $font-size-3;
  color: $white;
  padding: 0 20px;
  opacity: 0.6;
  transition: opacity 0.2s;
  cursor: pointer;
  position: relative;
  &:hover, &:active, &:visited, &:focus {
    color: $white;
    opacity: 1;
  }
  &.active {
    color: $white;
    opacity: 1;
    &:after {
      content:'';
      position: absolute;
      height: 3px;
      width: 18px;
      background: $white;
      bottom: -5px;
      left: calc(50% - 9px);
    }
  }
}

/* -- User Info Section --*/
.user {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

div.user_profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $white;
  padding: 6px 12px 6px 18px;
  height: 38px;
  &:hover {
    color: $white;
  }
  .light & {
    color: $black-90;
    &:hover {
      color: $black-90;
    }
  }
}

.user_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.user_name {
  font-size: $font-size-2;
  font-weight: $med;
  white-space: nowrap;
}

.user_icon {
  display: inline-block;
  transition: transform 0.3s;
  --color-1: #{$white};
  --color-2: #{$white};
  .light & {
    --color-1: #{$black-90};
    --color-2: #{$black-90};
  }
  &.active {
    transform: rotate(90deg);
  }
}

.user_popover {
  width: 200px;
  background-color: $black-90;
  border: none;
  border-radius: 8px;
  padding: 4px;
  color: white;
  overflow: hidden;
}

.popover_action {
  cursor: pointer;
  display: block;
  padding: 12px 20px;
  transition: background 0.3s;
  color: $white;
  background: transparent;
  border-radius: 4px;
  &:hover, &:active, &:focus, &.active {
    color: $white;
    background: $black-80;
  }
}

.popover_icon {
  float: left;
  margin-right: 12px;
  font-style: normal;
  font-size: 20px;
  --color-1: #{$white};
  --color-2: #{$white};
  &.my_moves {
    --color-1: #{$black-100};
    --color-2: #{$white};
  }
}

.popover_link {
  color: inherit;
}

.tooltip {
  width: 170px;
  background-color: $black-10;
  border: none;
  border-radius: 8px;
  padding: 15px;
}

[data-popper-placement*='top'] .tooltip_arrow::after {
  @include arrow(9px,$black-10,'down');
  top: 0;
  left: 0;
}

[data-popper-placement*='bottom'] .tooltip_arrow::after {
  @include arrow(9px,$black-10,'up');
  top: 0;
  left: 0;
}

.tooltip_text {
  color: $black-90;
  font-size: 12px;
  line-height: 16px;
}
