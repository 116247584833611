@use '~@moved/ui/src/balazs/common/all' as *;
@use './mixins';

// Layout Wrapper
.box {
  display: block;
  background-color: $white;
  padding: 4px 32px 4px 0px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 16px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out;
  color: $black-90;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &:active, &:focus {
    transform: scale(1.025);
    color: $black-90;
  }

  &.client_box {
    padding: 14px 14px 14px 0px;
  }

  @extend %field_columns;
}

.box_active {
  transform: scale(1.018);
  padding: 20px 46px;

  .arrow {
    transform: rotate(-180deg);
  }
}

.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex-basis: 21%;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}


/* --- Columns END ---- */

.oval {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background: linear-gradient(180deg, #C2C5C9 0%, #6F777F 100%);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: $white;
    font-size: $font-size-3;
    cursor: default;
  }
}

.namez {
  margin-left: 16px;
  font-size: $font-size-2;
  min-width: 180px;
  @include breakpoint([xs,sm]) {
    min-width: 0;
  }
  .first {
    color: $black-90;
  }
  .last {
    color: $black-40;
  }
}

.arrow_icon {
  transition: transform 0.3s ease-in-out;
  --color-1: #{$black-40};
  --color-2: #{$black-40};
}


.address_line {
  display: flex;
  flex-direction: column;
  .address_line_two {
    font-size: $font-size-1;
    color: $black-40;
  }
}

.reminder {
  margin-left: 12px;
}

.reminder_popover {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 0;
  padding: 4px 8px;
  background-color: $black-100;
  color: $white;
  font-size: $font-size-1;
}
