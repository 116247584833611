@use '~@moved/ui/src/sondheim/common' as *;

.stopsList {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background-color: $backgroundTertiary;
    width: 2px;
    left: 19px;
    top: 24px;
    bottom: 24px;
    z-index: 1;
  }
}

.stopIcon {
  width: 40px;
  height: 40px;
  padding: $sp-8;
  border-radius: 50%;
  position: relative;
  z-index: 2;

  &.small {
    width: 24px;
    height: 24px;
    padding: $sp-4;
    margin: $sp-8;
  }
}

.removeStop {
  cursor: pointer;
  // transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
}
