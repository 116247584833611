@use '../../common/all' as *;
@use '../../ui/keyframes' as *;

.overlay {
  z-index: 99999999;
  background-color: rgba(230,230,230,0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 400ms ease-in both;
}

.darken {
  background-color: rgba(0,0,0,0.5);
}
