@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  flex: 1 1 auto;
  margin: 40px 80px;
  position: relative;
  max-width: 1020px;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}
