@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.box {
  display: block;
  background-color: $white;
  padding: 10px 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
  font-size: $font-size-2;
  margin-bottom: 16px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, padding 0.2s ease-in-out;
  color: $black-90;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &:active, &:focus {
    transform: scale(1.025);
    color: $black-90;
  }

  &.disabled {
    cursor: default;
    &:hover, &:active, &:focus {
      transform: none;
    }
  }
}

.box_active {
  transform: scale(1.018);
  padding: 20px 46px;

  .arrow {
    transform: rotate(-180deg);
  }
}

.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex-basis: 21%;
    padding: 0 10px;
    display: flex;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.direction {
  flex-basis: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .direction_icon {
    margin-right: 2px;
  }
}

.unit {
  font-size: 18px;
  flex-basis: 11%;
}

.name_area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 22%;
  min-width: 150px;
  @include breakpoint([xs,sm]) {
    min-width: 0;
  }
}

.arrow_area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
}

.logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
  padding-right: 0;
}

.oval {
  height: 28px;
  width: 28px;
  border-radius: 100px;
  background: linear-gradient(180deg, #C2C5C9 0%, #6F777F 100%);
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: $white;
    font-size: $font-size-1;
    cursor: default;
  }
}

.name {
  display: flex;
  flex-direction: column;
  font-size: $font-size-2;
  flex-basis: 24%;
  .email {
    color: $black-50;
    font-size: $font-size-1;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
  --color-1: #{$black-40};
  --color-2: #{$black-40};
}

.progress {
  min-width: 154px;
  display: flex;
}

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-0;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  padding: 3px 9px;
  font-weight: $med;
  white-space: nowrap;
  color: $black-60;
  background-color: $black-20;
  border: 1px solid $black-20;

  &.invited {
    background-color: $orange-20;
    border-color: $orange-20;
    color: $orange-60;
  }

  &.registered {
    background-color: $blue-20;
    border-color: $blue-20;
    color: $blue-60;
  }
}

.reminder {
  margin-left: 12px;
}

.reminder_popover {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 0;
  padding: 4px 8px;
  background-color: $black-100;
  color: $white;
  font-size: $font-size-1;
}
