@use '~@moved/ui/src/sondheim/common' as *;

/* ----------------------------------------
    404 Page Layout
---------------------------------------- */

.wrapper {
  padding: $sp-40;
  flex: 1 1 auto;
}

.image {
  position: relative;
  z-index: 0;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1.0) 0%, rgba(0,0,0,0) 93.5%);
  & > img {
    max-width: 583px; // custom size based on designs
    width: 100%;
    padding: $sp-12 22px $sp-8;
  }
}

.content {
  z-index: 1;
  text-align: center;
}
