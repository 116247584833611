@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  text-align: left;
}
.subtitle {
  text-align: left;
  color: $black-40;
  font-size: $font-size-3;
  font-weight: $light;
  margin-bottom: 30px;
}
.footer {
  text-align: center;
}
.disclaimer {
  color: $black-40;
  margin-top: 25px;
  margin-bottom: 0;
  text-align: left;
  a {
    text-decoration: underline;
    color: inherit;
  }
}
.button {
  @extend %btn-primary;
  margin-top: 25px;
  position: relative;
  max-width: 100%;
  min-width: 190px;
  padding: 5px 64px;
  float: left;

  .arrow {
    position: absolute;
    right: 24px;
  }
}

@include breakpoint(xs) {
  .content {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }
  .flex_wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 44px;
  }
  .subtitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
  .footer {
    width: 100%;
    margin-top: 16px;
  }
  .disclaimer {
    margin-top: 0;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
  .button {
    width: 100%;
    margin-top: 12px;
  }
}
