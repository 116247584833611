@use '~@moved/ui/src/sondheim/common' as *;

.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $sp-40 0 $sp-16;
  width: 100%;
  h3 {
    font-size: 16px;
  }
}

.option_actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.option {
  margin-bottom: $sp-16;

  &:last-child {
    margin-bottom: $sp-0;
  }
}

.icons {
  display: flex;
  justify-content: center;
  align-items: right;
}

.icon {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }

  &:last-child {
    margin-right: $sp-0;
  }

  &.drag {
    cursor: grab;
  }
}

.icon_trash {
  cursor: pointer;

  svg {
    --color-1: #{$red-30};
    --color-2: #{$red-30};
  }

  &:hover {
    svg {
      --color-1: #{$red-50};
      --color-2: #{$red-50};
    }
  }
}

.icon_more {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$blue-50};
      --color-2: #{$blue-50};
    }
  }
}

.property_popover {
  border: 0px none transparent;
  box-shadow: $shadow-4;
  border-radius: $radius-12;
  padding: $sp-12 $sp-0;
  user-select: none;
}

.popover_action {
  padding: $sp-8 $sp-18;
  width: 186px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & > svg {
    margin-right: $sp-12;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }

  &.delete {
    &:hover {
      color: $red-50;
      --color-1: #{$red-50};
      --color-2: #{$red-50};
    }
  }

  &:hover {
    color: $blue-40;
    & > svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.option_icon {
  margin-right: $sp-16;
}

.listItem {
  // unfortunately drag and drop library does not support flex gaps
  margin-bottom: $sp-12;
  // &:last-child {
  //   this breaks drag & drop layout in the current library
  //     (account for extra bottom margin on parent container)
  //   margin-bottom: 0;
  // }
}
