@use '../../../common/all' as *;
@use 'input.all' as *;

%styled-form {
  position: relative;
  flex: 1;

  .fields {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .title {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: $font-size-3;
    font-weight: $regular;
    &:first-child {
      margin-top: 0;
    }
  }

  .input_row, .array_row, .address_list_row {
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    line-height: 16px;

    &.half_input {
      @include breakpoint(n-xs) {
        width: calc(50% - 8px);
        align-self: flex-start;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoint(xs) {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .flavor {
    margin-top: 5px;
    color: $black-40;
    font-size: $font-size-1;
  }

  p {
    line-height: 1.6
  }

  &.style_stacked {
    @import 'style.stacked';
  }
  &.style_underline {
    @import 'style.underline';
  }
  &.style_default {
    @import 'style.default';
  }

  /* Custom Field Type Styles */
  // TODO: migrate to field & form style specific organization
  .array_block {
    width: 100%;
  }
  .array_label {
    display: flex;
    margin: 10px 0;
    line-height: 22px;

    label {
      flex: 1 1 auto;
      font-size: $font-size-2;
      cursor: text;
    }

    .array_add {
      flex: 0 0 auto;
      display: flex;
      font-size: $font-size-4;
      font-weight: $bold;
      color: $white;
      background-color: $primary;
      border-radius: 22px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      span {
        height: 10px;
        line-height: 8px;
      }
      &:hover {
        background-color: $primary-hover;
      }
    }
  }

  .array_item_row {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }

    .input_row {
      margin-left: 10px;
      margin-bottom: 0;
      &:first-child {
        margin-left: 0;
      }
    }
    .array_remove {
      position: absolute;
      top: 50%;
      right: -28px;
      height: 24px;
      width: 24px;
      margin-top: -12px;
      cursor: pointer;
      .array_remove_icon {
        --color-1: #{$black-30};
        --color-2: #{$black-30};
      }
      &:hover .array_remove_icon {
        --color-1: #{$black-40};
        --color-2: #{$black-40};
      }
    }
    .error_msg {
      font-size: $font-size-1;
    }

  }

  .item_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    &.item_list_square {
      margin-left: -4px;
      margin-right: -4px;
      justify-content: flex-start;
    }
  }

  .item_wrapper {
    flex: 0 1 100%;
    display: flex;

    &.disabled_item {
      opacity: 0.7;
      color: $black-30;
      & > div:hover {
        border-color: transparent;
      }
    }
  }

  .two_col .item_wrapper {
    flex: 0 0 auto;
    width: calc(50% - 8px);
    @include breakpoint([xs,sm]) {
      width: 100%;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $white;
    border-radius: 8px;
    padding: 17px;
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
    margin-bottom: 12px;
    border: 1px solid transparent;
    transition: border-color linear 0.2s;
    flex-basis: 100%;

    @include breakpoint([xs]) {
      border-width: 1px;
    }

    .item_content {
      display: flex;
      align-items: center;
    }

    .item_labels {
      display: flex;
      align-items: center;
      flex-grow: 1;
      @include breakpoint([xs,sm]) {
        flex-direction: column;
      }
    }

    .item_hidden {
      padding-top: 25px;
    }

    .item_icon {
      margin-right: 20px;
      opacity: 0.9;
      @include breakpoint([xs]) {
        max-height: 24px;
        flex: 0 0 24px;
      }
    }

    .custom_icon {
      margin-right: 20px;
    }

    .label_wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }

    .item_label {
      font-size: $font-size-2;
      line-height: 1.3;
      user-select: none;
    }

    .item_sublabel {
      font-size: $font-size-1;
      color: $black-40;
      user-select: none;
      @include breakpoint(xs) {
        margin-top: 5px;
      }
    }

    .item_rightLabel {
      font-size: $font-size-3;
      user-select: none;
      margin-right: 20px;
      @include breakpoint([xs]) {
        font-size: $font-size-1;
        width: 100%;
      }
    }

    .item_check {
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg.on {
        display: none;
        --color-1: #{$white};
        --color-2: #{$green-40};
      }
      svg.off {
        --color-1: #{$black-40};
        --color-2: #{$black-40};
      }
    }

    &.item_large {
      padding: 25px;
      .item_label {
        font-size: $font-size-4;
      }
      @include breakpoint([xs]) {
        padding: 17px;
        .item_label {
          font-size: $font-size-3;
        }
      }
    }

    &:hover {
      border-color: $green-30;
      box-shadow: 0 3px 8px 0 rgba(0,0,0,0.06);
      svg.off {
        --color-1: #{$white};
        --color-2: #{$green-40};
      }
    }

    &.selected_item {
      border-color: $green-40;
      .item_check {
        svg.off {
          display: none;
        }
        svg.on {
          display: block;
        }
      }
      &:hover {
        border-color: $green-30;
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.06);
      }
    }
  }

  .item_divider {
    height: 1px;
    background-color: $black-20;
    margin: 8px 0 20px;
    flex: 0 1 100%;
  }

  .square_wrapper {
    flex: 1 0 21%;
    margin-bottom: 10px;
    margin: 0 4px 8px;

    @include breakpoint([xs]) {
      flex: 1 0 45%;
      margin-bottom: 12px;
    }
  }

  .square {
    display: flex;
    background-color: $black-20;
    border-radius: 8px;
    padding: 16px 10px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color linear 0.2s;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .square_label {
      font-size: $font-size-2;
      text-align: center;
      flex: 1 1 auto;
    }

    &.white {
      background-color: $white;
    }

    .item_icon {
      margin-bottom: 12px;
    }

    &:hover {
      border-color: $green-30;
    }

    &.selected_square {
      background-color: $green-10;
      border-color: $green-40;
      box-shadow: 0 4px 10px 0 rgba(0,0,0,0.06);
      .item_check {
        border-color: $green-40;
        background-color: $green-20;
      }
      &:hover {
        border-color: $green-40;
      }
    }
  }
  //  Google Places Inputs

  .google_place_wrapper {
    width: 100%;
    position: relative;
  }

  .google_autocomplete_loader {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    cursor: default;
    align-items: center;
  }

  .google_place_loader {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: inherit;
    cursor: default;

    & > span {
      color: $black-90;
      font-size: $font-size-1;
      margin-left: 10px;
    }
  }

  /* --- BEGIN Places Autocomplete --- */

  .suggest_container {
    z-index: 4;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: $white;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    box-shadow: 0 6px 16px 2px rgba(139, 148, 159, 0.2);
  }

  .suggest_suggestion {
    cursor: pointer;
    padding: 10px 8px;
    font-size: $font-size-1;
    &:hover {
      background-color: $blue-20;
    }
  }

  .suggest_suggestion_active {
    background-color: $blue-10;
    &:hover {
      background-color: $blue-20;
    }
  }

  /* --- END Places Autocomplete --- */

  // Address Inputs

  .address_field {
    cursor: pointer;
    color: $black-40;
    &:hover {
      border-bottom-color: $black-40;
      color: $black-90;
      // background-color: $black-20;
    }
  }

  .address_list_block {
    display: block;
    width: 100%;
    .address_item {
      &::before {
        content: '';
        background-color: $black-20;
        display: block;
        position: absolute;
        width: 2px;
        left: 19px;
        top: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  .address_item {
    display: flex;
    position: relative;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    & .address_content {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
    &:first-child {
      align-items: flex-start;
      padding-top: 0;
      &::before {
        top: 24px;
      }
    }
    &:last-child {
      padding-bottom: 0;
      align-items: flex-start;
      &::before {
        height: 34px;
        bottom: auto;
      }
      .address_icon::after {
        border-radius: 0;
      }
    }
    &:first-child, &:last-child {
      .address_icon {
        width: 40px;
        height: 40px;
        margin-left: 0;
        &::after {
          background-color: $black-90;
          height: 10px;
          width: 10px;
        }
      }
    }

    & ~ label {
      margin-bottom: -8px;
    }

  }

  .address_icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: $white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    margin-top: 4px;
    margin-right: 20px;
    margin-left: 5px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
    &::after {
      content: '';
      display: block;
      background-color: $black-40;
      height: 8px;
      width: 8px;
      border-radius: 100px;
    }
  }

  /* --- BEGIN Address Row Transitions --- */
  .address_enter {
    height: 0;
    .address_icon {
      transform: scale(0);
    }
    .input_row {
      opacity: 0;
      transform: translateX(-20px);
      height: 24px;
    }
    .address_remove {
      opacity: 0;
      transform: scale(0);
    }
  }

  .address_enter_active {
    height: 88px;
    transition: height 400ms linear 0ms;
    .address_icon {
      transform: scale(1);
      transition: transform 200ms ease-in 200ms;
    }
    .input_row {
      opacity: 1;
      transform: translateX(0) scale(1);
      height: 48px;
      transition: opacity 200ms ease-in 200ms, transform 200ms ease-in 200ms, height 200ms ease-in 200ms;
    }
    .address_remove {
      opacity: 1;
      transform: scale(1);
      transition: opacity 100ms ease-in 300ms, transform 100ms ease-in 300ms;
    }
  }
  /* --- END Address Row Transitions --- */

  .add_stop {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;
    &::before {
      content: '';
      background-color: $black-20;
      display: block;
      position: absolute;
      width: 2px;
      left: 19px;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    & .add_content {
      z-index: 2;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .add_stop_icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: $white;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    margin: 0 20px 0 5px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
    cursor: pointer;
    transition: transform .3s;
    &:hover {
      transform: scale(1.2);
    }
  }

  .add_stop_button {
    cursor: pointer;
    display: block;
    border-radius: 20px;
    background-color: $black-20;
    padding: 10px 20px;
    transition: background-color .3s, transform .3s;
    &:hover {
      background-color: $black-30;
      transform: scale(1.06);
    }
  }

  /* --- BEGIN Add Stop Button Transitions --- */
  .add_stop_enter {
    height: 0;

    .add_stop_icon {
      transform: scale(0);
    }
    .add_stop_button {
      opacity: 0;
      transform: translateX(-20px) scale(0.8);
    }
  }

  .add_stop_enter_active {
    height: 56px;
    transition: height 400ms linear 0ms;

    .add_stop_icon {
      transform: scale(1);
      transition: transform 100ms ease-in 300ms;
    }
    .add_stop_button {
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: opacity 200ms ease-in 200ms, transform 200ms ease-in 200ms;
    }
  }

  .add_stop_exit {
    height: 0;

    .add_stop_icon {
      transform: scale(0);
    }
    .add_stop_button {
      opacity: 0;
    }
  }
  .add_stop_exit_active {
    height: 0;

    .add_stop_icon {
      transform: scale(0);
      transition: transform 200ms ease-out 0ms;
    }
    .add_stop_button {
      opacity: 0;
      transform: translateX(-20px);
      transition: opacity 200ms ease-out 0ms, transform 200ms ease-out 0ms;
    }
  }
  /* --- END Add Stop Button Transitions --- */

  .address_remove {
    opacity: 0.7;
    position: absolute;
    right: 0;
    z-index: 20;
    cursor: pointer;
    padding: 10px;
    transition: opacity .3s, transform .3s;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }

}
