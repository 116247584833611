@use '../../../../common/all' as *;

.url_wrapper {
  margin-top: 10px;
  background-color: $black-60;
  border-radius: 3px;
  padding: 6px 10px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 550px;
  .label {
    font-weight: bold;
    color: $white;
    user-select: none;
    cursor: default;
    margin-right: 10px;
  }
  .url_input {
    background: transparent;
    border: none;
    color: $white;
    width: 100%;
    outline: none;
    &::placeholder {
      color: $white;
      opacity: 0.5;
    }
  }
  &::before {
    @include arrow(8px,$black-60,'up');
    top: -8px;
    left: 20px;
    position: absolute;
  }
}




/*  ---- Editor Styles ----  */
.editor {
  position: relative;
  display: flex;
  padding: 0;
  gap: 24px;
}

/*  ---- Render Styles ----  */
.render {
  display: flex;
  gap: 24px;
}
