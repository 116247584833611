@use '../../../../common/all' as *;
@use 'filterSearch' as *;

/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.results_section {
  padding-top: 40px;
}
