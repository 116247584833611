@use '../../../common/all' as *;
@use 'input.text' as *;

%integer-default,
%integer-stacked,
%integer-underline {
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  & ~ .increment, & ~ .decrement {
    position: absolute;
    top: 34px;
    z-index: 2;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    transition: transform .2s;
    color: $black-40;
    user-select: none;
    &:hover {
      transform: scale(1.5);
      color: $black-90;
    }
  }
  & ~ .decrement { left: 0; }
  & ~ .increment { right: 0; }
  & ~ .input_label {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    border-radius: 0;
    margin-bottom: 10px;
    color: $black-90;
  }
  @include active-state {
    & ~ .input_label {
      background: transparent;
      box-shadow: none;
      top: 0;
      left: 0;
    }
  }
}

%integer-stacked {
  & ~ .increment, & ~ .decrement {
    top: 31px;
  }
}

%integer-underline {
  & ~ .increment, & ~ .decrement {
    top: 8px;
  }
  & ~ .input_label {
    margin-bottom: -8px;
    margin-top: -8px;
    color: $black-40;
  }
}
