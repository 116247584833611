@use '../../../common/all' as *;
@use 'input.base' as *;

%pills-default,
%pills-stacked,
%pills-underline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .pills {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &.has_error {
      .pill {
        border-color: $red-40;
        color: $red-60;
      }
    }
  }

  .pill {
    font-size: $font-size-2;
    border: 1px solid $black-30;
    border-radius: 26px;
    transition: color ease-in-out 0.25s, background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
    margin-right: 8px;
    margin-bottom: 12px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }

    .pill_inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 14px;
      border: 2px solid transparent;
      border-radius: 26px;
      min-height: 100%;
      margin: -1px;
      transition: background-color ease-in-out 0.25s, border-color ease-in-out 0.25s;
      & > span {
        line-height: 16px;
        white-space: nowrap;
      }
    }

    .item_label {
      font-size: $font-size-2;
    }

    &:hover {
      border-color: $black-50;
    }

    :global(.filterSearchActive) & {
      border-color: $black-60;
    }

    &.pill_active {
      background-color: $black-20;
      border-color: $black-60;
      .pill_inner {
        border-color: $black-60;
        color: $black-90;
      }
    }
  }
}
