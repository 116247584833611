/* COLOR CODES, ALL SHADES */
$black-10: #f5f6f6;
$black-20: #e0e2e3;
$black-30: #c2c5c9;
$black-40: #91979d;
$black-50: #6f777f;
$black-60: #5a6067;
$black-70: #484d52;
$black-80: #35393d;
$black-90: #212326;
$black-100: #151618;

$blue-10: #f0f6fe;
$blue-20: #c9e0fc;
$blue-30: #95c2f9;
$blue-40: #3f92f5;
$blue-50: #0370f3;
$blue-60: #025ac4;
$blue-70: #01489e;
$blue-80: #013675;
$blue-90: #002148;
$blue-100: #00152e;

$green-10: #f0f7f3;
$green-20: #cbe6d6;
$green-30: #96ccac;
$green-40: #40a369;
$green-50: #05873b;
$green-60: #046d2f;
$green-70: #035726;
$green-80: #02411c;
$green-90: #012811;
$green-100: #00190b;

$red-10: #fcf2f2;
$red-20: #f5d1d1;
$red-30: #eca3a3;
$red-40: #dd5858;
$red-50: #d32424;
$red-60: #aa1d1d;
$red-70: #881717;
$red-80: #641111;
$red-90: #3f0a0a;
$red-100: #270606;

$orange-10: #fcf4f0;
$orange-20: #f4d9cb;
$orange-30: #e9b498;
$orange-40: #d87844;
$orange-50: #cc4e0a;
$orange-60: #a43f08;
$orange-70: #843206;
$orange-80: #622504;
$orange-90: #3c1702;
$orange-100: #270e01;

$yellow-10: #fefbf3;
$yellow-20: #fcf3d5;
$yellow-30: #f9e7aa;
$yellow-40: #f5d365;
$yellow-50: #f2c635;
$yellow-60: #c39f2a;
$yellow-70: #9d8022;
$yellow-80: #735e19;
$yellow-90: #483b0f;
$yellow-100: #2e260a;

$purple-10: #f8f3fd;
$purple-20: #e7d5f8;
$purple-30: #d0abf2;
$purple-40: #a967e8;
$purple-50: #8f38e1;
$purple-60: #732db5;
$purple-70: #5c2491;
$purple-80: #441b6c;
$purple-90: #2a1043;
$purple-100: #1a0a2a;

$brown-10: #f8f6f4;
$brown-20: #e9e0d8;
$brown-30: #d3c1b3;
$brown-40: #b08f75;
$brown-50: #986d4a;
$brown-60: #7b583c;
$brown-70: #624630;
$brown-80: #493423;
$brown-90: #2d2016;
$brown-100: #1c140d;

$neutral-0: #ffffff;
$neutral-100: #000000;
