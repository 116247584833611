@use '../../../common' as *;

.inputRow {
  align-items: center;
}

.input {
  display:none;
  & ~ .checkbox {
    cursor: pointer;
    --color-1: #{$iconSingleEnabled};
    --color-2: #{$iconSingleEnabled};
    &:hover {
      --color-1: #{$iconSingleHover};
      --color-2: #{$iconSingleHover};
    }
    &.error {
      --color-1: #{$iconMultiError};
      --color-2: #{$iconMultiError};
    }
  }
  &:checked ~ .checkbox {
    --color-1: #{$iconOnColor};
    --color-2: #{$iconMultiAccent};
    &:hover {
      --color-1: #{$iconOnColor};
      --color-2: #{$ctaAccentHover};
    }
  }
  &:disabled ~ .checkbox {
    --color-1: #{$iconSingleDisabled};
    --color-2: #{$iconSingleDisabled};
    &.error {
      --color-1: #{$iconMultiError};
      --color-2: #{$iconMultiError};
    }
  }
  &:disabled:checked ~ .checkbox {
    --color-1: #{$iconOnColor};
    --color-2: #{$ctaAccentDisabled};
  }
}

.label {
  cursor: pointer;
}
