@use '../../../common/all' as *;

.selector {
  margin-top: 10px;
}

.content {
  display: flex;
}

.circle {
  background-color: $black-20;
  border-radius: 200px;
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 28px;
}

.button {
  @extend %btn-primary;
  font-size: $font-size-2;
  height: 32px;
  line-height: 20px;
  padding: 6px 16px;
}

.helper {
  color: $black-40;
  font-size: $font-size-1;
  margin-top: 8px;
}
