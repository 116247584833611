@use '~@moved/ui/src/balazs/common/all' as *;

.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 0 1 auto;
  position: relative;
  width: 100%;

  .bar {
    background-color: $blue-50;
    height: 0;
    width: 4px;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }

}

.section_label {
  font-weight: 500;
  font-size: $font-size-0;
  line-height: 160%;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 28px 32px 8px;
  color: $black-70;
}
