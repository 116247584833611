@use '../../common/all' as *;

.container {
  position: relative;
  cursor: pointer;
  &:hover {
    .edit {
      transform: translateY(0);
      opacity: 0.5;
    }
  }
}

.edit {
  position: absolute;
  top: -5px;
  right: -5px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s;
}
