@use '../../../../common/all' as *;

/*  ---- General Styles ----  */

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.image {
  min-height: 40px;
  max-height: 60px;
  max-width: 220px;
  flex-shrink: 0;
  margin: 0;
}

.caption {
  max-width: 60%;
  text-align: right;
  color: $black-60;
  border: 0 none transparent;
  box-shadow: none;
  padding: 0;
  position: relative;
  display: block !important;

  &[contenteditable="true"][data-placeholder]:empty::before {
    width: 100%;
    position: absolute;
    left: auto;
    right: 0;
  }
}

/*  ---- Editor Styles ----  */
.editor {}

.uploader {
  border: 2px dashed $black-30;
  font-size: $font-size-2;
  color: $black-90;
  white-space: nowrap;
  &:focus, &:active {
    border-color: $blue-40;
  }
}

.preloader {
  transform: scale(0.7);
}

.image_container {
  max-width: 35%;
  min-height: 30px !important;
  border-radius: 2px;
  margin: 0 10px 0 0;
}

/*  ---- Render Styles ----  */
.render {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .image {
    margin-right: 10px;
    border-radius: 2px;
  }
}
