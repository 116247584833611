@use '~@moved/ui/src/balazs/common/all' as *;

.wrapper {
  width: 100%;
}


.section_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  width: 100%;
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}

.chapter_actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > div {
    @extend %btn-gray;
    @extend %btn--small;
    font-size: $font-size-2;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 8px;
    svg {
      margin-right: 8px;
      --color-1: #{$black-90};
      --color-2: #{$black-90};
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

// Chapter Wrapper
.chapter {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.icons {
  display: flex;
  justify-content: center;
  align-items: right;
}

.icon {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$black-100};
      --color-2: #{$black-100};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.drag {
    cursor: grab;
  }
}

.icon_trash {
  cursor: pointer;

  svg {
    --color-1: #{$red-30};
    --color-2: #{$red-30};
  }

  &:hover {
    svg {
      --color-1: #{$red-50};
      --color-2: #{$red-50};
    }
  }
}

.icon_more {
  cursor: pointer;

  svg {
    --color-1: #{$black-40};
    --color-2: #{$black-40};
  }

  &:hover {
    svg {
      --color-1: #{$blue-50};
      --color-2: #{$blue-50};
    }
  }
}

.property_popover {
  border: 0px none transparent;
  box-shadow: 0px 40px 69px rgba(0, 0, 0, 0.04), 0px 20px 30px rgba(0, 0, 0, 0.027), 0px -8px 11px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.013);
  border-radius: 13px;
  padding: 12px 0;
  user-select: none;
}

.popover_action {
  padding: 6px 18px;
  width: 186px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 12px;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }

  &.delete {
    &:hover {
      color: $red-50;
      & > svg {
        --color-1: #{$red-50};
        --color-2: #{$red-50};
      }
    }
  }

  &:hover {
    color: $blue-40;
    & > svg {
      --color-1: #{$blue-40};
      --color-2: #{$blue-40};
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  h4 {
    font-weight: $regular;
  }
}

.buttons {
  display: flex;
  gap: 20px;
  margin-top: 60px;
  padding-bottom: 24px;
}

.save {
  min-width: 340px;
}
