@use '~@moved/ui/src/balazs/common/all' as *;

// Notification settings container
.notifications {
  padding: 24px 0 16px 16px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

  h3 {
    font-size: $font-size-3;
    font-weight: $regular;
  }

  .deselect {
    @extend %faux-link;
    font-size: $font-size-1;
  }
}

.form {
  .option {
    margin-bottom: 10px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    & > label {
      font-size: $font-size-2 !important;
    }
  }
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4px;
}

.display_name {
  color: $black-100;
  font-size: $font-size-2;
}

.task_title {
  color: $black-60;
  font-size: $font-size-1;
}
