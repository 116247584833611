@use '~@moved/ui/src/sondheim/common' as *;

.calendar {
  height: calc(100% - 126px);
}

.no_calendar {
  border: 1px solid $black-30;
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    @include headingL;
    margin-top: 10px;
    color: $black-80;
    text-align: center;
  }
}
