@use '~@moved/ui/src/balazs/common/all' as *;

.title {
  margin-bottom: 26px;
}

.section {
  margin-bottom: 26px;
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:last-child {
    margin-bottom: 0;
  }
}

.detailsHeader {
  font-weight: 600;
}

.actions {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
