@use '../../../common' as *;

$toggleWidth: 40px;
$knobSize: 20px;
$knobGap: 2px;

.wrapper {
  display: flex;
  gap: $sp-16;
  align-items: center;
}

.input {
  display:none;
  & ~ .toggle {
    flex: 0 0 $toggleWidth;
    cursor: pointer;
    display: block;
    background: $black-30;
    height: $knobSize + 2*$knobGap;
    width: $toggleWidth;
    border-radius: 999em;
    padding: $knobGap;
    transition: padding 0.1s linear;
    &:before {
      content:'';
      display: block;
      height: $knobSize;
      width: $knobSize;
      border-radius: 999em;
      background: $backgroundSecondary;
    }
    &:hover {
      background: $black-40;
    }
  }
  &:checked ~ .toggle {
    background: $ctaAccentEnabled;
    padding-left: $toggleWidth - $knobSize - $knobGap;
    &:hover {
      background: $ctaAccentHover;
    }
  }
  &:disabled ~ .toggle {
    cursor: default;
    background: $ctaSecondaryDisabled;
  }
  &:disabled:checked ~ .toggle {
    background: $ctaAccentDisabled;
  }
}
