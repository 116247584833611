@use '../../../../common/all' as *;


/*  ---- General Styles ----  */
.icon {
  flex: 0 0 auto;
  margin-right:24px;
}
%wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0 0 0;
  border-top: 1px solid $black-20;
  border-bottom: 1px solid $black-20;
}

%item {
  display: flex;
  flex-basis: 45%;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 20px;
  min-height: 20px;
  line-height: 26px;
  @include breakpoint(xs) {
    flex-basis: 100%
  }
}


/*  ---- Editor Styles ----  */
.editor {
  @extend %wrapper;
  color: attr(data-color);
}

.item {
  @extend %item;
  padding-left: 34px;
  position: relative;
  min-height: 40px;
  &:before {
    content: url('./check.svg');
    width: 20px;
    height: 20px;
    flex: 0 0 auto;
    margin-right: 10px;
    position: absolute;
    border-radius: 100px;
    top: 2px;
    left: 2px;
    background-color: inherit;
    padding: 2px 3px;
    line-height: 16px;
    .black & {
      background-color: $black-100;
    }
    .blue & {
      background-color: $blue-40;
    }
    .green & {
      background-color: $green-40;
    }
    .red & {
      background-color: $red-40;
    }
    .orange & {
      background-color: $orange-40;
    }
    .yellow & {
      background-color: $yellow-40;
    }
    .purple & {
      background-color: $purple-40;
    }
    .brown & {
      background-color: $brown-40;
    }
  }
}

.settings {
  display: flex;
  flex-wrap: wrap;
}

/*  ---- Render Styles ----  */
.render {
  @extend %wrapper;
}

.render_item {
  @extend %item;
  & > span {
    margin-left: 10px;
  }
  svg {
    --color-1: #{$white};
    --color-2: #{$green-40};
    &.black {
      --color-2: #{$black-100};
    }
    &.blue {
      --color-2: #{$blue-40};
    }
    &.green {
      --color-2: #{$green-40};
    }
    &.red {
      --color-2: #{$red-40};
    }
    &.orange {
      --color-2: #{$orange-40};
    }
    &.yellow {
      --color-2: #{$yellow-40};
    }
    &.purple {
      --color-2: #{$purple-40};
    }
    &.brown {
      --color-2: #{$brown-40};
    }
  }
}
