@use '../../../common' as *;

.container {
  position: relative;
  min-width: 185px;
}

.dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 99;
  min-width: 100%;
}

.spinner {
  position: absolute;
  top: 22px;
  right: 0;
  z-index: 1;
  opacity: 0.8;
  &.trailingIcon {
    right: 32px;
  }
}
