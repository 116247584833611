@use '~@moved/ui/src/balazs/common/all' as *;
@use '../../../common/styles/shared';

.section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 26px;
  @include white-box;
  &:last-child {
    margin-bottom: 0;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top {
    border-bottom: 1px solid $black-20;
    padding-bottom: 15px;
  }

  .bottom {
    padding-top: 15px;
    flex-direction: column;
  }
}

.title {
  h3 {
    font-size: $font-size-3;
    font-weight: $med;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .order_date {
      margin-right: 12px;
    }
  }
}

.actions {
  display: flex;
}

.action {
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.action_oval {
  height: 32px;
  width: 32px;
  border-radius: 999em;
  background-color: $black-10;
  border: 1px solid $black-10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:hover {
    background-color: $blue-10;
    transform: scale(1.15);
    border-color: $blue-20;
  }
}

.action_btn {
  @extend %btn-gray;
  font-size: $font-size-2;
  padding: 6px 16px;
  white-space: nowrap;
  line-height: 20px;
  height: auto;
  svg {
    margin-right: 8px;
    --color-1: #{$black-90};
    --color-2: #{$black-90};
  }
}

.field_list {
  margin-top: 15px;
  width: 100%;
  h5 {
    padding: 0;
    font-size: $font-size-0;
    margin: 20px 0 10px;
  }
}

.adjustment {
  display: flex;
  width: 100%;
  background-color: $bg-light;
  padding: 16px 16px 8px 16px;
  border-radius: 6px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.none {
  width: 100%;
  background-color: $bg-light;
  color: $black-40;
  padding: 16px;
  text-align: center;
  border-radius: 6px;
}
