@use '../../common/all' as *;

/* ----------------------------------------
    Wide Format Wrapper
---------------------------------------- */

.header_section {
  background-color: $black-20;
  padding: 25px;
  border-radius: 4px;
  text-align: center;

  h2 {
    display: flex;
    align-items: center;
    color: $black-90;
    font-size: 26px;
    font-weight: $med;
    flex-direction: column;
    margin-bottom: 6px;
    .icon {
      margin-bottom: 10px;
    }
  }

  &.green {
    background-color: $green-10;
    h2 {
      color: $green-40;
    }
  }

  &.brown {
    background-color: #f8f6f4;
    h2 {
      color: $black-90;
    }
  }

  &.orange {
    background-color: $orange-10;
    h2 {
      color: $orange-40;
    }
  }

  &.yellow {
    background-color: $black-10;
    color: $black-50;
  }

  &.red {
    background-color: $red-10;
    h2 {
      color: $red-40;
    }
  }
}

.icon {
  &.green {
    --color-1: #{$green-40};
    --color-2: #{$green-30};
  }

  &.brown {
    --color-1: #{$brown-40};
    --color-2: #{$brown-50};
  }

  &.yellow {
    --color-1: #{$yellow-30};
    --color-2: #{$yellow-50};
  }
}

.flavor {
  color: $text-color;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
