@use '../../common' as *;

.data_bar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: $sp-16 0;
  gap: 20px;
  border: 1px solid $borderSecondary;
  width: 100%;
}

.attribute {
  border-left: 1px solid $borderSecondary;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  .large & {
    padding: 0 64px 0 32px;
    gap: 8px;
  }
  .small & {
    padding: 0 60px 0 20px;
    gap: 4px;
  }
  &:first-child {
    border-left: none;
  }
}

.label {
  white-space: nowrap;
}
