@use '~@moved/ui/src/sondheim/common' as *;

.slider {
  width: 100%;
}

.question_tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > span {
    margin-right: $sp-8;
  }

  svg {
    --color-1: #{$red-50};
    --color-2: #{$red-20};
  }
}

.popover {
  max-width: 190px;
  background-color: $black-90;
  border: none;
  border-radius: $radius-4;
  padding: $sp-4 $sp-8;
}

[data-popper-placement*='top'] .popover_arrow::after {
  @include arrow(9px,$black-90,'down');
  top: 0;
  left: 0;
}

[data-popper-placement*='bottom'] .popover_arrow::after {
  @include arrow(9px,$black-90,'up');
  top: 0;
  left: 0;
}

.popover_text {
  color: $white;
  font-size: 12px;
  line-height: 16px;
}


.view {
  width: 100%;
}
