@use '../../../../../sondheim/common' as *;

/*  ---- General Styles ----  */
.editor, .render {
  display: flex;
  .icon {
    flex: 0 0 auto;
    margin-right: $sp-12;
  }
  .title {
    margin-top: $sp-4;
    margin-bottom: $sp-8;
  }
  .content {
    display: block;
  }
}
