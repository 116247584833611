@use '../../common/all' as *;

.accordion {
  position: relative;
  display: flex;
  flex-direction: column;
}

.panel {
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
}

.panel_header {
  display: flex;
  font-size: $font-size-2;
  line-height: 20px;
  padding: 2px 0 18px;
  border-bottom: 1px solid $border-color;
  color: $black-40;
  cursor: pointer;
  transition: color 0.3s, border 0.3s, padding 0.3s;
  &:hover, &.open {
    color: $black-90;
  }
  &.open {
    border-bottom-color: transparent;
    padding-bottom: 14px;
    .panel_arrow {
      transform: rotate(90deg);
    }
  }
}

.panel_label {
  flex: 1 1 auto;
}

.panel_arrow {
  flex: 0 0 auto;
  transition: transform 0.3s;
  color: inherit;
  --color-1: currentColor;
}
