@use '~@moved/ui/src/balazs/common/all' as *;

.icon {
  vertical-align: top;
  margin-left: 5px;
}

.field {
  padding: 0 30px;
  margin: 0;
  border-right: 1px solid $black-20;
  display: flex;
  flex-direction: column;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    border-right: 0px none transparent;
  }
  dt {
    color: $black-40;
    font-size: $font-size-2;
    line-height: 20px;
  }
  dd {
    color: $black-90;
    font-size: $font-size-2;
    line-height: 20px;
    margin: 4px 0 0;
  }
  &.rate {
    dt {
      font-size: $font-size-1;
      line-height: 16px;
    }
    dd {
      color: $black-90;
      font-weight: $med;
      font-size: $font-size-6;
      line-height: 36px;
      margin: -4px 0;
    }
    &.red {
      dd {
        color: $red-50;
      }
    }
  }
}
.left_align {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
}
.right_align {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
