@use '~@moved/ui/src/balazs/common/all' as *;

.arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 4%;
}

.name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -10px;
  min-width: 180px;
  flex-basis: 28%;
}

.slug {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 20%;
}

.contact_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 21%;
}

.num_buildings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 8%;
}

.num_admins {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 8%;
}
