@use '../common' as *;

.primary { color: $primary; }
.secondary { color: $secondary; }
.accent { color: $accent; }

.error { color: $error; }
.warning { color: $warning; }
.success { color: $success; }

/* Background colors */
.backgroundPrimary { background-color: $backgroundPrimary; }
.backgroundSecondary { background-color: $backgroundSecondary; }
.backgroundTertiary { background-color: $backgroundTertiary; }

.backgroundInversePrimary { background-color: $backgroundInversePrimary; }
.backgroundInverseSecondary { background-color: $backgroundInverseSecondary; }

.backgroundStateEnabled { background-color: $backgroundStateEnabled; }
.backgroundStateHover { background-color: $backgroundStateHover; }
.backgroundStateActive { background-color: $backgroundStateActive; }
.backgroundStateDisabled { background-color: $backgroundStateDisabled; }

.backgroundOverlayDark { background-color: $backgroundOverlayDark; }
.backgroundOverlayLight { background-color: $backgroundOverlayLight; }

.backgroundAccent { background-color: $backgroundAccent; }
.backgroundError { background-color: $backgroundError; }
.backgroundWarning { background-color: $backgroundWarning; }
.backgroundSuccess { background-color: $backgroundSuccess; }

.backgroundAccentLight { background-color: $backgroundAccentLight; }
.backgroundErrorLight { background-color: $backgroundErrorLight; }
.backgroundWarningLight { background-color: $backgroundWarningLight; }
.backgroundSuccessLight { background-color: $backgroundSuccessLight; }

.backgroundTransparent { background-color: transparent; }

/* Content colors */
.contentPrimary { color: $contentPrimary; }
.contentSecondary { color: $contentSecondary; }
.contentTertiary { color: $contentTertiary; }

.contentInversePrimary { color: $contentInversePrimary; }
.contentInverseSecondary { color: $contentInverseSecondary; }
.contentInverseTertiary { color: $contentInverseTertiary; }

.contentAccent { color: $contentAccent; }
.contentOnColor { color: $contentOnColor; }

.contentError { color: $contentError; }
.contentWarning { color: $contentWarning; }
.contentSuccess { color: $contentSuccess; }

/* Border colors */
.borderPrimary { border-color: $borderPrimary; }
.borderSecondary { border-color: $borderSecondary; }
.borderTertiary { border-color: $borderTertiary; }

.borderAccent { border-color: $borderAccent; }
.borderError { border-color: $borderError; }
.borderWarning { border-color: $borderWarning; }
.borderSuccess { border-color: $borderSuccess; }

.borderAccentLight { border-color: $borderAccentLight; }
.borderErrorLight { border-color: $borderErrorLight; }
.borderWarningLight { border-color: $borderWarningLight; }
.borderSuccessLight { border-color: $borderSuccessLight; }

.borderTransparent { border-color: transparent; }

/* CTA colors */
.ctaAccentEnabled { color: $ctaAccentEnabled; }
.ctaAccentHover { color: $ctaAccentHover; }
.ctaAccentActive { color: $ctaAccentActive; }
.ctaAccentDisabled { color: $ctaAccentDisabled; }

.ctaSecondaryEnabled { color: $ctaSecondaryEnabled; }
.ctaSecondaryHover { color: $ctaSecondaryHover; }
.ctaSecondaryActive { color: $ctaSecondaryActive; }
.ctaSecondaryDisabled { color: $ctaSecondaryDisabled; }

.ctaErrorEnabled { color: $ctaErrorEnabled; }
.ctaErrorHover { color: $ctaErrorHover; }
.ctaErrorActive { color: $ctaErrorActive; }
.ctaErrorDisabled { color: $ctaErrorDisabled; }

/* Icon colors */
.iconSingleEnabled { color: $iconSingleEnabled; }
.iconSingleHover { color: $iconSingleHover; }
.iconSingleActive { color: $iconSingleActive; }
.iconSingleDisabled { color: $iconSingleDisabled; }

.iconOnButton { color: $iconOnButton; }
.iconOnColor { color: $iconOnColor; }

.iconMultiPrimary { color: $iconMultiPrimary; }
.iconMultiAccent { color: $iconMultiAccent; }
.iconMultiError { color: $iconMultiError; }
.iconMultiWarning { color: $iconMultiWarning; }
.iconMultiSuccess { color: $iconMultiSuccess; }
.iconMultiWhite { color: $iconMultiWhite; }

.iconMultiPrimaryLight { color: $iconMultiPrimaryLight; }
.iconMultiAccentLight { color: $iconMultiAccentLight; }
.iconMultiErrorLight { color: $iconMultiErrorLight; }
.iconMultiWarningLight { color: $iconMultiWarningLight; }
.iconMultiSuccessLight { color: $iconMultiSuccessLight; }
