@use '~@moved/ui/src/sondheim/common' as *;

.title {
  margin-bottom: 24px;
  h3 {
    font-size: 20px;
    line-height: 20px;
  }
}

.block_edit_control {
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button, label {
    padding: 0 40px;
    height: 44px;
    line-height: 44px;
  }
}

.confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
