@use '~@moved/ui/src/sondheim/common' as *;

.task {
  cursor: pointer;
  padding: $sp-4 0;
  justify-content: space-between;
  box-shadow: $shadow-1;
  transition: box-shadow 0.1s ease-out,
              padding 0.1s ease-out,
              margin 0.1s ease-out;
  &:hover {
    box-shadow: $shadow-3;
    padding: $sp-8 $sp-8;
    margin: -#{$sp-4} -#{$sp-8};
  }
}

.taskTitle {
  align-items: center;
  position: relative;
  padding: $sp-16 0 $sp-16 $sp-24;
  flex: 1 1 auto;
  @include responsive(tablet) {
    flex: 1 1;
  }
  @include responsive(mobile) {
    padding: $sp-16 0 $sp-16 $sp-12;
    flex: 1 1 auto;
  }
}

.taskButtons {
  flex: none;
  padding: $sp-16 $sp-24;
  align-items: center;
  @include responsive(mobile) {
    padding: $sp-16 $sp-12;
  }
}
.taskButton {
  flex: 0 0 auto;
  color: $iconSingleEnabled;
  transition: color 0.2s ease-out;
  &:hover {
    color: $iconSingleHover;
  }
}

.taskArrow {
  .task:hover & {
    color: $iconSingleActive;
  }
}

.dragHandle {
  // on mobile no hover state exists so we always show the dragHandle
  @include responsive(tablet,desktop) {
    display: none;
    .task:hover &, .task.dragging & {
      display: block;
    }
  }
  svg {
    --color-1: #{$iconSingleEnabled};
  }
  &:hover, .task.dragging & {
    svg {
      --color-1: #{$iconSingleHover};
    }
  }
}
.icon {
  // on mobile no hover state exists so we always show the dragHandle
  @include responsive(mobile) {
    display: none;
  }
  @include responsive(tablet,desktop) {
    display: block;
    .task:hover &, .task.dragging & {
      display: none;
    }
  }
}
