@use '../../common/all' as *;

/* ----------------------------------------
    Monthly Calendar View Daypicker
---------------------------------------- */

.container {}

.header {
  display: flex;
  padding: 0 5px 15px;
}

.header_month {
  flex: 1 1 auto;
  font-size: $font-size-3;
  color: $black-90;
}

.header_nav {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
}

.month_nav {
  cursor: pointer;
  color: $black-90;
  user-select: none;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow {
    opacity: 0.4;
  }
  &:hover {
    .arrow {
      opacity: 1;
    }
  }
}

.calendar {
  width: 100%;
  border-spacing: 0;
}

.active_week {
  background-color: $blue-10;
  border-radius: 10px;
}

.dow, .day {
  font-size: $font-size-2;
  text-align: center;
  padding: 3px;
  font-weight: $med;
  user-select: none;
  > span {
    display: inline-block;
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}

.dow {
  color: $black-90;
  cursor: default;
}

.day {
  padding: 0;
  cursor: pointer;
  &:first-child {
    border-radius: 30px 0 0 30px;
  }
  &:last-child {
    border-radius: 0 30px 30px 0;
  }
  > span {
    color: $black-90;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
    background-color: $white;
    border: 2px solid transparent;
    margin-bottom: 10px;
    &:hover {
      background-color: $green-10;
      color: $black-90;
    }
  }
}

.day_disabled {
  cursor: default;
  > span {
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border-color: transparent;
    color: $black-40;
  }
  &.day_selected {
    > span {
      opacity: 0.4;
    }
  }
}

.day_prev, .day_next {
  visibility: hidden; // maintain layout but hide the actual days
}
// day of next month
.day_next {
  > span {
    display: none; // remove the day from layout to allow an entire row of these to collapse
  }
}

.hover_day .day_disabled:hover {
  > span {
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border-color: transparent;
    color: $black-40;
  }
}

.day_selected, .day_selected.day_today, .day_selected:hover, .hover_day .day.day_selected:hover {
  > span {
    background-color: $green-10;
    border: 2px solid $green-40;
    color: $black-90;
    width: 44px;
    height: 44px;
    line-height: 40px;
    margin: -2px -2px 8px;
  }
}

.day_today, .day_today:hover {
  > span {
    border-color: $blue-20;
    width: 44px;
    height: 44px;
    line-height: 40px;
    margin: -2px -2px 8px;
  }
}

.hover_week .week:hover {
  background-color: $black-20;
}
.hover_week .week.active_week:hover {
  background-color: $blue-20;
}
