@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  flex: 1 1 auto;
  margin: 40px 80px;
  position: relative;
  max-width: 1020px;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

// Header Section
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.title_area {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 26px;
    margin-bottom: 4px;
  }

  .title_text {
    margin-right: 20px;
  }

  .subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: $black-50;
    font-weight: normal;
  }
}

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-0;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  padding: 3px 9px;
  font-weight: $med;
  white-space: nowrap;
  color: $black-60;
  background-color: $black-20;
  border: 1px solid $black-20;

  &.invited {
    background-color: $orange-20;
    border-color: $orange-20;
    color: $orange-60;
  }

  &.registered {
    background-color: $blue-20;
    border-color: $blue-20;
    color: $blue-60;
  }
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 4px;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }
  &:hover {
    color: $link-hover;
    --color-1: #{$link-hover};
    --color-2: #{$link-hover};
  }
}

.action_area {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

// Content section

.attributes {
  max-width: 611px;
  padding-bottom: 40px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.properties {
  margin-top: 70px;
}

.properties_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-weight: $regular;
  }
}

.btn_assign, .btn_resend {
  @extend %btn-gray;
  @extend %btn--small;
  padding: 6px 16px 6px 12px;
  font-size: $font-size-2;
  svg {
    margin-right: 8px;
    --color-1: #{$black-100};
    --color-2: #{$black-100};
  }
}

.properties_list {
  margin-top: 28px;
}
