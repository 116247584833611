@use '~@moved/ui/src/balazs/common/all' as *;
@use './variables' as *;

.sidebar {
  width: $navWidthCollapsed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  background: linear-gradient(180deg, #fff 0%, #f5f6f6 100%);
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.01);
  transition: width 0.4s cubic-bezier(0.75, 0, 0.25, 1), box-shadow 0.5s ease-in-out;
  overflow: hidden;
  &.expanded {
    width: $navWidthExpanded;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);
  }
}

.logo_section {
  padding-top: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  svg {
    height: 14px;
    width: 26px;
  }
}

.bottom_section {
  flex: 0 0 86px;
  height: 86px;
  width: $navWidthExpanded;
  opacity: 0;
  transition: opacity 0.4s;
  .expanded & {
    opacity: 1.0;
  }
}
