@use '~@moved/ui/src/sondheim/common' as *;
@use './shared' as *;

.key_column {
  flex: 0 0 auto;
  width: $keyWidth
}

.key_header {
  text-align: center;
  height: $calendarHeaderHeight;
  padding: 15px;
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  z-index: 36;
  border-right: 1px solid $black-20;
  position: sticky;
  top: 0;
  background-image: linear-gradient(to bottom, $black-10 25%, rgba(0,0,0,0) 95%);
}

.key_midnight {
  color: $black-40;
  @include labelXS;
  height: 0;
  width: 100%;
  position: relative;
  .key_times {
    position: absolute;
    right: 10px;
    top: -10px;
  }
}

.key_slot, .key_grid {
  flex: 1 0 auto;
  margin: 0 -1px 0 0;
  min-height: 60px;
  position: relative;
  &:last-child {
    margin-bottom: 15px;
  }
}

.key_background {
  position: absolute;
  left: $keyWidth;
  right: 0;
  top: $calendarHeaderHeight;
  height: 1440px;
  background-image: url('../images/diagonal.png');
  background-position: center;
  background-attachment: scroll;
  background-repeat: repeat;
  background-size: 20px;
}

.key_grid {
  width: 100%;
  .key_grid_line {
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 28;
    pointer-events: none;
  }
}

.key_slot {
  z-index: 2;
}

.key_slot_content {

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  align-items: flex-end;
  justify-content: flex-end;
  .key_times {
    display: block;
    margin-right: 10px;
    margin-bottom: -10px;
  }
}

.key_times {
  height: 20px;
  line-height: 20px;
  color: $black-40;
  @include labelXS;
}
