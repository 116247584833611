@use '../../common/all' as *;

.bread {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 30px;
}

.crumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  color: $black-50;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease-in-out;
  &:hover, &:active {
    color: $blue-50;
  }

  &.active {
    color: $black-100;
  }

  &:not(.active) {
    .icon {
      margin-right: 0;
    }
  }
}

.icon {
  margin-right: 8px;
  --color-1: currentColor;
  --color-2: currentColor;
}

.next_icon {
  margin: 0 8px;
  --color-1: #{$black-50};
  --color-2: #{$black-50};
}

.popover {
  max-width: 190px;
  background-color: $black-90;
  border: none;
  border-radius: 8px;
  padding: 4px 8px;
}

[data-popper-placement*='top'] .popover_arrow::after {
  @include arrow(9px,$black-90,'down');
  top: 0;
  left: 0;
}

[data-popper-placement*='bottom'] .popover_arrow::after {
  @include arrow(9px,$black-90,'up');
  top: 0;
  left: 0;
}

.popover_text {
  color: $white;
  font-size: 12px;
  line-height: 16px;
}
