@use '../../../../common/all' as *;

/* ----------------------------------------
    PopOver styling
---------------------------------------- */

.popover {
  background-color: $white;
  padding: 16px 20px;
  border-radius: 8px;
  box-shadow: 0 6px 24px 0 rgba(0,0,0,0.04);
  min-width: 330px;
}

.title_bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h4 {
    font-weight: $regular;
    font-size: 16px;
  }
  svg {
    cursor: pointer;
    --color-1: #{$black-90};
    --color-2: #{$black-90};
    &:hover {
      --color-1: #{$red-40};
      --color-2: #{$red-40};
    }
  }
}

@mixin active-state {
  &:focus, &:active, &.has_value {
    @content;
  }
  &:not(:empty), &:not(:placeholder-shown) {
    @content;
  }
}

.options {
  padding: 0;
  margin: 12px 0 0;
  overflow-x: auto;
  overflow-y: visible;
  max-height: 200px;
}

.option {
  list-style-type: none;
  margin-bottom: 12px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1px;
  &:last-child {
    margin-bottom: 0;
  }

  .link {
    display: none;
  }

  &:hover .link {
    display: block;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    cursor: pointer;

    .on, .off {
      position: absolute;
      left: -2px;
    }
    .on {
      opacity: 0;
      z-index: 2;
      --color-1: #{$white};
      --color-2: #{$blue-50};
      .check_input:checked ~ & {
        opacity: 1;
      }
    }
    .off {
      z-index: 1;
      --color-1: #{$black-40};
      --color-2: #{$black-40};
    }

    &:hover {
      .on {
        --color-1: #{$white};
        --color-2: #{$blue-30};
      }
      .off {
        --color-1: #{$blue-50};
        --color-2: #{$blue-50};
      }
    }

    span {
      font-size: $font-size-2;
    }
  }

  .check_input {
    display: none;

    &:checked ~ label {
      .on {
        opacity: 1;
      }
    }
  }
}
